// 2022-02-24 인수인계
// 기능 : 채팅페이지입니다.

import React, {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {getParameterByName, toggleClass, IsLogin, noData, timeForToday, inputChanger} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import TeamworkList from "../components/teamworkList";
import anime from "animejs";
import Basic from "../components/basic";
import ChatInner from "../components/chat_inner";
import AddMember from "../components/addMember";
import TeamChat from "../components/msgHandler";
import WriteTeamworkNotice from "../components/write_teamwork_notice";
import axios from "axios";
import {bridge, colorBridgeGray} from "../components/bridge";
const token = localStorage.getItem("token");

const Chat = () => {
    useEffect(() => {
        inputChanger();
        bridge();
        colorBridgeGray();
        TeamChat();
        if (!window.matchMedia("(min-width: 768px)").matches && getParameterByName("idx")) {
            document.querySelectorAll(".chat_wrapper")[0].style.display = "none";
            document.querySelectorAll(".left_menu_bar")[0].style.display = "none";
            document.querySelectorAll("div.chatting_area")[0].style.display = "block";
        }
    },[]);

    if(document.querySelectorAll(".left_menu_bar")[0] && document.querySelectorAll(".chat_wrapper")[0] && document.querySelectorAll("div.chatting_area")[0] && !window.matchMedia("(min-width: 768px)").matches && getParameterByName("idx") == ""){
        document.querySelectorAll(".chat_wrapper")[0].style.display = "block";
        document.querySelectorAll(".left_menu_bar")[0].style.display = "block";
        document.querySelectorAll("div.chatting_area")[0].style.display = "none";
    }

    return (
        <>
            <div className="wrap">
                <MainMenu />
                <AddMember />
                <TeamworkList />
                {getParameterByName("idx") ? <ChatInner teamworkId={getParameterByName("idx")} /> : <Basic />}
                <WriteTeamworkNotice />
            </div>
        </>
    );
};

export default Chat;

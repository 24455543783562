// 2022-02-24 인수인계
// 기능 : 채팅 (우측에서 열리는 팝업 제외) UI와 대부분의 기능 함수 포함

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getToday,
  lastWeek,
  getParameterByName,
  numberWithCommas,
  imgViewerFunction,
  imgViewerFunctionSetting,
  toggleClass,
  filteringBtns,
} from "../shared/script";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import {
  readLastMsg,
  scrollBottom,
  sendMsgExcute,
  memberDetailFunction,
  updateScheduleData,
  toggleSubMenu,
  fileUploadChat,
  enterTeamwork,
} from "../shared/chat.js";
import TeamworkModule from "./teamworkModules";
import Anime, { anime } from "react-anime";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import {
  magShare,
  msgShareExcute,
  scheduleStay,
  scheduleReturn,
  scheduleCancel,
  scheduleRemove,
  editSchedule,
  editScheduleExcute,
  clearScheduleInfo,
  scheduleShare,
  shareExcute,
  changePaymentType,
  paymentShare,
  payment,
} from "../shared/schedule";
import createPopup from "./createPopup";
import axios from "axios";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

const ChatInner = (props) => {
  const navigate = useNavigate();
  let isBlock = false;
  let searchCalendar;
  let myId, teamworkUserId, myPermissionInfo;
  let restChatInfoArr = [],
    isLeader,
    isSubLeader,
    isHideMsg = false;
  const imgTypeArr = ["jpg", "jpeg", "gif", "bmp", "png"];
  const videoArr = [
    "mp4",
    "m4v",
    "avi",
    "asf",
    "wmv",
    "mkv",
    "ts",
    "mpg",
    "mpeg",
    "mov",
    "flv",
    "ogv",
  ];

  let startSearchIndex = 1;

  function chatDate(date) {
    let chatTime = "";
    let newTime = "";

    function returnSet(type) {
      let hh = newTime.getHours(),
        mm = newTime.getMinutes();

      if (type == "typeZ") {
        hh = hh + 9;
      }

      if (hh <= 9) {
        hh = "0" + hh;
      }
      if (mm <= 9) {
        mm = "0" + mm;
      }

      chatTime = hh + ":" + mm;
    }

    if (date == undefined || chatTime == null) {
      chatTime = "";
    } else {
      if (date.indexOf("-") == -1) {
        newTime = new Date(date);
        returnSet("");
      } else if (date.indexOf("T") !== -1) {
        if (date.indexOf(".") !== -1) {
          newTime = new Date(
            date.replace(/T/g, " ").split(".")[0].replace(/-/g, "/")
          );
          returnSet("typeZ");
        } else {
          newTime = new Date(date.replace(/T/g, " ").replace(/-/g, "/"));
          returnSet("typeZ");
        }
      } else {
        newTime = new Date(date.replace(/-/g, "/"));
        returnSet("");
      }
    }
    return chatTime;
  }

  function chatDateYform(date) {
    let chatTime = "";
    if (date == undefined || chatTime == null) {
      chatTime = "-";
    } else {
      let newTime = "";

      if (date.indexOf("-") == -1) {
        newTime = new Date(date);
      } else if (date.indexOf("T") !== -1) {
        if (date.indexOf(".") !== -1) {
          newTime = new Date(
            date.replace(/T/g, " ").split(".")[0].replace(/-/g, "/")
          );
        } else {
          newTime = new Date(date.replace(/T/g, " ").replace(/-/g, "/"));
        }
      } else {
        newTime = new Date(date.replace(/-/g, "/"));
      }

      let yy = newTime.getFullYear(),
        mm = newTime.getMonth() + 1,
        dd = newTime.getDate();
      if (mm <= 9) {
        mm = "0" + mm;
      }
      if (dd <= 9) {
        dd = "0" + dd;
      }
      chatTime = yy + "-" + mm + "-" + dd;
    }
    return chatTime;
  }

  function videoImgSet(videoItem) {
    // let videoImg = "";
    // videoItem.muted = true;
    // videoItem.preload = 'auto';
    // videoItem.addEventListener('loadeddata', function () {
    //     let canvas = document.createElement('canvas');
    //     canvas.classList.add("videoCanvas");
    //     document.querySelector("body").appendChild(canvas);
    //     let canvasItem = document.querySelectorAll(".videoCanvas")[0];
    //     canvasItem.width = 298;
    //     canvasItem.height = 145;
    //     let ctx = canvasItem.getContext('2d');
    //     ctx.drawImage(videoItem, 0, 0, canvasItem.width, canvasItem.height);
    //     let datauri = canvasItem.toDataURL();
    //     videoImg = datauri;
    // });
    // return videoImg;
  }

  function updateChatStateChk(count) {
    document.querySelectorAll(".chat_data_inner>div").forEach((item, idx) => {
      if (!item.classList.contains("line_notice")) {
        var countItem = item.querySelectorAll(".un_read")[0];
        if (count == "") {
          countItem.textContent = "";
        } else if (Number(countItem.textContent) > Number(count)) {
          countItem.textContent = count;
        }
      }
    });
  }

  const updateChatState = (firstMsgId, LastMsgId) => {
    if (
      firstMsgId == LastMsgId ||
      document.querySelectorAll(
        ".chat_data_inner>div[idx='" + firstMsgId + "']"
      )[0] == undefined
    ) {
      var countItem = document
        .querySelectorAll(".chat_data_inner>div[idx='" + LastMsgId + "']")[0]
        .querySelectorAll(".un_read")[0];
      countItem.textContent =
        countItem.textContent <= 1 ? "" : Number(countItem.textContent) - 1;

      updateChatStateChk(countItem.textContent);
    } else if (firstMsgId < LastMsgId) {
      var chatMsgIdChk = 0;
      document.querySelectorAll(".chat_data_inner>div").forEach((item, idx) => {
        if (!item.classList.contains("line_notice")) {
          if (chatMsgIdChk == 0) {
            if (item.getAttribute("idx") == firstMsgId) {
              chatMsgIdChk = 1;
              var countItem = item.querySelectorAll(".un_read")[0];
              countItem.textContent =
                countItem.textContent <= 1
                  ? ""
                  : Number(countItem.textContent) - 1;
            }
          } else {
            var countItem = item.querySelectorAll(".un_read")[0];
            countItem.textContent =
              countItem.textContent <= 1
                ? ""
                : Number(countItem.textContent) - 1;
            if (item.getAttribute("idx") == LastMsgId) {
              updateChatStateChk(countItem.textContent);
              chatMsgIdChk = 0;
            }
          }
        }
      });
    }
  };

  function noticeFunctionUpdate() {
    getParameterByName("idx") && teamworkNoticeListUpDate();
    function teamworkNoticeListUpDate() {
      inner(
        `https://api.olapse.com/api/teamwork/notice?page=1&id=${getParameterByName(
          "idx"
        )}`
      );
      function inner(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            document.querySelectorAll(".notice_list_wrapper")[0].innerHTML = ``;
            document.querySelectorAll(".notice_count")[0].textContent =
              data.data.teamwork_notices.data.length;
            if (data.data.teamwork_notices.data.length !== 0) {
              document.querySelectorAll(".d_6 .no_data")[0].style.display =
                "none";
              data.data.teamwork_notices.data.forEach((item, idx) => {
                const htmlString = `<li class="new_item" idx="${
                  item.id
                }"> <h1>${item.title}</h1> <span>${
                  item.created_at.split(" ")[0]
                }</span> </li>`;
                document
                  .querySelectorAll(".notice_list_wrapper")[0]
                  .insertAdjacentHTML("beforeend", htmlString);
                document
                  .querySelectorAll(".new_item")[0]
                  .addEventListener("click", function () {
                    getTeamworkNoticeDetailUpdate(this.getAttribute("idx"));
                  });
                document
                  .querySelectorAll(".new_item")[0]
                  .classList.remove("new_item");
              });
            } else if (data.data.teamwork_notices.data.length == 0) {
              document.querySelectorAll(".d_6 .no_data")[0].style.display =
                "flex";
            }
            if (data.data.teamwork_notices.next_page_url) {
              inner(data.data.teamwork_notices.next_page_url);
            }
          });
      }
    }

    //공지사항 상세 불러오기
    function getTeamworkNoticeDetailUpdate(id) {
      fetch(`https://api.olapse.com/api/teamwork/notice/detail?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            document
              .querySelectorAll(".notice_header_right")[0]
              .setAttribute("idx", id);
            (document.querySelectorAll(
              "div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area h1"
            )[0].textContent = data.data.teamwork_notice.title),
              (document.querySelectorAll(
                "div.notice_detail_wrapper.notice_detail_wrapper_2 div.title_area span"
              )[0].textContent =
                data.data.teamwork_notice.created_at.split(" ")[0]),
              (document.querySelectorAll(
                "div.notice_detail_wrapper.notice_detail_wrapper_2 div.notice_contents span"
              )[0].textContent = data.data.teamwork_notice.contents);
            _animateRightModule(true, document.querySelectorAll(".d_7")[0]);
          }
        });
    }
    // 메뉴 토글
    // document.querySelectorAll(".notice_header_right")[0].addEventListener("click", function () {
    //     if (this.classList.contains("active")) {
    //         this.classList.remove("active");
    //         document.querySelectorAll(".d_7 .sub_popup_wrapper")[0].style.display = "none";
    //     } else {
    //         this.classList.add("active");
    //         document.querySelectorAll(".d_7 .sub_popup_wrapper")[0].style.display = "flex";
    //     }
    // });
    document
      .querySelectorAll("div.sub_popup_wrapper ul li")[0]
      .addEventListener("click", function () {
        //공지 수정
        disableAllPopup();
        document.querySelectorAll(".sub_popup_wrapper")[0].style.display =
          "none";
        document
          .querySelectorAll(".edit_checker")[0]
          .setAttribute(
            "notice",
            document
              .querySelectorAll(".notice_header_right")[0]
              .getAttribute("idx")
          );
        document.querySelectorAll(".chatting_area")[0].style.display = "none";
        document.querySelectorAll(".writeTeamworkNotice")[0].style.display =
          "flex";
        document.querySelectorAll(
          ".writeTeamworkNotice .right_area_title"
        )[0].textContent = "공지사항 수정";
        document.querySelectorAll(".submit_notice")[0].textContent = "수정완료";
        fetch(
          `https://api.olapse.com/api/teamwork/notice/detail?id=${document
            .querySelectorAll(".edit_checker")[0]
            .getAttribute("notice")}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              document.querySelector("#noti_title").value =
                data.data.teamwork_notice.title;
              document.querySelector("#noti_content").value =
                data.data.teamwork_notice.contents;
            }
          });
      });
    document
      .querySelectorAll("div.sub_popup_wrapper ul li")[1]
      .addEventListener("click", function () {
        //공지 삭제
        removeTeamworkNoticeUpdate(
          document
            .querySelectorAll(".notice_header_right")[0]
            .getAttribute("idx")
        );
      });
    function removeTeamworkNoticeUpdate(id) {
      fetch(
        `https://api.olapse.com/api/teamwork/notice/delete?teamwork_notice_id=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "공지사항 삭제 완료",
              content: "팀워크 공지사항을 삭제하였습니다.",
              custom: () => {
                location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "삭제 불가능",
              content: data.alert,
            });
          }
        });
    }
  }

  const openSearchSelector = (date) => {
    const dateArr = {
      date: [],
      element: [],
    };
    document.querySelectorAll(".chat_search_popup_wrapper")[0].style.display =
      "flex";
    searchCalendar.render();

    document
      .querySelectorAll(".chat_search_popup_wrapper .fc-daygrid-day")
      .forEach((item, idx) => {
        if (!item.classList.contains("fc-day-other")) {
          dateArr.date.push(item.getAttribute("data-date").split("-")[2]);
          dateArr.element.push(item);
        }
        item.style.opacity = 0.3;
        item.setAttribute("disabled", true);
      });

    fetch(
      `https://api.olapse.com/api/teamwork/message/date/search?date=${date}&id=${getParameterByName(
        "idx"
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        data?.data?.teamwork_messages.forEach((item, idx) => {
          if (dateArr.date.indexOf(item.date) !== -1) {
            dateArr.element[dateArr.date.indexOf(item.date)].style.opacity = 1;
            dateArr.element[dateArr.date.indexOf(item.date)].setAttribute(
              "disabled",
              false
            );
          }
        });
      });
  };

  //모든 부모요소 반환
  function getParents(elem, targetTagName) {
    const parents = [];
    while (
      elem.parentNode &&
      elem.parentNode.nodeName.toLowerCase() != targetTagName
    ) {
      elem = elem.parentNode;
      parents.push(elem);
    }
    return parents;
  }

  //이름 변경 기능
  function fileNameCutter(name) {
    let result = "";
    name.split("_").forEach((item, idx) => {
      if (idx !== 0) {
        result += item;
      }
    });
    return result;
  }

  function dateSecondCutter(date) {
    return date.split(":")[0] + ":" + date.split(":")[1];
  }

  useEffect(() => {
    var echo;
    let lastItem = "";
    //풀캘린더 세팅
    window.FullCalendar = Calendar;
    window.dayGridPlugin = dayGridPlugin;
    window.listPlugin = listPlugin;
    window.interactionPlugin = interactionPlugin;

    const appendTarget = document.querySelectorAll(".chat_data_inner")[0];
    let myCalFunctionParams = {
      startDate: lastWeek(),
      endDate: getToday(),
      teamworkUserId: null,
    };
    let lastDate, pageEndDate;
    function addDateFunc(item, idx, maxIndex, type) {
      let addLineDateHtml;
      function addItem(addHtml) {
        if (type) {
          appendTarget.insertAdjacentHTML("beforeend", addHtml);
        } else {
          appendTarget
            .querySelectorAll("div")[0]
            .insertAdjacentHTML("beforebegin", addHtml);
        }
      }

      document.querySelectorAll(".line_noticeStart")[0] &&
        document.querySelectorAll(".line_noticeStart")[0].remove();

      if (document.querySelectorAll(".chat_data_inner>div").length !== 0) {
        if (lastDate !== chatDateYform(item.created_at)) {
          if (pageEndDate == chatDateYform(item.created_at)) {
            document
              .querySelectorAll(
                ".line_notice[data-date='" + pageEndDate + "']"
              )[0]
              .remove();
            lastDate = chatDateYform(item.created_at);
            // addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
            // addItem(addLineDateHtml);
          } else {
            if (type) {
              lastDate = chatDateYform(item.created_at);
              addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
              addItem(addLineDateHtml);
            } else {
              addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
              appendTarget
                .querySelectorAll("div")[0]
                .nextSibling.insertAdjacentHTML("beforebegin", addLineDateHtml);
              lastDate = chatDateYform(item.created_at);
              if (idx == maxIndex) {
                addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
                appendTarget
                  .querySelectorAll("div")[0]
                  .insertAdjacentHTML("beforebegin", addLineDateHtml);
              }
            }
          }
        } else if (idx == maxIndex && !type) {
          addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
          addItem(addLineDateHtml);
        }
      } else {
        lastDate = chatDateYform(item.created_at);
        if (maxIndex == 0 || idx == 0) {
          addLineDateHtml = `<div class="line_notice" data-date="${lastDate}"><span class="teamwork_start_date">${lastDate}</span></div>`;
          addItem(addLineDateHtml);
        }
      }
      if (idx == maxIndex) {
        pageEndDate = document.querySelectorAll(".line_notice")[0].textContent;
        lastDate = "";
      }
    }
    function myCalFunction(teamworkUserId) {
      if (myCalFunctionParams.teamworkUserId == null) {
        myCalFunctionParams.teamworkUserId = teamworkUserId;
      }

      document.querySelectorAll(".d_11 .member_name")[0].textContent =
        "내 매출/정산";
      document.querySelectorAll(".d_11 .member_name")[0].removeAttribute("idx");
      document
        .querySelectorAll(".d_11 .member_name")[0]
        .removeAttribute("name");

      document
        .querySelectorAll(
          "div.memeber_price_wrapper div.memeber_price_list_wrapper ul li"
        )
        .forEach((item, idx) => {
          item.remove();
        });

      fetch(
        `https://api.olapse.com/api/teamwork/member/calculate?id=${teamworkUserId}&begin_date=${myCalFunctionParams.startDate}&end_date=${myCalFunctionParams.endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          var stDate = new Date(
            myCalFunctionParams.startDate.replace(/-/g, "/")
          );
          var enDate = new Date(myCalFunctionParams.endDate.replace(/-/g, "/"));
          var setDay =
            (enDate.getTime() - stDate.getTime()) / (1000 * 60 * 60 * 24);

          document.querySelectorAll(
            ".memeber_price_wrapper .date_picker_row span"
          )[0].textContent =
            myCalFunctionParams.startDate +
            " ~ " +
            myCalFunctionParams.endDate +
            " (" +
            setDay +
            "일)";
          document.querySelectorAll(
            "div.memeber_price_wrapper div.price_detail_info > h1"
          )[0].innerHTML = `<span>${
            data.data.schedules.length
          }건</span><span class="blue">${numberWithCommas(
            data.data.total_price
          )}원</span>`;
          if (data.data.schedules.length !== 0) {
            data.data.schedules.forEach((item, idx) => {
              const htmlString = `<li class="new_schedule_share_item"><div class="left"><h1>${numberWithCommas(
                item.price
              )}원 (카드)</h1><span>일정완료일 : ${
                item.completed_at ? chatDateYform(item.completed_at) : "-"
              }</span></div><div class="memeber_price_list_right"><p class="${
                item.calculate_status == 1
                  ? "type_2"
                  : "type_1 complete_schedule_button_s"
              }">${
                item.calculate_status == 1 ? "완료" : "미완료"
              }</p></div></li>`;
              document
                .querySelectorAll(
                  "div.memeber_price_wrapper div.memeber_price_list_wrapper ul"
                )[0]
                .insertAdjacentHTML("beforeend", htmlString);
              if (document.querySelectorAll(".complete_schedule_button_s")[0]) {
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .removeEventListener("click", function () {
                    completeSchedule(item.id);
                  });
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .addEventListener("click", function () {
                    completeSchedule(item.id);
                  });
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .classList.remove("complete_schedule_button_s");
              }
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .removeEventListener("click", function (e) {
                  if (e.target.tagName !== "P") {
                    window.location.href = `/more/payment_detail_2?idx=${item.id}`;
                  }
                });
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .addEventListener("click", function (e) {
                  if (e.target.tagName !== "P") {
                    window.location.href = `/more/payment_detail_2?idx=${item.id}`;
                  }
                });
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .classList.remove("new_schedule_share_item");
            });
            document.querySelectorAll(
              ".memeber_price_wrapper .no_data"
            )[0].style.display = "none";
          } else {
            document.querySelectorAll(
              ".memeber_price_wrapper .no_data"
            )[0].style.display = "flex";
          }
        });
    }
    function myCalFunctionV2(teamworkUserId) {
      if (myCalFunctionParams.teamworkUserId == null) {
        myCalFunctionParams.teamworkUserId = teamworkUserId;
      }
      document.querySelectorAll(".d_11 .member_name")[0].textContent =
        document.querySelectorAll(".d_5 .member_name")[0].textContent +
        " 매출/정산";
      document
        .querySelectorAll(".d_11 .member_name")[0]
        .setAttribute("idx", teamworkUserId);
      document
        .querySelectorAll(".d_11 .member_name")[0]
        .setAttribute(
          "name",
          document.querySelectorAll(".d_5 .member_name")[0].textContent
        );

      document
        .querySelectorAll(
          "div.memeber_price_wrapper div.memeber_price_list_wrapper ul li"
        )
        .forEach((item, idx) => {
          item.remove();
        });

      fetch(
        `https://api.olapse.com/api/teamwork/member/calculate?id=${teamworkUserId}&begin_date=${myCalFunctionParams.startDate}&end_date=${myCalFunctionParams.endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          var stDate = new Date(
            myCalFunctionParams.startDate.replace(/-/g, "/")
          );
          var enDate = new Date(myCalFunctionParams.endDate.replace(/-/g, "/"));
          var setDay =
            (enDate.getTime() - stDate.getTime()) / (1000 * 60 * 60 * 24);

          document.querySelectorAll(
            ".memeber_price_wrapper .date_picker_row span"
          )[0].textContent =
            myCalFunctionParams.startDate +
            " ~ " +
            myCalFunctionParams.endDate +
            " (" +
            setDay +
            "일)";
          document.querySelectorAll(
            "div.memeber_price_wrapper div.price_detail_info > h1"
          )[0].innerHTML = `<span>${
            data.data.schedules.length
          }건</span><span class="blue">${numberWithCommas(
            data.data.total_price
          )}원</span>`;
          if (data.data.schedules.length !== 0) {
            data.data.schedules.forEach((item, idx) => {
              const htmlString = `<li class="new_schedule_share_item"><div class="left"><h1>${numberWithCommas(
                item.price
              )}원 (카드)</h1><span>일정완료일 : ${
                item.completed_at ? chatDateYform(item.completed_at) : "-"
              }</span></div><div class="memeber_price_list_right"><p class="${
                item.calculate_status == 1
                  ? "type_2"
                  : "type_1 complete_schedule_button_s"
              }">${
                item.calculate_status == 1 ? "완료" : "미완료"
              }</p></div></li>`;
              document
                .querySelectorAll(
                  "div.memeber_price_wrapper div.memeber_price_list_wrapper ul"
                )[0]
                .insertAdjacentHTML("beforeend", htmlString);
              if (document.querySelectorAll(".complete_schedule_button_s")[0]) {
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .removeEventListener("click", function () {
                    completeSchedule(item.id);
                  });
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .addEventListener("click", function () {
                    completeSchedule(item.id);
                  });
                document
                  .querySelectorAll(".complete_schedule_button_s")[0]
                  .classList.remove("complete_schedule_button_s");
              }
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .removeEventListener("click", function (e) {
                  if (e.target.tagName !== "P") {
                    window.location.href = `/more/payment_detail_2?idx=${item.id}`;
                  }
                });
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .addEventListener("click", function (e) {
                  if (e.target.tagName !== "P") {
                    window.location.href = `/more/payment_detail_2?idx=${item.id}`;
                  }
                });
              document
                .querySelectorAll(".new_schedule_share_item")[0]
                .classList.remove("new_schedule_share_item");
            });
            document.querySelectorAll(
              ".memeber_price_wrapper .no_data"
            )[0].style.display = "none";
          } else {
            document.querySelectorAll(
              ".memeber_price_wrapper .no_data"
            )[0].style.display = "flex";
          }
        });
    }
    function completeSchedule(scheduleId) {
      let formdata = new FormData();
      let scheduleIdArr = [];
      scheduleIdArr.push(scheduleId);
      formdata.append("schedule_id[]", scheduleIdArr);
      fetch(`https://api.olapse.com/api/teamwork/schedule/calculate/complete`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "정산 완료",
              content: "정산이 완료되었습니다.",
              custom: () => {
                window.location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "정산 실패",
              content: data.alert,
              custom: () => {
                window.location.reload(true);
              },
            });
          }
        });
    }
    function initPaymentCal() {
      document.querySelectorAll(
        ".popup_wrapper_2_payment .start_time_data"
      )[0].textContent = myCalFunctionParams.startDate;
      document.querySelectorAll(
        ".popup_wrapper_2_payment .end_time_data"
      )[0].textContent = myCalFunctionParams.endDate;
      const calendarEl = document.querySelectorAll(
          ".popup_wrapper_2_payment .fullcal_wrapper"
        )[0],
        calendar = new FullCalendar.Calendar(calendarEl, {
          plugins: [dayGridPlugin, listPlugin, interactionPlugin],
          initialView: "dayGridMonth",
          dateClick: function (info) {
            document.querySelectorAll(
              "div.popup_wrapper_2_payment div.date_picker_box div.time_col div.col.active span"
            )[0].textContent = info.dateStr;
            if (
              document.querySelectorAll(
                ".popup_wrapper_2_payment .fc_actived_day"
              )[0]
            ) {
              document
                .querySelectorAll(".popup_wrapper_2_payment .fc_actived_day")
                .forEach((item, idx) => {
                  item.classList.remove("fc_actived_day");
                });
            }
            info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
          },
        });
      calendar.setOption("locale", "ko");
      calendar.render();
      document
        .querySelectorAll("div.memeber_price_wrapper div.date_picker_row")[0]
        .removeEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "flex";
          calendar.render();
        });
      document
        .querySelectorAll(
          "div.popup_wrapper_2_payment div.date_picker_box ul.button_wrapper li"
        )[0]
        .removeEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "none";
        });
      document
        .querySelectorAll(
          "div.popup_wrapper_2_payment div.date_picker_box ul.button_wrapper li"
        )[1]
        .removeEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "none";
          myCalFunctionParams.startDate = document.querySelectorAll(
            ".popup_wrapper_2_payment .start_time_data"
          )[0].textContent;
          myCalFunctionParams.endDate = document.querySelectorAll(
            ".popup_wrapper_2_payment .end_time_data"
          )[0].textContent;
          myCalFunctionV2(
            document
              .querySelectorAll(".d_5 .member_name")[0]
              .getAttribute("idx")
              ? document
                  .querySelectorAll(".d_5 .member_name")[0]
                  .getAttribute("idx")
              : myCalFunctionParams.teamworkUserId
          );
        });
      document
        .querySelectorAll("div.memeber_price_wrapper div.date_picker_row")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "flex";
          calendar.render();
        });
      document
        .querySelectorAll(
          "div.popup_wrapper_2_payment div.date_picker_box ul.button_wrapper li"
        )[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "none";
        });
      document
        .querySelectorAll(
          "div.popup_wrapper_2_payment div.date_picker_box ul.button_wrapper li"
        )[1]
        .addEventListener("click", function () {
          document.querySelectorAll(
            ".popup_wrapper_2_payment"
          )[0].style.display = "none";
          myCalFunctionParams.startDate = document.querySelectorAll(
            ".popup_wrapper_2_payment .start_time_data"
          )[0].textContent;
          myCalFunctionParams.endDate = document.querySelectorAll(
            ".popup_wrapper_2_payment .end_time_data"
          )[0].textContent;
          myCalFunctionV2(
            document
              .querySelectorAll(".d_5 .member_name")[0]
              .getAttribute("idx")
              ? document
                  .querySelectorAll(".d_5 .member_name")[0]
                  .getAttribute("idx")
              : myCalFunctionParams.teamworkUserId
          );
        });
    }
    //내 아이디값 저장, 소켓 이벤트
    // fetch("https://api.olapse.com/api/member", {
    //     method: "GET",
    //     headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    // }).then(function (response) {
    //     return response.json();
    // }).then(function (data) {
    //     //시작
    //     myId = data.data.my_info.id;
    //     myPermission();
    // });
    let teamSetChk;
    if (teamSetChk !== "") {
      clearInterval(teamSetChk);
    }
    sessionStorage.setItem("teamSetChk", false);
    teamSetChk = setInterval(() => {
      if (sessionStorage.getItem("teamSetChk") == "true") {
        myId = sessionStorage.getItem("myId");
        myPermissionInfo = sessionStorage.getItem("myPermissionInfo");
        setTimeout(() => {
          myPermission();
        }, 10);
        clearInterval(teamSetChk);
      }
    }, 100);

    let lastMsgId = "",
      lastReadId = "";

    function msgOption(e) {
      if (
        document.querySelectorAll(".hide_msg_button")[0] &&
        document
          .querySelectorAll(".hide_msg_button")[0]
          .classList.contains("active")
      ) {
        e.is_leader == 1 && receiveMsgFromChild(e);
      } else {
        receiveMsgFromChild(e);
      }
    }

    function getMyId() {
      echo = new Echo({
        host: "https://api.olapse.com:6006/",
        broadcaster: "socket.io",
        client: socketio,
      });
      echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
        if (e.type == "read" && e.last_message_id !== lastReadId) {
          msgOption(e);
          lastReadId = e.last_message_id;
        } else if (
          e.type == "member_invite" ||
          e.type == "member_add" ||
          e.type == "info_modify"
        ) {
          msgOption(e);
        } else if (e.id !== lastMsgId) {
          msgOption(e);
          lastMsgId = e.id;
        } else {
          msgOption(e);
        }
      });
    }

    function myPermission() {
      if (myPermissionInfo == "팀원") {
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[0].style.display = "none";
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[1].style.display = "none";
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[2].style.display = "none";
      }

      if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
        document.querySelectorAll(
          "div.chat_sub_write_menu ul li"
        )[1].style.display = "flex";
      } else {
        document.querySelectorAll(
          "div.chat_sub_write_menu ul li"
        )[1].style.display = "none";
        if (isBlock == true) {
          document.querySelectorAll(
            "div.chatting_area div.chat_write"
          )[0].style.pointerEvents = "none";
          document.querySelectorAll(
            "div.chatting_area div.chat_write"
          )[0].style.filter = "blur(2px)";
        }
      }

      getTeamworkDetailInfo();
      toggleSubMenu();
      imgViewerFunctionSetting();
      fileUploadChat(props.teamworkId);
      enterTeamwork(props.teamworkId);
      memberListFunction();
      toggleSettings();
      settingTeamworkData();
      menuModi();
      initPaymentCal();
    }

    function myPermissionUpdate() {
      if (myPermissionInfo == "팀원") {
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[0].style.display = "none";
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[1].style.display = "none";
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
        )[2].style.display = "none";
      }

      if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
        document.querySelectorAll(
          "div.chat_sub_write_menu ul li"
        )[1].style.display = "flex";
      } else {
        document.querySelectorAll(
          "div.chat_sub_write_menu ul li"
        )[1].style.display = "none";
        if (isBlock == true) {
          document.querySelectorAll(
            "div.chatting_area div.chat_write"
          )[0].style.pointerEvents = "none";
          document.querySelectorAll(
            "div.chatting_area div.chat_write"
          )[0].style.filter = "blur(2px)";
        }
      }

      getTeamworkDetailInfoUpdate();
      memberListFunctionUpdate();
      settingTeamworkData();
      teamworkDataUpdate();

      // fetch(`https://api.olapse.com/api/teamwork/member?id=${getParameterByName("idx")}`, {
      //     method: "GET",
      //     headers: {
      //         Authorization: "Bearer " + localStorage.getItem("token"),
      //     },
      // })
      //     .then(function (response) {
      //         return response.json();
      //     })
      //     .then(function (data) {
      //         if(data.success == true){
      //             data.data.teamwork_users.forEach((item, idx) => {
      //                 if (item.user_id == myId) {
      //                     if (item.is_leader == 1) {
      //                         myPermissionInfo = "팀장";
      //                     } else if (item.is_sub_leader == 1) {
      //                         myPermissionInfo = "부팀장";
      //                     } else {
      //                         myPermissionInfo = "팀원";
      //                         document.querySelectorAll("div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li")[0].style.display = "none";
      //                         document.querySelectorAll("div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li")[1].style.display = "none";
      //                         document.querySelectorAll("div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li")[2].style.display = "none";
      //                     }
      //                     if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
      //                         document.querySelectorAll("div.chat_sub_write_menu ul li")[1].style.display = "flex";
      //                     } else {
      //                         document.querySelectorAll("div.chat_sub_write_menu ul li")[1].style.display = "none";
      //                         if (isBlock == true) {
      //                             document.querySelectorAll("div.chatting_area div.chat_write")[0].style.pointerEvents = "none";
      //                             document.querySelectorAll("div.chatting_area div.chat_write")[0].style.filter = "blur(2px)";
      //                         }
      //                     }
      //                 }
      //             });
      //         }
      //         getTeamworkDetailInfoUpdate();
      //         memberListFunctionUpdate();
      //         settingTeamworkData();
      //         teamworkDataUpdate();
      //     });
    }

    //일정공유 닫기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll("div.share_popup_wrapper")[0].style.display =
          "none";
      });
    //일정공유하기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        shareExcute();
      });

    //메세지공유 닫기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(1)"
      )[1]
      .addEventListener("click", function () {
        document.querySelectorAll("div.share_popup_wrapper")[1].style.display =
          "none";
      });
    //메세지공유하기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(2)"
      )[1]
      .addEventListener("click", function () {
        msgShareExcute(this.getAttribute("idx"));
      });

    //소켓에서 받아왔을 경우 메세지 insert
    const varUAApp = navigator.userAgent;
    var readCount = 1000,
      readSet;
    let receiveMsgFromChild = (e) => {
      document.querySelectorAll(".chat_row_prev")[0] &&
        document.querySelectorAll(".chat_row_prev")[0].remove();
      const result = e;
      //일정 실시간 상태변경 기능
      // if (result.type == "return") {
      //     window.location.reload(true);
      // }
      if (e.type == "read") {
        if (
          e.first_message_id !== null &&
          e.teamwork_id == getParameterByName("idx")
        ) {
          updateChatState(e.first_message_id, e.last_message_id);
        }
      } else if (
        e.type == "member_invite" ||
        e.type == "member_add" ||
        e.type == "info_modify"
      ) {
        //팀워크 제목
        //사이드메뉴 팀워크 정보
        //멤버 목록
        //
        myPermissionUpdate();
        setTimeout(() => {
          clearInterval(readSet);
          readCount = 1000;
          readSet = setInterval(() => {
            readCount = readCount - 100;
            if (readCount == 0) {
              var chSetting = setInterval(() => {
                if (
                  document.querySelectorAll(
                    ".chat_data_inner > div:last-of-type"
                  )[0]
                ) {
                  readLastMsg(
                    document
                      .querySelectorAll(
                        ".chat_data_inner > div:last-of-type"
                      )[0]
                      .getAttribute("idx"),
                    getParameterByName("idx")
                  );
                  clearInterval(chSetting);
                }
              }, 100);
            }
          }, 100);
        }, 10);
      } else {
        if (
          result.type !== "enter" &&
          chatDateYform(result.chat_date) !== "-"
        ) {
          document.querySelectorAll(".line_noticeStart")[0] &&
            document.querySelectorAll(".line_noticeStart")[0].remove();
          let lastDateItem = document.querySelectorAll(".line_notice")[0]
            ? document
                .querySelectorAll(".line_notice")
                [
                  document.querySelectorAll(".line_notice").length - 1
                ].querySelectorAll(".teamwork_start_date")[0].textContent
            : "";
          if (lastDateItem !== chatDateYform(result.chat_date)) {
            let addLineDateHtml = `<div class="line_notice" data-date="${chatDateYform(
              result.chat_date
            )}"><span class="teamwork_start_date">${chatDateYform(
              result.chat_date
            )}</span></div>`;
            appendTarget.insertAdjacentHTML("beforeend", addLineDateHtml);
          }
        }
        if (
          result.type == "accept" ||
          result.type == "complete" ||
          result.type == "calculate_complete" ||
          result.type == "hold" ||
          result.type == "cancel"
        ) {
          var scheduleId =
            result.type == "return" ? result.reference_id : result.id;
          fetch(
            `https://api.olapse.com/api/teamwork/schedule/detail?id=${scheduleId}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              document
                .querySelectorAll(".schedule_wrapper")
                .forEach((item, idx) => {
                  if (item.getAttribute("data-schedule_id") == scheduleId) {
                    let statusClassName, title;
                    if (data.data.schedule.status == 0) {
                      //요청
                      statusClassName = "request";
                      title = "일정이 요청되었습니다.";
                    } else if (data.data.schedule.status == 1) {
                      //수락
                      statusClassName = "admit";
                      title = `일정이 수락되었습니다.`;
                    } else if (data.data.schedule.status == 2) {
                      //완료
                      statusClassName = "complete";
                      title = `일정이 완료되었습니다.`;
                    } else if (data.data.schedule.status == 5) {
                      //보류
                      statusClassName = "stay";
                      title = `일정이 보류되었습니다.`;
                    } else if (data.data.schedule.status == 10) {
                      //취소
                      statusClassName = "cancel";
                      title = `일정이 취소되었습니다.`;
                    }

                    const htmlString = `<div data-schedule_id="${
                      data.data.schedule.id
                    }" idx="${item.getAttribute(
                      "idx"
                    )}" class="schedule_wrapper new_schedule_wrapper message_${
                      item.id
                    }"><div class="schedule_bottom_box">${
                      item.querySelectorAll(".time")[0].cloneNode(true)
                        .outerHTML
                    }${
                      item.querySelectorAll(".un_read")[0].cloneNode(true)
                        .outerHTML
                    }</div> <div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                      data.data.schedule.contents
                    }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                      data.data.schedule.place
                    }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                      data.data.schedule.begin_date
                    )} ~<br>${
                      data.data.schedule.end_date
                        ? dateSecondCutter(data.data.schedule.end_date)
                        : ""
                    }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                      data.data.schedule.price
                    )}원</span> </div>`;
                    item.insertAdjacentHTML("afterend", htmlString);
                    item.remove();
                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".right_popup")
                          .forEach((item, idx) => {
                            _animateRightModule(false, item);
                          });
                        setTimeout(() => {
                          _animateRightModule(
                            true,
                            document.querySelectorAll(".d_4")[0]
                          );
                          updateScheduleData(scheduleId, myPermissionInfo);
                        }, 500);
                        document
                          .querySelectorAll(".common_more_wrapper li")
                          .forEach((item2, idx2) => {
                            item2.setAttribute("data-schedule_id", scheduleId);
                          });
                        document
                          .querySelectorAll(".edit_schedule")[0]
                          .setAttribute("data-schedule_id", scheduleId);
                      });
                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .classList.remove("new_schedule_detail_button");

                    //반환이 아닐경우 경우 팀원한테 노출x
                    if (result.type !== "return") {
                      if (myPermissionInfo == "팀원") {
                        if (myId !== data.data.schedule.accept_user_id) {
                          document.querySelectorAll(
                            ".new_schedule_wrapper"
                          )[0].style.display = "none";
                        }
                      }
                    }
                    document
                      .querySelectorAll(".new_schedule_wrapper")[0]
                      .classList.remove("new_schedule_wrapper");
                  }
                });
            });
        } else if (result.type == "delete") {
          document
            .querySelectorAll(".schedule_wrapper")
            .forEach((item2, idx2) => {
              if (result.id == item2.getAttribute("data-schedule_id")) {
                item2.remove();
              }
            });
        } else {
          // readLastMsg(result.id, props.teamworkId);
          // if(result.id !== null){
          //     readLastMsg(result.id, getParameterByName("idx"));
          // }else{
          //     var chSetting = setInterval(() => {
          //         if(document.querySelectorAll(".chat_data_inner > div:last-of-type")[0]){
          //             readLastMsg(document.querySelectorAll(".chat_data_inner > div:last-of-type")[0].getAttribute("idx"),getParameterByName("idx"));
          //             clearInterval(chSetting);
          //         }
          //     }, 100);
          // }
        }
        if (result.teamwork_id == props.teamworkId) {
          let item = result;
          let msgString;
          //메세지가 250자 이상일 경우 처리
          if (item.message && String(item.message).length > 250) {
            msgString = `${item.message.substring(0, 250)}... <span origin="${
              item.message
            }" class="new_show_more">더보기</span>`;
          } else {
            msgString = `${item.message}`;
          }
          if (item.user_id == myId) {
            //내가 보낸 메세지
            if (item.type_ == "file") {
              //파일일 경우 (row 추가 o)
              if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                //이미지일 경우 (row 추가 o)
                const htmlString = `<div idx="${
                  item.id
                }" class="new_chat_row_img chat_row user_new_chat_row"> <div class="chat_row_wrapper_my"> <img class="chat_img_right" src="${
                  item.file_url
                }" /> <div class="chat_info_right"> <span id_arr="${
                  item.unread_message_ids
                }" class="un_read">${
                  item.unread_message_count ? item.unread_message_count : ""
                }</span> <p class="time">${chatDate(
                  result.chat_date
                )}</p> </div> </div> </div>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                imgViewerFunction(
                  document.querySelectorAll(
                    ".new_chat_row_img .chat_img_right"
                  )[0],
                  item.file_url,
                  item.name,
                  item
                );
                document
                  .querySelectorAll(".new_chat_row_img")[0]
                  .classList.remove("new_chat_row_img");
              } else {
                if (videoArr.indexOf(item.file_extension) !== -1) {
                  //동영상일경우
                  var videoName =
                    item.file_url.split("/")[
                      item.file_url.split("/").length - 1
                    ];

                  document.querySelectorAll(".video_prev_row")[0].remove();
                  const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                    item.unread_message_ids
                  }" class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span> <p class="time">${chatDate(
                    result.chat_date
                  )}</p> </div><div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" class="new_video" controls><source src="${
                    item.file_url
                  }#t=0.1"type="video/${
                    item.file_extension
                  }"></video></div></div>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);

                  // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                  // document.querySelectorAll(".new_video")[0].load();
                  document
                    .querySelectorAll(".new_video")[0]
                    .classList.remove("new_video");
                } else {
                  //다운로드가능한 파일일 경우
                  const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"> <a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                    item.unread_message_ids
                  }" class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span> <p class="time">${chatDate(
                    result.chat_date
                  )}</p> </div> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                    item.file_url
                  )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                    item.expiration_date
                  }</h1> <span>용량 : ${
                    item.file_size
                  }kb</span> </div> </div> </div>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  document
                    .querySelectorAll(
                      ".new_chat_file_row .chat_file_row_inner"
                    )[0]
                    .addEventListener("click", function () {
                      this.parentNode.querySelectorAll("a")[0].click();
                    });
                  document
                    .querySelectorAll(".new_chat_file_row")[0]
                    .classList.remove("new_chat_file_row");
                }
              }
            } else if (item.type_ == "message") {
              //메세지일 경우
              if (item.parent_id) {
                //답장일 경우
                fetch(
                  `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    let resMsg;
                    if (data.data.teamwork_message.type == "file") {
                      resMsg = fileNameCutter(
                        data.data.teamwork_message.file_url
                      );
                    } else {
                      resMsg =
                        data.data.teamwork_message.type === "schedule"
                          ? "[일정]"
                          : data.data.teamwork_message.message;
                    }
                    const htmlString = `<div idx="${
                      item.id
                    }" class="chat_row"> <div class="chat_row_wrapper_my"> <div class="response_msg_wrapper schedule_message message_${
                      item.id
                    }"><div class="response_msg_wrapper_top"><h1>${
                      data.data.teamwork_message.name
                    }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                      item.unread_message_ids
                    }" class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span> <p class="time">${chatDate(
                      result.chat_date
                    )}</p> </div> </div> </div>`;
                    appendTarget.insertAdjacentHTML("beforeend", htmlString);

                    document
                      .querySelectorAll(
                        `.schedule_message.message_${item.id}`
                      )[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(`.message_${item.parent_id}`)[0]
                          .scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                      });

                    if (
                      (varUAApp.match("olapse/Android") != null ||
                        varUAApp.match("olapse/iOS") != null) &&
                      document.querySelectorAll(".autolinkMsg")[0]
                    ) {
                      let msgLink = document
                        .querySelectorAll(".autolinkMsg")[0]
                        .getAttribute("href");
                      if (msgLink.indexOf("idx=") !== -1) {
                        document
                          .querySelectorAll(".autolinkMsg")[0]
                          .setAttribute(
                            "href",
                            "/join_team.html?idx=" +
                              msgLink.split("idx=")[1].split("&")[0]
                          );
                      }
                      document
                        .querySelectorAll(".autolinkMsg")[0]
                        .classList.remove("autolinkMsg");
                    }
                  });

                if (document.querySelectorAll(".new_show_more")[0]) {
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .addEventListener("click", function () {
                      this.parentNode.textContent = this.getAttribute("origin");
                      this.remove();
                    });
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .classList.remove("new_show_more");
                }
              } else {
                //일반 메세지일 경우

                const htmlString = `<div idx="${
                  item.id
                }" class="chat_row"> <div class="chat_row_wrapper_my"> <p class="msg_item">${msgString.autoLink()}</p> <div class="chat_info_right"> <span id_arr="${
                  item.unread_message_ids
                }" class="un_read">${
                  item.unread_message_count ? item.unread_message_count : ""
                }</span> <p class="time">${chatDate(
                  result.chat_date
                )}</p> </div> </div> </div>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                if (
                  (varUAApp.match("olapse/Android") != null ||
                    varUAApp.match("olapse/iOS") != null) &&
                  document.querySelectorAll(".autolinkMsg")[0]
                ) {
                  let msgLink = document
                    .querySelectorAll(".autolinkMsg")[0]
                    .getAttribute("href");
                  if (msgLink.indexOf("idx=") !== -1) {
                    document
                      .querySelectorAll(".autolinkMsg")[0]
                      .setAttribute(
                        "href",
                        "/join_team.html?idx=" +
                          msgLink.split("idx=")[1].split("&")[0]
                      );
                  }
                  document
                    .querySelectorAll(".autolinkMsg")[0]
                    .classList.remove("autolinkMsg");
                }
                if (document.querySelectorAll(".new_show_more")[0]) {
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .addEventListener("click", function () {
                      this.parentNode.textContent = this.getAttribute("origin");
                      this.remove();
                    });
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .classList.remove("new_show_more");
                }
              }
            } else if (item.type_ == "schedule" || item.type_ == "return") {
              if (item.type_ == "return") {
                document.querySelectorAll(
                  ".chat_data_inner>div[idx='" + item.etc + "']"
                )[0] &&
                  document
                    .querySelectorAll(
                      ".chat_data_inner>div[idx='" + item.etc + "']"
                    )[0]
                    .remove();
              }
              fetch(
                `https://api.olapse.com/api/teamwork/schedule/detail?id=${item.reference_id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(function (data) {
                  item.schedule = data.data.schedule;
                  let statusClassName, title;
                  if (item.schedule.status == 0) {
                    //요청
                    statusClassName = "request";
                    title = "일정이 요청되었습니다.";
                  } else if (item.schedule.status == 1) {
                    //수락
                    statusClassName = "admit";
                    title = `일정이 수락되었습니다.`;
                  } else if (item.schedule.status == 2) {
                    //완료
                    statusClassName = "complete";
                    title = `일정이 완료되었습니다.`;
                  } else if (item.schedule.status == 5) {
                    //보류
                    statusClassName = "stay";
                    title = `일정이 보류되었습니다.`;
                  } else if (item.schedule.status == 10) {
                    //취소
                    statusClassName = "cancel";
                    title = `일정이 취소되었습니다.`;
                  }
                  const htmlString = `<div data-schedule_id="${
                    item.schedule.id
                  }" idx="${item.id}" class="schedule_wrapper message_${
                    item.id
                  }"><div class="schedule_bottom_box"><p class="time">${chatDate(
                    result.chat_date
                  )}</p><span id_arr="${
                    item.unread_message_ids
                  }" class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span></div> <div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                    item.schedule.contents
                  }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                    item.schedule.place
                  }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                    item.schedule.begin_date
                  )} ~<br>${
                    item.schedule.end_date
                      ? dateSecondCutter(item.schedule.end_date)
                      : ""
                  }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                    item.schedule.price
                  )}원</span> </div>`;

                  appendTarget.insertAdjacentHTML("beforeend", htmlString);

                  document
                    .querySelectorAll(".new_schedule_detail_button")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".right_popup")
                        .forEach((item, idx) => {
                          _animateRightModule(false, item);
                        });
                      setTimeout(() => {
                        _animateRightModule(
                          true,
                          document.querySelectorAll(".d_4")[0]
                        );
                        updateScheduleData(item.schedule.id, myPermissionInfo);
                      }, 500);
                      document
                        .querySelectorAll(".common_more_wrapper li")
                        .forEach((item2, idx2) => {
                          item2.setAttribute(
                            "data-schedule_id",
                            item.schedule.id
                          );
                        });
                      document
                        .querySelectorAll(".edit_schedule")[0]
                        .setAttribute("data-schedule_id", item.schedule.id);
                    });
                  document
                    .querySelectorAll(".new_schedule_detail_button")[0]
                    .classList.remove("new_schedule_detail_button");
                  scrollBottom();
                });
            } else if (item.type_ == "notice") {
              //공지사항일 경우
              const htmlString = `<div idx="${
                item.id
              }" class="chat_row chat_row_notice"> <div class="chat_row_wrapper_my"> <p>${
                item.message
              }</p> <div class="chat_info_right"> <span id_arr="${
                item.unread_message_ids
              }" class="un_read">${
                item.unread_message_count ? item.unread_message_count : ""
              }</span> <p class="time">${chatDate(
                result.chat_date
              )}</p> </div> </div> </div>`;
              appendTarget.insertAdjacentHTML("beforeend", htmlString);
              noticeFunctionUpdate();
            } else if (
              item.type_ == "notice_modify" ||
              item.type_ == "notice_delete"
            ) {
              noticeFunctionUpdate();
            }
          } else {
            //타인이 보낸 메세지
            if (item.type_ == "file") {
              //파일일 경우
              if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                //이미지일 경우

                //dom 삽입
                let resendItem = document
                  .querySelectorAll(".toggle_button_3")[0]
                  .classList.contains("active")
                  ? myPermissionInfo == "팀장" || myPermissionInfo == "부팀장"
                    ? `<li class="chat_resend">답장</li>`
                    : ""
                  : `<li class="chat_resend">답장</li>`;
                const htmlString = `<div idx="${
                  item.id
                }" class="chat_row_2 new_chat_row chat_row_img_2 new_chat_row_img"><div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><div style="background: url('${
                  item.profile_url
                    ? item.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                }') no-repeat" class="chat_profile ${
                  item.is_leader == 1
                    ? "chatLeader_profile"
                    : item.is_sub_leader == 1
                    ? "chatSubLeader_profile"
                    : ""
                } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                  item.name
                }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <img class="chat_row_img_2_inner" src="${
                  item.file_url
                }" alt=""> <div class="chat_info_right"> <span id_arr="${
                  item.unread_message_ids
                }" class="un_read">${
                  item.unread_message_count ? item.unread_message_count : ""
                }</span> <p class="time">${chatDate(
                  result.chat_date
                )}</p> </div> </div> </div> </div> </div> </div>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);

                //이미지 뷰어 처리
                imgViewerFunction(
                  document.querySelectorAll(
                    ".new_chat_row_img .chat_row_img_2_inner"
                  )[0],
                  item.file_url,
                  item.name,
                  item
                );
                document
                  .querySelectorAll(".new_chat_row_img")[0]
                  .classList.remove("new_chat_row_img");

                //탭홀드 이벤트 할당
                document
                  .querySelectorAll(".new_chat_row")[0]
                  .setAttribute("data-long-press-delay", 750);

                document
                  .querySelectorAll(".new_chat_row")[0]
                  .addEventListener("long-press", function (e) {
                    if (!window.matchMedia("(max-width: 768px)").matches) {
                      e.preventDefault();
                    }
                    this.querySelectorAll(".chat_long_menu")[0].style.display =
                      "block";
                    document.querySelector("body").style.overflow = "hidden";
                  });

                document
                  .querySelectorAll(".new_chat_row")[0]
                  .addEventListener("mousedown", function (e) {
                    if (e.button == 2 || e.which == 3) {
                      if (!window.matchMedia("(max-width: 768px)").matches) {
                        e.preventDefault();
                      }
                      this.querySelectorAll(
                        ".chat_long_menu"
                      )[0].style.display = "block";
                      document.querySelector("body").style.overflow = "hidden";
                    }
                  });

                document
                  .querySelectorAll(".new_chat_row .chat_long_menu_white")[0]
                  .addEventListener("click", function () {
                    this.parentNode.style.display = "none";
                    document.querySelector("body").style.overflow = "auto";
                  });

                //답장기능
                document.querySelectorAll(".new_chat_row .chat_resend")[0] &&
                  document
                    .querySelectorAll(".new_chat_row .chat_resend")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".chat_data_area")[0]
                        .insertAdjacentHTML(
                          "beforeend",
                          `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>이미지</span><img src="/assets/images/icon_response_end.svg" /></div>`
                        );
                      document
                        .querySelectorAll("div.response_prev_ui img")[0]
                        .addEventListener("click", function () {
                          document
                            .querySelectorAll(".response_prev_ui")[0]
                            .remove();
                        });
                      this.parentNode.parentNode.style.display = "none";
                      document.querySelector("body").style.overflow = "visible";
                    });

                //공유기능
                document
                  .querySelectorAll(".new_chat_row .chat_long_menu_share")[0]
                  .addEventListener("click", function () {
                    magShare(
                      this.parentNode.parentNode.parentNode.getAttribute("idx")
                    );
                    document.querySelector("body").style.overflow = "visible";
                  });
                //저장기능
                document
                  .querySelectorAll(".new_chat_row .chat_long_menu_save")[0]
                  .addEventListener("click", function () {
                    document.querySelectorAll(".new_chat_row a")[0].click();
                    document.querySelector("body").style.overflow = "auto";
                  });

                //구분 클래스네임 삭제
                document
                  .querySelectorAll(".new_chat_row")[0]
                  .classList.remove("new_chat_row");
              } else {
                if (videoArr.indexOf(item.file_extension) !== -1) {
                  //동영상일경우
                  var videoName =
                    item.file_url.split("/")[
                      item.file_url.split("/").length - 1
                    ];
                  let resendItem = document
                    .querySelectorAll(".toggle_button_3")[0]
                    .classList.contains("active")
                    ? myPermissionInfo == "팀장" || myPermissionInfo == "부팀장"
                      ? `<li class="chat_resend">답장</li>`
                      : ""
                    : `<li class="chat_resend">답장</li>`;
                  const htmlString = `<div idx="${
                    item.id
                  }" class="chat_row_2 new_chat_row"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div> <div style="background:url('${
                    item.profile_url
                      ? item.profile_url
                      : "/assets/images/icon_profile_basic.svg"
                  }') no-repeat" class="chat_profile ${
                    item.is_leader == 1
                      ? "chatLeader_profile"
                      : item.is_sub_leader == 1
                      ? "chatSubLeader_profile"
                      : ""
                  } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                    item.name
                  }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" controls><source src="${
                    item.file_url
                  }#t=0.1"type="video/${
                    item.file_extension
                  }"></video></div> <div class="chat_info_right"><span id_arr="${
                    item.unread_message_ids
                  }" class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span> <p class="time">${chatDate(
                    result.chat_date
                  )}</p> </div> </div> </div> </div> </div> </div> </div>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                  // document.querySelectorAll(".new_video")[0].load();
                  document
                    .querySelectorAll(".new_video")[0]
                    .classList.remove("new_video");
                  //탭홀드 이벤트 할당
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .setAttribute("data-long-press-delay", 750);
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("long-press", function (e) {
                      if (!window.matchMedia("(max-width: 768px)").matches) {
                        e.preventDefault();
                      }
                      this.querySelectorAll(
                        ".chat_long_menu"
                      )[0].style.display = "block";
                      document.querySelector("body").style.overflow = "hidden";
                    });
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("mousedown", function (e) {
                      if (e.button == 2 || e.which == 3) {
                        if (!window.matchMedia("(max-width: 768px)").matches) {
                          e.preventDefault();
                        }
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].style.display = "block";
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                        document.querySelector("body").style.overflow =
                          "hidden";
                      }
                    });
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_white")[0]
                    .addEventListener("click", function () {
                      this.parentNode.style.display = "none";
                      document.querySelector("body").style.overflow = "auto";
                    });

                  //답장기능
                  document.querySelectorAll(".new_chat_row .chat_resend")[0] &&
                    document
                      .querySelectorAll(".new_chat_row .chat_resend")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".chat_data_area")[0]
                          .insertAdjacentHTML(
                            "beforeend",
                            `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>동영상</span><img src="/assets/images/icon_response_end.svg" /></div>`
                          );
                        document
                          .querySelectorAll("div.response_prev_ui img")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".response_prev_ui")[0]
                              .remove();
                          });
                        this.parentNode.parentNode.style.display = "none";
                      });

                  //공유기능
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_share")[0]
                    .addEventListener("click", function () {
                      magShare(
                        this.parentNode.parentNode.parentNode.getAttribute(
                          "idx"
                        )
                      );
                    });
                  //저장기능
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_save")[0]
                    .addEventListener("click", function () {
                      this.parentNode.parentNode.parentNode
                        .querySelectorAll("a")[0]
                        .click();
                      document.querySelector("body").style.overflow = "auto";
                    });

                  //구분 클래스네임 삭제
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .classList.remove("new_chat_row");
                } else {
                  //다운로드가능한 파일일 경우
                  let resendItem = document
                    .querySelectorAll(".toggle_button_3")[0]
                    .classList.contains("active")
                    ? myPermissionInfo == "팀장" || myPermissionInfo == "부팀장"
                      ? `<li class="chat_resend">답장</li>`
                      : ""
                    : `<li class="chat_resend">답장</li>`;
                  const htmlString = `<div idx="${
                    item.id
                  }" class="chat_row_2 chat_row_2_file new_chat_row_2 new_chat_row"> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div style="background: url('${
                    item.profile_url
                      ? item.profile_url
                      : "/assets/images/icon_profile_basic.svg"
                  }') no-repeat" class="chat_profile ${
                    item.is_leader == 1
                      ? "chatLeader_profile"
                      : item.is_sub_leader == 1
                      ? "chatSubLeader_profile"
                      : ""
                  } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                    item.name
                  }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                    item.file_url
                  )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                    item.expiration_date
                  }</h1> <span>용량 : ${
                    item.file_size
                  }kb</span> </div> </div> <div class="chat_info_right"> <span id_arr="${
                    item.unread_message_ids
                  }" class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span> <p class="time">${chatDate(
                    result.chat_date
                  )}</p> </div> </div> </div> </div> </div> </div>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  document
                    .querySelectorAll(".new_chat_row_2 .chat_info")[0]
                    .addEventListener("click", function () {
                      this.parentNode.querySelectorAll("a")[0].click();
                    });
                  document
                    .querySelectorAll(".new_chat_row_2")[0]
                    .classList.remove("new_chat_row_2");

                  //탭홀드 이벤트 할당
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .setAttribute("data-long-press-delay", 750);
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("long-press", function (e) {
                      if (!window.matchMedia("(max-width: 768px)").matches) {
                        e.preventDefault();
                      }
                      this.querySelectorAll(
                        ".chat_long_menu"
                      )[0].style.display = "block";
                      document.querySelector("body").style.overflow = "hidden";
                    });
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("mousedown", function (e) {
                      if (e.button == 2 || e.which == 3) {
                        if (!window.matchMedia("(max-width: 768px)").matches) {
                          e.preventDefault();
                        }
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].style.display = "block";
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                        document.querySelector("body").style.overflow =
                          "hidden";
                      }
                    });
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_white")[0]
                    .addEventListener("click", function () {
                      this.parentNode.style.display = "none";
                      document.querySelector("body").style.overflow = "auto";
                    });

                  //답장기능
                  document.querySelectorAll(".new_chat_row .chat_resend")[0] &&
                    document
                      .querySelectorAll(".new_chat_row .chat_resend")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".chat_data_area")[0]
                          .insertAdjacentHTML(
                            "beforeend",
                            `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>파일</span><img src="/assets/images/icon_response_end.svg" /></div>`
                          );
                        document
                          .querySelectorAll("div.response_prev_ui img")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".response_prev_ui")[0]
                              .remove();
                          });
                        this.parentNode.parentNode.style.display = "none";
                      });

                  //공유기능
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_share")[0]
                    .addEventListener("click", function () {
                      magShare(
                        this.parentNode.parentNode.parentNode.getAttribute(
                          "idx"
                        )
                      );
                    });
                  //저장기능
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_save")[0]
                    .addEventListener("click", function () {
                      document.querySelectorAll(".new_chat_row a")[0].click();
                      document.querySelector("body").style.overflow = "auto";
                    });

                  //구분 클래스네임 삭제
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .classList.remove("new_chat_row");
                }
              }
            } else if (item.type_ == "message") {
              //메세지일 경우
              if (item.parent_id) {
                //답장일 경우
                appendTarget.insertAdjacentHTML(
                  "beforeend",
                  `<div idx="${item.id}" class="prev_response_element"></div>`
                );
                fetch(
                  `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    let resMsg;
                    if (data.data.teamwork_message.type == "file") {
                      resMsg = fileNameCutter(
                        data.data.teamwork_message.file_url
                      );
                    } else {
                      resMsg =
                        data.data.teamwork_message.type === "schedule"
                          ? "[일정]"
                          : data.data.teamwork_message.message;
                    }

                    const htmlString = `<div idx="${
                      item.id
                    }" class="chat_row_2 new_chat_row "> <div style="background:url('${
                      item.profile_url
                        ? item.profile_url
                        : "/assets/images/icon_profile_basic.svg"
                    }') no-repeat" class="chat_profile ${
                      item.is_leader == 1
                        ? "chatLeader_profile"
                        : item.is_sub_leader == 1
                        ? "chatSubLeader_profile"
                        : ""
                    } new_chat_profile"></div><div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                      item.name
                    }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="response_msg_wrapper schedule_message message_${
                      item.id
                    }"><div class="response_msg_wrapper_top"><h1>${
                      data.data.teamwork_message.name
                    }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                      item.unread_message_ids
                    }" class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span> <p class="time">${chatDate(
                      item.chat_date
                    )}</p> </div> </div> </div> </div> </div> </div>`;

                    document
                      .querySelectorAll(".prev_response_element")
                      .forEach((item2, idx2) => {
                        if (item2.getAttribute("idx") == item.id) {
                          item2.insertAdjacentHTML("afterend", htmlString);

                          document
                            .querySelectorAll(
                              `.schedule_message.message_${item.id}`
                            )[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(
                                  `.message_${item.parent_id}`
                                )[0]
                                .scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                });
                            });

                          if (
                            (varUAApp.match("olapse/Android") != null ||
                              varUAApp.match("olapse/iOS") != null) &&
                            document.querySelectorAll(".autolinkMsg")[0]
                          ) {
                            let msgLink = document
                              .querySelectorAll(".autolinkMsg")[0]
                              .getAttribute("href");
                            if (msgLink.indexOf("idx=") !== -1) {
                              document
                                .querySelectorAll(".autolinkMsg")[0]
                                .setAttribute(
                                  "href",
                                  "/join_team.html?idx=" +
                                    msgLink.split("idx=")[1].split("&")[0]
                                );
                            }
                            document
                              .querySelectorAll(".autolinkMsg")[0]
                              .classList.remove("autolinkMsg");
                          }
                          item2.remove();
                          if (document.querySelectorAll(".new_show_more")[0]) {
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .addEventListener("click", function () {
                                this.parentNode.textContent =
                                  this.getAttribute("origin");
                                this.remove();
                              });
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .classList.remove("new_show_more");
                          }
                        }
                      });
                  });
              } else {
                //dom 삽입
                let resendItem = document
                  .querySelectorAll(".toggle_button_3")[0]
                  .classList.contains("active")
                  ? myPermissionInfo == "팀장" || myPermissionInfo == "부팀장"
                    ? `<li class="chat_resend">답장</li>`
                    : ""
                  : `<li class="chat_resend">답장</li>`;
                const htmlString = `<div idx="${
                  item.id
                }" class="chat_row_2 new_chat_row"><div class="chat_long_menu"><input class="copy_target" value='${
                  item.message
                }' type="text" /><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_copy">복사</li>${resendItem}</ul></div> <div style="background:url('${
                  item.profile_url
                    ? item.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                }') no-repeat" class="chat_profile ${
                  item.is_leader == 1
                    ? "chatLeader_profile"
                    : item.is_sub_leader == 1
                    ? "chatSubLeader_profile"
                    : ""
                } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                  item.name
                }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p class="msg_item">${msgString.autoLink()}</p> <div class="chat_info_right"> <span id_arr="${
                  item.unread_message_ids
                }" class="un_read">${
                  item.unread_message_count ? item.unread_message_count : ""
                }</span> <p class="time">${chatDate(
                  item.chat_date
                )}</p> </div> </div> </div> </div> </div> </div>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                if (
                  (varUAApp.match("olapse/Android") != null ||
                    varUAApp.match("olapse/iOS") != null) &&
                  document.querySelectorAll(".autolinkMsg")[0]
                ) {
                  let msgLink = document
                    .querySelectorAll(".autolinkMsg")[0]
                    .getAttribute("href");
                  if (msgLink.indexOf("idx=") !== -1) {
                    document
                      .querySelectorAll(".autolinkMsg")[0]
                      .setAttribute(
                        "href",
                        "/join_team.html?idx=" +
                          msgLink.split("idx=")[1].split("&")[0]
                      );
                  }
                  document
                    .querySelectorAll(".autolinkMsg")[0]
                    .classList.remove("autolinkMsg");
                }
                //250자 이상 처리
                if (document.querySelectorAll(".new_show_more")[0]) {
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .addEventListener("click", function () {
                      this.parentNode.textContent = this.getAttribute("origin");
                      this.remove();
                    });
                  document
                    .querySelectorAll(".new_show_more")[0]
                    .classList.remove("new_show_more");
                }

                //탭홀드 이벤트 할당
                document
                  .querySelectorAll(".new_chat_row")[0]
                  .setAttribute("data-long-press-delay", 750);

                document
                  .querySelectorAll(".new_chat_row")[0]
                  .addEventListener("long-press", function (e) {
                    if (!window.matchMedia("(max-width: 768px)").matches) {
                      e.preventDefault();
                    }
                    this.querySelectorAll(".chat_long_menu")[0].style.display =
                      "block";
                    document.querySelector("body").style.overflow = "hidden";
                  });

                document
                  .querySelectorAll(".new_chat_row")[0]
                  .addEventListener("mousedown", function (e) {
                    if (e.button == 2 || e.which == 3) {
                      if (!window.matchMedia("(max-width: 768px)").matches) {
                        e.preventDefault();
                      }
                      this.querySelectorAll(
                        ".chat_long_menu"
                      )[0].style.display = "block";
                      document.querySelector("body").style.overflow = "hidden";
                    }
                  });
                document
                  .querySelectorAll(".new_chat_row .chat_long_menu_white")[0]
                  .addEventListener("click", function () {
                    this.parentNode.style.display = "none";
                    document.querySelector("body").style.overflow = "visible";
                  });

                //메세지 복사 기능
                document
                  .querySelectorAll(".new_chat_row .chat_long_menu_copy")[0]
                  .addEventListener("click", function () {
                    copyToClipboard(
                      this.parentNode.parentNode.querySelectorAll(
                        ".copy_target"
                      )[0]
                    );
                    this.parentNode.parentNode.style.display = "none";
                    createPopup({
                      type: "confirm",
                      title: "복사완료",
                      content: "선택하신 메세지를 클립보드에 복사하였습니다.",
                    });
                    document.querySelector("body").style.overflow = "visible";
                  });

                //답장기능
                document.querySelectorAll(".new_chat_row .chat_resend")[0] &&
                  document
                    .querySelectorAll(".new_chat_row .chat_resend")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".chat_data_area")[0]
                        .insertAdjacentHTML(
                          "beforeend",
                          `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>${item.message}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                        );
                      document
                        .querySelectorAll("div.response_prev_ui img")[0]
                        .addEventListener("click", function () {
                          document
                            .querySelectorAll(".response_prev_ui")[0]
                            .remove();
                        });
                      this.parentNode.parentNode.style.display = "none";
                      document.querySelector("body").style.overflow = "visible";
                    });

                //구분 클래스네임 삭제
                document
                  .querySelectorAll(".new_chat_row")[0]
                  .classList.remove("new_chat_row");
              }
            } else if (item.type_ == "schedule" || item.type_ == "return") {
              //dom 삽입
              let resendItem = document
                .querySelectorAll(".toggle_button_3")[0]
                .classList.contains("active")
                ? myPermissionInfo == "팀장" || myPermissionInfo == "부팀장"
                  ? `<li class="chat_resend">답장</li>`
                  : ""
                : `<li class="chat_resend">답장</li>`;
              const menuString = `<div class="chat_long_menu"><input class="copy_target" value='${item.message}' type="text" /><div class="chat_long_menu_white"></div><ul>${resendItem}</ul></div>`;

              if (item.type_ == "return") {
                document.querySelectorAll(
                  ".chat_data_inner>div[idx='" + item.etc + "']"
                )[0] &&
                  document
                    .querySelectorAll(
                      ".chat_data_inner>div[idx='" + item.etc + "']"
                    )[0]
                    .remove();
              }

              fetch(
                `https://api.olapse.com/api/teamwork/schedule/detail?id=${item.reference_id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
                .then(function (response) {
                  return response.json();
                })
                .then(function (data) {
                  item.schedule = data.data.schedule;
                  let statusClassName, title;
                  if (item.schedule.status == 0) {
                    //요청
                    statusClassName = "request";
                    title = "일정이 요청되었습니다.";
                  } else if (item.schedule.status == 1) {
                    //수락
                    statusClassName = "admit";
                    title = `일정이 수락되었습니다.`;
                  } else if (item.schedule.status == 2) {
                    //완료
                    statusClassName = "complete";
                    title = `일정이 완료되었습니다.`;
                  } else if (item.schedule.status == 5) {
                    //보류
                    statusClassName = "stay";
                    title = `일정이 보류되었습니다.`;
                  } else if (item.schedule.status == 10) {
                    //취소
                    statusClassName = "cancel";
                    title = `일정이 취소되었습니다.`;
                  }

                  const htmlString = `<div data-schedule_id="${
                    item.schedule.id
                  }" idx="${
                    item.id
                  }" class="schedule_wrapper new_chat_row message_${
                    item.id
                  }">${menuString}<div class="schedule_bottom_box"><p class="time">${chatDate(
                    result.chat_date
                  )}</p><span class="un_read">${
                    item.unread_message_count ? item.unread_message_count : ""
                  }</span></div><div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                    item.schedule.contents
                  }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                    item.schedule.place
                  }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                    item.schedule.begin_date
                  )} ~<br>${
                    item.schedule.end_date
                      ? dateSecondCutter(item.schedule.end_date)
                      : ""
                  }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                    item.schedule.price
                  )}원</span> </div>`;

                  appendTarget.insertAdjacentHTML("beforeend", htmlString);

                  document
                    .querySelectorAll(".new_schedule_detail_button")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".right_popup")
                        .forEach((item, idx) => {
                          _animateRightModule(false, item);
                        });

                      setTimeout(() => {
                        _animateRightModule(
                          true,
                          document.querySelectorAll(".d_4")[0]
                        );
                        updateScheduleData(item.schedule.id, myPermissionInfo);
                      }, 500);

                      document
                        .querySelectorAll(".common_more_wrapper li")
                        .forEach((item2, idx2) => {
                          item2.setAttribute(
                            "data-schedule_id",
                            item.schedule.id
                          );
                        });
                      document
                        .querySelectorAll(".edit_schedule")[0]
                        .setAttribute("data-schedule_id", item.schedule.id);
                    });

                  document
                    .querySelectorAll(".new_schedule_detail_button")[0]
                    .classList.remove("new_schedule_detail_button");

                  scrollBottom();

                  //답장기능
                  document.querySelectorAll(".new_chat_row .chat_resend")[0] &&
                    document
                      .querySelectorAll(".new_chat_row .chat_resend")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".chat_data_area")[0]
                          .insertAdjacentHTML(
                            "beforeend",
                            `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>[일정] ${item.schedule.contents}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                          );
                        document
                          .querySelectorAll("div.response_prev_ui img")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".response_prev_ui")[0]
                              .remove();
                          });
                        this.parentNode.parentNode.style.display = "none";
                        document.querySelector("body").style.overflow =
                          "visible";
                      });

                  //탭홀드 이벤트 할당
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .setAttribute("data-long-press-delay", 750);
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("long-press", function (e) {
                      if (!window.matchMedia("(max-width: 768px)").matches) {
                        e.preventDefault();
                      }
                      this.querySelectorAll(
                        ".chat_long_menu"
                      )[0].style.display = "block";
                      document.querySelector("body").style.overflow = "hidden";
                    });
                  document
                    .querySelectorAll(".new_chat_row")[0]
                    .addEventListener("mousedown", function (e) {
                      if (e.button == 2 || e.which == 3) {
                        if (!window.matchMedia("(max-width: 768px)").matches) {
                          e.preventDefault();
                        }
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].style.display = "block";
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                        document.querySelector("body").style.overflow =
                          "hidden";
                      }
                    });
                  document
                    .querySelectorAll(".new_chat_row .chat_long_menu_white")[0]
                    .addEventListener("click", function () {
                      this.parentNode.style.display = "none";
                      document.querySelector("body").style.overflow = "visible";
                    });
                });
            } else if (item.type_ == "notice") {
              //공지사항일 경우
              const htmlString = `<div idx="${
                item.id
              }" class="chat_row_2 chat_row_notice"> <div style="background:url('${
                item.profile_url
                  ? item.profile_url
                  : "/assets/images/icon_profile_basic.svg"
              }') no-repeat" class="chat_profile ${
                item.is_leader == 1
                  ? "chatLeader_profile"
                  : item.is_sub_leader == 1
                  ? "chatSubLeader_profile"
                  : ""
              } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                item.name
              }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p>${
                item.message
              }</p> <div class="chat_info_right"> <span id_arr="${
                item.unread_message_ids
              }" class="un_read">${
                item.unread_message_count ? item.unread_message_count : ""
              }</span> <p class="time">${chatDate(
                result.chat_date
              )}</p> </div> </div> </div> </div> </div> </div>`;
              appendTarget.insertAdjacentHTML("beforeend", htmlString);
              noticeFunctionUpdate();
            } else if (
              item.type_ == "notice_modify" ||
              item.type_ == "notice_delete"
            ) {
              noticeFunctionUpdate();
            }

            if (document.querySelectorAll(".new_chat_profile")[0]) {
              memberDetailFunction(
                document.querySelectorAll(".new_chat_profile")[0],
                item.teamwork_user_id,
                item.user_id,
                item
              );
              document
                .querySelectorAll(".new_chat_profile")[0]
                ?.classList.remove("new_chat_profile");
            }

            if (result.type_ !== "enter") {
              memberDetailFunction(
                document.querySelectorAll(".new_chat_profile")[0],
                result.teamwork_user_id,
                result.user_id
              );
              document
                .querySelectorAll(".new_chat_profile")[0]
                ?.classList.remove("new_chat_profile");
            }
          }
        }
        setTimeout(() => {
          if (
            e.type !== "accept" &&
            e.type !== "complete" &&
            e.type !== "calculate_complete" &&
            e.type !== "hold" &&
            e.type !== "cancel"
          ) {
            clearInterval(readSet);
            readCount = 1000;
            readSet = setInterval(() => {
              readCount = readCount - 100;
              if (readCount == 0) {
                var chSetting = setInterval(() => {
                  if (
                    document.querySelectorAll(
                      ".chat_data_inner > div:last-of-type"
                    )[0]
                  ) {
                    readLastMsg(
                      document
                        .querySelectorAll(
                          ".chat_data_inner > div:last-of-type"
                        )[0]
                        .getAttribute("idx"),
                      getParameterByName("idx")
                    );
                    clearInterval(chSetting);
                  }
                }, 100);
              }
            }, 100);
          }
          // updateChatState();
          if (e.teamwork_id == getParameterByName("idx")) {
            scrollBottom();
          }
        }, 10);
      }
    };

    //팀워크 상세정보 + 이전 메세지 insert
    const getTeamworkDetailInfo = () => {
      var mHSize =
        document.querySelectorAll(".chatting_header")[0].clientHeight +
        document.querySelectorAll(".chat_write")[0].clientHeight;
      if (document.querySelectorAll(".chat_loading")[0]) {
        document.querySelectorAll(".chat_loading")[0].style.height =
          "calc(100vh - " + mHSize + "px)";
        document.querySelectorAll(".chat_loading")[0].style.top =
          document.querySelectorAll(".chatting_header")[0].clientHeight + "px";
      }

      //첫번째 페이지로 init
      inner(
        `https://api.olapse.com/api/teamwork/detail?page=1&id=${props.teamworkId}`
      );
      function inner(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            //팀장일 때, 일반 팀원일때의 분기 처리
            if (data.data.teamwork_user.is_leader == 1) {
              //팀워크 설정 진입 방지
              document
                .querySelectorAll(".go_edit")[0]
                .addEventListener("click", function () {
                  _animateRightModule(
                    true,
                    document.querySelectorAll(".d_2")[0]
                  );
                });
              //나가기, 팀워크 삭제하기 표시 설정
              document.querySelectorAll(".end_teamwork")[0].textContent =
                "팀워크 삭제";
              endTeamwork("remove");
            } else {
              document.querySelectorAll(".end_teamwork")[0].textContent =
                "나가기";
              endTeamwork("leave");
            }
            //내 팀워크 아이디값 할당
            teamworkUserId = data.data.teamwork_user.id;
            document
              .querySelectorAll(".btnMyCal")[0]
              .addEventListener("click", function () {
                myCalFunction(teamworkUserId);
              });

            //메뉴 정보 기입
            document.querySelectorAll(
              "div.teamwork_row div.teamwork_col h1"
            )[0].textContent = data.data.teamwork.title;
            document.querySelectorAll(
              "div.teamwork_row div.teamwork_col span"
            )[0].textContent = `참여 멤버 : ${data.data.teamwork.total_user_count}명`;
            //첫번째 메세지 시간 표기
            // data.data.teamwork_messages.data[0] ? (document.querySelectorAll(".line_noticeStart .teamwork_start_date")[0].textContent = data.data.teamwork_messages.data[data.data.teamwork_messages.data.length - 1].created_at.split(" ")[0]) : (document.querySelectorAll(".line_noticeStart .teamwork_start_date")[0].textContent = "팀워크 채팅을 시작해보세요.");
            //타이틀
            document.querySelectorAll(".team_work_title_1")[0].textContent =
              data.data.teamwork.title;
            if (data.data.teamwork.title == "나와의 채팅") {
              document.querySelectorAll(
                "div.chat_sub_write_menu ul li"
              )[1].style.display = "none";
              document.querySelectorAll(".chat_menu_button")[0].style.display =
                "none";
              document.querySelectorAll(
                ".chat_myMenu_button"
              )[0].style.display = "block";
            }
            if (data.data.teamwork_messages.next_page_url) {
              // 채팅 1페이지 초과일 경우
              sprData();
            } else {
              // 채팅이 1페이지 이하일 경우
              sprData();
              document.querySelectorAll(
                ".rest_chat_data_button"
              )[0].style.display = "none";
            }

            var pageCount,
              lastCount,
              settingType = 0;

            function nextDataSet() {
              document.querySelectorAll(".rest_chat_data_button")[0] &&
                document.querySelectorAll(".rest_chat_data_button")[0].click();
              setTimeout(() => {
                settingType = 0;
              }, 1000);
            }
            //스크롤 체크
            function topChk() {
              let scrollTop = document.documentElement.scrollTop;

              if (scrollTop == 0) {
                return true;
              } else {
                return false;
              }
            }
            function topChkM() {
              let scrollTop =
                document.querySelectorAll(".chat_data_inner")[0].scrollTop;

              if (scrollTop == 0) {
                return true;
              } else {
                return false;
              }
            }
            function addChat() {
              if (
                topChk() &&
                lastCount > 1 &&
                pageCount !== 1 &&
                settingType == 0
              ) {
                settingType = 1;
                nextDataSet();
              }
            }
            function addChatM() {
              if (
                topChkM() &&
                lastCount > 1 &&
                pageCount !== 1 &&
                settingType == 0
              ) {
                settingType = 1;
                nextDataSet();
              }
            }

            document
              .querySelectorAll(".chat_data_inner")[0]
              .addEventListener("scroll", function () {
                addChatM();
              });
            document.addEventListener("scroll", function () {
              addChat();
            });
            function sprData() {
              if (data.data.teamwork_messages.last_page > 1) {
                //다른 페이지도 존재할 경우 해당 url push + 버튼 노출 + 마지막 페이지는 노출
                lastCount = data.data.teamwork_messages.last_page;
                pageCount = 2;
                // for (let i = 2; i < Number(data.data.teamwork_messages.last_page); i++) {
                //     restChatInfoArr.push(`https://api.olapse.com/api/teamwork/detail?page=${i}&id=${props.teamworkId}`);
                // }
                // restChatInfoArr.reverse();

                document.querySelectorAll(
                  ".rest_chat_data_button"
                )[0].style.display = "flex";
                document
                  .querySelectorAll(".rest_chat_data_button")[0]
                  .addEventListener("click", function () {
                    // if (restChatInfoArr.length !== 0) {
                    //     excute(restChatInfoArr[restChatInfoArr.length - 1]), restChatInfoArr.pop();
                    // } else {
                    //     document.querySelectorAll(".rest_chat_data_button")[0].style.display = "none";
                    // }
                    excute(
                      `https://api.olapse.com/api/teamwork/detail?page=${pageCount}&id=${props.teamworkId}`
                    ),
                      restChatInfoArr.pop();
                    if (pageCount >= lastCount) {
                      document.querySelectorAll(
                        ".rest_chat_data_button"
                      )[0].style.display = "none";
                    }
                    pageCount = pageCount + 1;
                  });
              }
              if (data.data.teamwork_messages.data.length > 0) {
                data.data.teamwork_messages.data
                  .reverse()
                  .forEach((item, idx) => {
                    addDateFunc(
                      item,
                      idx,
                      data.data.teamwork_messages.data.length - 1,
                      true
                    );
                    let msgString;
                    //메세지가 250자 이상일 경우 처리
                    if (item.message && String(item.message).length > 250) {
                      msgString = `${item.message.substring(
                        0,
                        250
                      )}... <span origin="${
                        item.message
                      }" class="new_show_more">더보기</span>`;
                    } else {
                      msgString = `${item.message}`;
                    }

                    if (item.user_id == myId) {
                      //내가 보낸 메세지
                      if (item.type == "file") {
                        //파일일 경우 (row 추가 o)
                        if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                          //이미지일 경우 (row 추가 o)
                          const htmlString = `<div idx="${
                            item.id
                          }" class="new_chat_row_img chat_row user_new_chat_row"> <div class="chat_row_wrapper_my"> <img class="chat_img_right" src="${
                            item.file_url
                          }" /> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div>`;
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            htmlString
                          );
                          imgViewerFunction(
                            document.querySelectorAll(
                              ".new_chat_row_img .chat_img_right"
                            )[0],
                            item.file_url,
                            item.name,
                            item
                          );
                          document
                            .querySelectorAll(".new_chat_row_img")[0]
                            .classList.remove("new_chat_row_img");
                        } else {
                          if (videoArr.indexOf(item.file_extension) !== -1) {
                            //동영상일경우
                            var videoName =
                              item.file_url.split("/")[
                                item.file_url.split("/").length - 1
                              ];
                            const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                              item.unread_message_ids
                            }" class="un_read">${
                              item.unread_message_count
                                ? item.unread_message_count
                                : ""
                            }</span> <p class="time">${chatDate(
                              item.created_at
                            )}</p> </div><div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" controls><source src="${
                              item.file_url
                            }#t=0.1"type="video/${
                              item.file_extension
                            }"></video></div></div>`;
                            appendTarget.insertAdjacentHTML(
                              "beforeend",
                              htmlString
                            );
                            // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                            // document.querySelectorAll(".new_video")[0].load();
                            document
                              .querySelectorAll(".new_video")[0]
                              .classList.remove("new_video");
                          } else {
                            //다운로드가능한 파일일 경우
                            const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"> <a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                              item.unread_message_ids
                            }" class="un_read">${
                              item.unread_message_count
                                ? item.unread_message_count
                                : ""
                            }</span> <p class="time">${chatDate(
                              item.created_at
                            )}</p> </div> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                              item.file_url
                            )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                              item.expiration_date
                            }</h1> <span>용량 : ${
                              item.file_size
                            }kb</span> </div> </div> </div>`;
                            appendTarget.insertAdjacentHTML(
                              "beforeend",
                              htmlString
                            );
                            document
                              .querySelectorAll(
                                ".new_chat_file_row .chat_file_row_inner"
                              )[0]
                              .addEventListener("click", function () {
                                this.parentNode
                                  .querySelectorAll("a")[0]
                                  .click();
                              });
                            document
                              .querySelectorAll(".new_chat_file_row")[0]
                              .classList.remove("new_chat_file_row");
                          }
                        }
                      } else if (item.type == "message") {
                        //메세지일 경우
                        if (item.parent_id !== null) {
                          //답장일 경우
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            `<div idx="${item.id}" class="prev_response_element"></div>`
                          );
                          fetch(
                            `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                            {
                              method: "GET",
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          )
                            .then(function (response) {
                              return response.json();
                            })
                            .then(function (data) {
                              let resMsg;
                              if (data.data.teamwork_message.type == "file") {
                                resMsg = fileNameCutter(
                                  data.data.teamwork_message.file_url
                                );
                              } else {
                                resMsg =
                                  data.data.teamwork_message.type === "schedule"
                                    ? "[일정]"
                                    : data.data.teamwork_message.message;
                              }
                              const htmlString = `<div idx="${
                                item.id
                              }" class="chat_row"> <div class="chat_row_wrapper_my"> <div class="response_msg_wrapper schedule_message message_${
                                item.id
                              }"><div class="response_msg_wrapper_top"><h1>${
                                data.data.teamwork_message.name
                              }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                                item.unread_message_ids
                              }" class="un_read">${
                                item.unread_message_count
                                  ? item.unread_message_count
                                  : ""
                              }</span> <p class="time">${chatDate(
                                item.created_at
                              )}</p> </div> </div> </div>`;
                              document
                                .querySelectorAll(".prev_response_element")
                                .forEach((item2, idx2) => {
                                  if (item2.getAttribute("idx") == item.id) {
                                    item2.insertAdjacentHTML(
                                      "afterend",
                                      htmlString
                                    );

                                    document
                                      .querySelectorAll(
                                        `.schedule_message.message_${item.id}`
                                      )[0]
                                      .addEventListener("click", function () {
                                        document
                                          .querySelectorAll(
                                            `.message_${item.parent_id}`
                                          )[0]
                                          .scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                          });
                                      });

                                    if (
                                      (varUAApp.match("olapse/Android") !=
                                        null ||
                                        varUAApp.match("olapse/iOS") != null) &&
                                      document.querySelectorAll(
                                        ".autolinkMsg"
                                      )[0]
                                    ) {
                                      let msgLink = document
                                        .querySelectorAll(".autolinkMsg")[0]
                                        .getAttribute("href");
                                      if (msgLink.indexOf("idx=") !== -1) {
                                        document
                                          .querySelectorAll(".autolinkMsg")[0]
                                          .setAttribute(
                                            "href",
                                            "/join_team.html?idx=" +
                                              msgLink
                                                .split("idx=")[1]
                                                .split("&")[0]
                                          );
                                      }
                                      document
                                        .querySelectorAll(".autolinkMsg")[0]
                                        .classList.remove("autolinkMsg");
                                    }
                                    item2.remove();
                                    if (
                                      document.querySelectorAll(
                                        ".new_show_more"
                                      )[0]
                                    ) {
                                      document
                                        .querySelectorAll(".new_show_more")[0]
                                        .addEventListener("click", function () {
                                          this.parentNode.textContent =
                                            this.getAttribute("origin");
                                          this.remove();
                                        });
                                      document
                                        .querySelectorAll(".new_show_more")[0]
                                        .classList.remove("new_show_more");
                                    }
                                  }
                                });
                            });
                        } else {
                          //일반 메세지일 경우
                          const htmlString = `<div idx="${
                            item.id
                          }" class="chat_row"> <div class="chat_row_wrapper_my"> <p class="msg_item">${msgString.autoLink()}</p> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div>`;
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            htmlString
                          );

                          if (
                            (varUAApp.match("olapse/Android") != null ||
                              varUAApp.match("olapse/iOS") != null) &&
                            document.querySelectorAll(".autolinkMsg")[0]
                          ) {
                            let msgLink = document
                              .querySelectorAll(".autolinkMsg")[0]
                              .getAttribute("href");
                            if (msgLink.indexOf("idx=") !== -1) {
                              document
                                .querySelectorAll(".autolinkMsg")[0]
                                .setAttribute(
                                  "href",
                                  "/join_team.html?idx=" +
                                    msgLink.split("idx=")[1].split("&")[0]
                                );
                            }
                            document
                              .querySelectorAll(".autolinkMsg")[0]
                              .classList.remove("autolinkMsg");
                          }
                          if (document.querySelectorAll(".new_show_more")[0]) {
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .addEventListener("click", function () {
                                this.parentNode.textContent =
                                  this.getAttribute("origin");
                                this.remove();
                              });
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .classList.remove("new_show_more");
                          }
                        }
                      } else if (
                        item.type == "schedule" ||
                        item.type == "return"
                      ) {
                        let resendItem = document
                          .querySelectorAll(".toggle_button_3")[0]
                          .classList.contains("active")
                          ? myPermissionInfo == "팀장" ||
                            myPermissionInfo == "부팀장"
                            ? `<li class="chat_resend">답장</li>`
                            : ""
                          : `<li class="chat_resend">답장</li>`;
                        const menuString = `<div class="chat_long_menu"><input class="copy_target" value='${item.message}' type="text" /><div class="chat_long_menu_white"></div><ul>${resendItem}</ul></div>`;

                        if (item.type == "return") {
                          document.querySelectorAll(
                            ".chat_data_inner>div[idx='" + item.etc + "']"
                          )[0] &&
                            document
                              .querySelectorAll(
                                ".chat_data_inner>div[idx='" + item.etc + "']"
                              )[0]
                              .remove();
                        }
                        let statusClassName, title;
                        if (item.schedule.status == 0) {
                          //요청
                          statusClassName = "request";
                          title = "일정이 요청되었습니다.";
                        } else if (item.schedule.status == 1) {
                          //수락
                          statusClassName = "admit";
                          title = `일정이 수락되었습니다.`;
                        } else if (item.schedule.status == 2) {
                          //완료
                          statusClassName = "complete";
                          title = `일정이 완료되었습니다.`;
                        } else if (item.schedule.status == 5) {
                          //보류
                          statusClassName = "stay";
                          title = `일정이 보류되었습니다.`;
                        } else if (item.schedule.status == 10) {
                          //취소
                          statusClassName = "cancel";
                          title = `일정이 취소되었습니다.`;
                        }
                        const htmlString = `<div data-schedule_id="${
                          item.schedule.id
                        }" idx="${
                          item.id
                        }" class="schedule_wrapper new_schedule_wrapper new_chat_row message_${
                          item.id
                        }">${menuString}<div class="schedule_bottom_box"><p class="time">${chatDate(
                          item.created_at
                        )}</p><span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span></div> <div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                          item.schedule.contents
                        }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                          item.schedule.place
                        }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                          item.schedule.begin_date
                        )} ~<br>${
                          item.schedule.end_date
                            ? dateSecondCutter(item.schedule.end_date)
                            : ""
                        }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                          item.schedule.price
                        )}원</span> </div>`;
                        appendTarget.insertAdjacentHTML(
                          "beforeend",
                          htmlString
                        );

                        if (
                          item.schedule.accept_user_id == myId ||
                          myPermissionInfo == "팀장" ||
                          myPermissionInfo == "부팀장"
                        ) {
                        } else {
                          if (item.schedule.status !== 0) {
                            document.querySelectorAll(
                              ".new_schedule_wrapper"
                            )[0].style.display = "none";
                          }
                        }
                        document.querySelectorAll(".new_schedule_wrapper")[0] &&
                          document
                            .querySelectorAll(".new_schedule_wrapper")[0]
                            .classList.remove("new_schedule_wrapper");

                        document.querySelectorAll(
                          ".new_schedule_detail_button"
                        )[0] &&
                          document
                            .querySelectorAll(".new_schedule_detail_button")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".right_popup")
                                .forEach((item, idx) => {
                                  _animateRightModule(false, item);
                                });
                              setTimeout(() => {
                                _animateRightModule(
                                  true,
                                  document.querySelectorAll(".d_4")[0]
                                );
                                updateScheduleData(
                                  item.schedule.id,
                                  myPermissionInfo
                                );
                              }, 500);
                              document
                                .querySelectorAll(".common_more_wrapper li")
                                .forEach((item2, idx2) => {
                                  item2.setAttribute(
                                    "data-schedule_id",
                                    item.schedule.id
                                  );
                                });
                              document
                                .querySelectorAll(".edit_schedule")[0]
                                .setAttribute(
                                  "data-schedule_id",
                                  item.schedule.id
                                );
                            });
                        document
                          .querySelectorAll(".new_schedule_detail_button")[0]
                          .classList.remove("new_schedule_detail_button");

                        //답장기능
                        document.querySelectorAll(
                          ".new_chat_row .chat_resend"
                        )[0] &&
                          document
                            .querySelectorAll(".new_chat_row .chat_resend")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".chat_data_area")[0]
                                .insertAdjacentHTML(
                                  "beforeend",
                                  `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>[일정] ${item.schedule.contents}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                );
                              document
                                .querySelectorAll("div.response_prev_ui img")[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".response_prev_ui")[0]
                                    .remove();
                                });
                              this.parentNode.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "visible";
                            });

                        //탭홀드 이벤트 할당
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .setAttribute("data-long-press-delay", 750);
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("long-press", function (e) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                            document.querySelector("body").style.overflow =
                              "hidden";
                          });
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("mousedown", function (e) {
                            if (e.button == 2 || e.which == 3) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            }
                          });
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_white"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "visible";
                          });
                      } else if (
                        item.type == "notice" ||
                        item.type == undefined
                      ) {
                        //공지사항일 경우
                        const htmlString = `<div idx="${
                          item.id
                        }" class="chat_row chat_row_notice"> <div class="chat_row_wrapper_my"> <p>${
                          item.message
                        }</p> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div> </div> </div>`;
                        appendTarget.insertAdjacentHTML(
                          "beforeend",
                          htmlString
                        );
                        noticeFunctionUpdate();
                      } else if (
                        item.type_ == "notice_modify" ||
                        item.type_ == "notice_delete"
                      ) {
                        noticeFunctionUpdate();
                      }
                    } else {
                      //타인이 보낸 메세지
                      if (item.type == "file") {
                        //파일일 경우
                        if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                          //이미지일 경우

                          //dom 삽입
                          let resendItem = document
                            .querySelectorAll(".toggle_button_3")[0]
                            .classList.contains("active")
                            ? myPermissionInfo == "팀장" ||
                              myPermissionInfo == "부팀장"
                              ? `<li class="chat_resend">답장</li>`
                              : ""
                            : `<li class="chat_resend">답장</li>`;
                          const htmlString = `<div idx="${
                            item.id
                          }" class="chat_row_2 new_chat_row chat_row_img_2 new_chat_row_img"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a><div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><div style="background: url('${
                            item.profile_url
                              ? item.profile_url
                              : "/assets/images/icon_profile_basic.svg"
                          }') no-repeat" class="chat_profile ${
                            item.is_leader == 1
                              ? "chatLeader_profile"
                              : item.is_sub_leader == 1
                              ? "chatSubLeader_profile"
                              : ""
                          } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                            item.name
                          }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <img class="chat_row_img_2_inner" src="${
                            item.file_url
                          }" alt=""> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div> </div> </div> </div>`;
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            htmlString
                          );

                          //이미지 뷰어 처리
                          imgViewerFunction(
                            document.querySelectorAll(
                              ".new_chat_row_img .chat_row_img_2_inner"
                            )[0],
                            item.file_url,
                            item.name,
                            item
                          );
                          document
                            .querySelectorAll(".new_chat_row_img")[0]
                            .classList.remove("new_chat_row_img");

                          //탭홀드 이벤트 할당
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .setAttribute("data-long-press-delay", 750);
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .addEventListener("long-press", function (e) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            });
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .addEventListener("mousedown", function (e) {
                              if (e.button == 2 || e.which == 3) {
                                if (
                                  !window.matchMedia("(max-width: 768px)")
                                    .matches
                                ) {
                                  e.preventDefault();
                                }
                                this.querySelectorAll(
                                  ".chat_long_menu"
                                )[0].style.display = "block";
                                document.querySelector("body").style.overflow =
                                  "hidden";
                              }
                            });
                          document
                            .querySelectorAll(
                              ".new_chat_row .chat_long_menu_white"
                            )[0]
                            .addEventListener("click", function () {
                              this.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "auto";
                            });

                          //답장기능
                          document.querySelectorAll(
                            ".new_chat_row .chat_resend"
                          )[0] &&
                            document
                              .querySelectorAll(".new_chat_row .chat_resend")[0]
                              .addEventListener("click", function () {
                                document
                                  .querySelectorAll(".chat_data_area")[0]
                                  .insertAdjacentHTML(
                                    "beforeend",
                                    `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>이미지</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                  );
                                document
                                  .querySelectorAll(
                                    "div.response_prev_ui img"
                                  )[0]
                                  .addEventListener("click", function () {
                                    document
                                      .querySelectorAll(".response_prev_ui")[0]
                                      .remove();
                                  });
                                this.parentNode.parentNode.style.display =
                                  "none";
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });

                          //공유기능
                          document
                            .querySelectorAll(
                              ".new_chat_row .chat_long_menu_share"
                            )[0]
                            .addEventListener("click", function () {
                              magShare(
                                this.parentNode.parentNode.parentNode.getAttribute(
                                  "idx"
                                )
                              );
                              document.querySelector("body").style.overflow =
                                "auto";
                            });
                          //저장기능
                          document
                            .querySelectorAll(
                              ".new_chat_row .chat_long_menu_save"
                            )[0]
                            .addEventListener("click", function () {
                              this.parentNode.parentNode.parentNode
                                .querySelectorAll("a")[0]
                                .click();
                              document.querySelector("body").style.overflow =
                                "auto";
                            });

                          //구분 클래스네임 삭제
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .classList.remove("new_chat_row");
                        } else {
                          if (videoArr.indexOf(item.file_extension) !== -1) {
                            //동영상일경우
                            var videoName =
                              item.file_url.split("/")[
                                item.file_url.split("/").length - 1
                              ];
                            let resendItem = document
                              .querySelectorAll(".toggle_button_3")[0]
                              .classList.contains("active")
                              ? myPermissionInfo == "팀장" ||
                                myPermissionInfo == "부팀장"
                                ? `<li class="chat_resend">답장</li>`
                                : ""
                              : `<li class="chat_resend">답장</li>`;
                            const htmlString = `<div idx="${
                              item.id
                            }" class="chat_row_2 new_chat_row"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div> <div style="background:url('${
                              item.profile_url
                                ? item.profile_url
                                : "/assets/images/icon_profile_basic.svg"
                            }') no-repeat" class="chat_profile ${
                              item.is_leader == 1
                                ? "chatLeader_profile"
                                : item.is_sub_leader == 1
                                ? "chatSubLeader_profile"
                                : ""
                            } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                              item.name
                            }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" controls><source src="${
                              item.file_url
                            }#t=0.1"type="video/${
                              item.file_extension
                            }"></video></div> <div class="chat_info_right"> <span id_arr="${
                              item.unread_message_ids
                            }" class="un_read">${
                              item.unread_message_count
                                ? item.unread_message_count
                                : ""
                            }</span> <p class="time">${chatDate(
                              item.created_at
                            )}</p> </div> </div> </div> </div> </div> </div>`;
                            appendTarget.insertAdjacentHTML(
                              "beforeend",
                              htmlString
                            );
                            // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                            // document.querySelectorAll(".new_video")[0].load();
                            document
                              .querySelectorAll(".new_video")[0]
                              .classList.remove("new_video");

                            //탭홀드 이벤트 할당
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .setAttribute("data-long-press-delay", 750);
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .addEventListener("long-press", function (e) {
                                if (
                                  !window.matchMedia("(max-width: 768px)")
                                    .matches
                                ) {
                                  e.preventDefault();
                                }
                                this.querySelectorAll(
                                  ".chat_long_menu"
                                )[0].style.display = "block";
                                document.querySelector("body").style.overflow =
                                  "hidden";
                              });
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .addEventListener("mousedown", function (e) {
                                if (e.button == 2 || e.which == 3) {
                                  if (
                                    !window.matchMedia("(max-width: 768px)")
                                      .matches
                                  ) {
                                    e.preventDefault();
                                  }
                                  this.querySelectorAll(
                                    ".chat_long_menu"
                                  )[0].style.display = "block";
                                  document.querySelector(
                                    "body"
                                  ).style.overflow = "hidden";
                                }
                              });
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_white"
                              )[0]
                              .addEventListener("click", function () {
                                this.parentNode.style.display = "none";
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });

                            //답장기능
                            document.querySelectorAll(
                              ".new_chat_row .chat_resend"
                            )[0] &&
                              document
                                .querySelectorAll(
                                  ".new_chat_row .chat_resend"
                                )[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".chat_data_area")[0]
                                    .insertAdjacentHTML(
                                      "beforeend",
                                      `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>동영상</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                    );
                                  document
                                    .querySelectorAll(
                                      "div.response_prev_ui img"
                                    )[0]
                                    .addEventListener("click", function () {
                                      document
                                        .querySelectorAll(
                                          ".response_prev_ui"
                                        )[0]
                                        .remove();
                                    });
                                  this.parentNode.parentNode.style.display =
                                    "none";
                                  document.querySelector(
                                    "body"
                                  ).style.overflow = "auto";
                                });

                            //공유기능
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_share"
                              )[0]
                              .addEventListener("click", function () {
                                magShare(
                                  this.parentNode.parentNode.parentNode.getAttribute(
                                    "idx"
                                  )
                                );
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });
                            //저장기능
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_save"
                              )[0]
                              .addEventListener("click", function () {
                                this.parentNode.parentNode.parentNode
                                  .querySelectorAll("a")[0]
                                  .click();
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });

                            //구분 클래스네임 삭제
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .classList.remove("new_chat_row");
                          } else {
                            //다운로드가능한 파일일 경우
                            let resendItem = document
                              .querySelectorAll(".toggle_button_3")[0]
                              .classList.contains("active")
                              ? myPermissionInfo == "팀장" ||
                                myPermissionInfo == "부팀장"
                                ? `<li class="chat_resend">답장</li>`
                                : ""
                              : `<li class="chat_resend">답장</li>`;
                            const htmlString = `<div idx="${
                              item.id
                            }" class="chat_row_2 chat_row_2_file new_chat_row_2 new_chat_row"> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div style="background: url('${
                              item.profile_url
                                ? item.profile_url
                                : "/assets/images/icon_profile_basic.svg"
                            }') no-repeat" class="chat_profile ${
                              item.is_leader == 1
                                ? "chatLeader_profile"
                                : item.is_sub_leader == 1
                                ? "chatSubLeader_profile"
                                : ""
                            } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                              item.name
                            }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                              item.file_url
                            )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                              item.expiration_date
                            }</h1> <span>용량 : ${
                              item.file_size
                            }kb</span> </div> </div> <div class="chat_info_right"> <span id_arr="${
                              item.unread_message_ids
                            }" class="un_read">${
                              item.unread_message_count
                                ? item.unread_message_count
                                : ""
                            }</span> <p class="time">${chatDate(
                              item.created_at
                            )}</p> </div> </div> </div> </div> </div> </div>`;
                            appendTarget.insertAdjacentHTML(
                              "beforeend",
                              htmlString
                            );
                            document
                              .querySelectorAll(".new_chat_row_2 .chat_info")[0]
                              .addEventListener("click", function () {
                                this.parentNode
                                  .querySelectorAll("a")[0]
                                  .click();
                              });
                            document
                              .querySelectorAll(".new_chat_row_2")[0]
                              .classList.remove("new_chat_row_2");

                            //탭홀드 이벤트 할당
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .setAttribute("data-long-press-delay", 750);
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .addEventListener("long-press", function (e) {
                                if (
                                  !window.matchMedia("(max-width: 768px)")
                                    .matches
                                ) {
                                  e.preventDefault();
                                }
                                this.querySelectorAll(
                                  ".chat_long_menu"
                                )[0].style.display = "block";
                                document.querySelector("body").style.overflow =
                                  "hidden";
                              });
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .addEventListener("mousedown", function (e) {
                                if (e.button == 2 || e.which == 3) {
                                  if (
                                    !window.matchMedia("(max-width: 768px)")
                                      .matches
                                  ) {
                                    e.preventDefault();
                                  }
                                  this.querySelectorAll(
                                    ".chat_long_menu"
                                  )[0].style.display = "block";
                                  document.querySelector(
                                    "body"
                                  ).style.overflow = "hidden";
                                }
                              });
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_white"
                              )[0]
                              .addEventListener("click", function () {
                                this.parentNode.style.display = "none";
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });

                            //답장기능
                            document.querySelectorAll(
                              ".new_chat_row .chat_resend"
                            )[0] &&
                              document
                                .querySelectorAll(
                                  ".new_chat_row .chat_resend"
                                )[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".chat_data_area")[0]
                                    .insertAdjacentHTML(
                                      "beforeend",
                                      `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>파일</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                    );
                                  document
                                    .querySelectorAll(
                                      "div.response_prev_ui img"
                                    )[0]
                                    .addEventListener("click", function () {
                                      document
                                        .querySelectorAll(
                                          ".response_prev_ui"
                                        )[0]
                                        .remove();
                                    });
                                  this.parentNode.parentNode.style.display =
                                    "none";
                                  document.querySelector(
                                    "body"
                                  ).style.overflow = "auto";
                                });

                            //공유기능
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_share"
                              )[0]
                              .addEventListener("click", function () {
                                magShare(
                                  this.parentNode.parentNode.parentNode.getAttribute(
                                    "idx"
                                  )
                                );
                              });
                            //저장기능
                            document
                              .querySelectorAll(
                                ".new_chat_row .chat_long_menu_save"
                              )[0]
                              .addEventListener("click", function () {
                                this.parentNode.parentNode.parentNode
                                  .querySelectorAll("a")[0]
                                  .click();
                                document.querySelector("body").style.overflow =
                                  "auto";
                              });

                            //구분 클래스네임 삭제
                            document
                              .querySelectorAll(".new_chat_row")[0]
                              .classList.remove("new_chat_row");
                          }
                        }
                      } else if (item.type == "message") {
                        //메세지일 경우
                        if (item.parent_id) {
                          //답장일 경우
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            `<div idx="${item.id}" class="prev_response_element"></div>`
                          );
                          fetch(
                            `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                            {
                              method: "GET",
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          )
                            .then(function (response) {
                              return response.json();
                            })
                            .then(function (data) {
                              let resMsg;
                              if (data.data.teamwork_message.type == "file") {
                                resMsg = fileNameCutter(
                                  data.data.teamwork_message.file_url
                                );
                              } else {
                                resMsg =
                                  data.data.teamwork_message.type === "schedule"
                                    ? "[일정]"
                                    : data.data.teamwork_message.message;
                              }

                              const htmlString = `<div idx="${
                                item.id
                              }" class="chat_row_2 new_chat_row"> <div style="background:url('${
                                item.profile_url
                                  ? item.profile_url
                                  : "/assets/images/icon_profile_basic.svg"
                              }') no-repeat" class="chat_profile ${
                                item.is_leader == 1
                                  ? "chatLeader_profile"
                                  : item.is_sub_leader == 1
                                  ? "chatSubLeader_profile"
                                  : ""
                              } new_chat_profile"></div><div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                                item.name
                              }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="response_msg_wrapper schedule_message message_${
                                item.id
                              }"><div class="response_msg_wrapper_top"><h1>${
                                data.data.teamwork_message.name
                              }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                                item.unread_message_ids
                              }" class="un_read">${
                                item.unread_message_count
                                  ? item.unread_message_count
                                  : ""
                              }</span> <p class="time">${chatDate(
                                item.created_at
                              )}</p> </div> </div> </div> </div> </div> </div>`;
                              document
                                .querySelectorAll(".prev_response_element")
                                .forEach((item2, idx2) => {
                                  if (item2.getAttribute("idx") == item.id) {
                                    item2.insertAdjacentHTML(
                                      "afterend",
                                      htmlString
                                    );

                                    document
                                      .querySelectorAll(
                                        `.schedule_message.message_${item.id}`
                                      )[0]
                                      .addEventListener("click", function () {
                                        document
                                          .querySelectorAll(
                                            `.message_${item.parent_id}`
                                          )[0]
                                          .scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                          });
                                      });

                                    if (
                                      (varUAApp.match("olapse/Android") !=
                                        null ||
                                        varUAApp.match("olapse/iOS") != null) &&
                                      document.querySelectorAll(
                                        ".autolinkMsg"
                                      )[0]
                                    ) {
                                      let msgLink = document
                                        .querySelectorAll(".autolinkMsg")[0]
                                        .getAttribute("href");
                                      if (msgLink.indexOf("idx=") !== -1) {
                                        document
                                          .querySelectorAll(".autolinkMsg")[0]
                                          .setAttribute(
                                            "href",
                                            "/join_team.html?idx=" +
                                              msgLink
                                                .split("idx=")[1]
                                                .split("&")[0]
                                          );
                                      }
                                      document
                                        .querySelectorAll(".autolinkMsg")[0]
                                        .classList.remove("autolinkMsg");
                                    }
                                    item2.remove();
                                    if (
                                      document.querySelectorAll(
                                        ".new_show_more"
                                      )[0]
                                    ) {
                                      document
                                        .querySelectorAll(".new_show_more")[0]
                                        .addEventListener("click", function () {
                                          this.parentNode.textContent =
                                            this.getAttribute("origin");
                                          this.remove();
                                        });
                                      document
                                        .querySelectorAll(".new_show_more")[0]
                                        .classList.remove("new_show_more");
                                    }
                                  }
                                });
                            });
                        } else {
                          //dom 삽입
                          let resendItem = document
                            .querySelectorAll(".toggle_button_3")[0]
                            .classList.contains("active")
                            ? myPermissionInfo == "팀장" ||
                              myPermissionInfo == "부팀장"
                              ? `<li class="chat_resend">답장</li>`
                              : ""
                            : `<li class="chat_resend">답장</li>`;
                          const htmlString = `<div idx="${
                            item.id
                          }" class="chat_row_2 new_chat_row"><div class="chat_long_menu"><input class="copy_target" value='${
                            item.message
                          }' type="text" /><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_copy">복사</li>${resendItem}</ul></div> <div style="background:url('${
                            item.profile_url
                              ? item.profile_url
                              : "/assets/images/icon_profile_basic.svg"
                          }') no-repeat" class="chat_profile ${
                            item.is_leader == 1
                              ? "chatLeader_profile"
                              : item.is_sub_leader == 1
                              ? "chatSubLeader_profile"
                              : ""
                          } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                            item.name
                          }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p class="msg_item">${msgString.autoLink()}</p> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div> </div> </div> </div>`;
                          appendTarget.insertAdjacentHTML(
                            "beforeend",
                            htmlString
                          );
                          if (
                            (varUAApp.match("olapse/Android") != null ||
                              varUAApp.match("olapse/iOS") != null) &&
                            document.querySelectorAll(".autolinkMsg")[0]
                          ) {
                            let msgLink = document
                              .querySelectorAll(".autolinkMsg")[0]
                              .getAttribute("href");
                            if (msgLink.indexOf("idx=") !== -1) {
                              document
                                .querySelectorAll(".autolinkMsg")[0]
                                .setAttribute(
                                  "href",
                                  "/join_team.html?idx=" +
                                    msgLink.split("idx=")[1].split("&")[0]
                                );
                            }
                            document
                              .querySelectorAll(".autolinkMsg")[0]
                              .classList.remove("autolinkMsg");
                          }

                          //250자 이상 처리
                          if (document.querySelectorAll(".new_show_more")[0]) {
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .addEventListener("click", function () {
                                this.parentNode.textContent =
                                  this.getAttribute("origin");
                                this.remove();
                              });
                            document
                              .querySelectorAll(".new_show_more")[0]
                              .classList.remove("new_show_more");
                          }

                          //탭홀드 이벤트 할당
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .setAttribute("data-long-press-delay", 750);
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .addEventListener("long-press", function (e) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            });
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .addEventListener("mousedown", function (e) {
                              if (e.button == 2 || e.which == 3) {
                                if (
                                  !window.matchMedia("(max-width: 768px)")
                                    .matches
                                ) {
                                  e.preventDefault();
                                }
                                this.querySelectorAll(
                                  ".chat_long_menu"
                                )[0].style.display = "block";
                                document.querySelector("body").style.overflow =
                                  "hidden";
                              }
                            });
                          document
                            .querySelectorAll(
                              ".new_chat_row .chat_long_menu_white"
                            )[0]
                            .addEventListener("click", function () {
                              this.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "visible";
                            });

                          //메세지 복사 기능
                          document
                            .querySelectorAll(
                              ".new_chat_row .chat_long_menu_copy"
                            )[0]
                            .addEventListener("click", function () {
                              copyToClipboard(
                                this.parentNode.parentNode.querySelectorAll(
                                  ".copy_target"
                                )[0]
                              );
                              this.parentNode.parentNode.style.display = "none";
                              createPopup({
                                type: "confirm",
                                title: "복사완료",
                                content:
                                  "선택하신 메세지를 클립보드에 복사하였습니다.",
                              });
                              document.querySelector("body").style.overflow =
                                "visible";
                            });

                          //답장기능
                          document.querySelectorAll(
                            ".new_chat_row .chat_resend"
                          )[0] &&
                            document
                              .querySelectorAll(".new_chat_row .chat_resend")[0]
                              .addEventListener("click", function () {
                                document
                                  .querySelectorAll(".chat_data_area")[0]
                                  .insertAdjacentHTML(
                                    "beforeend",
                                    `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>${item.message}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                  );
                                document
                                  .querySelectorAll(
                                    "div.response_prev_ui img"
                                  )[0]
                                  .addEventListener("click", function () {
                                    document
                                      .querySelectorAll(".response_prev_ui")[0]
                                      .remove();
                                  });
                                this.parentNode.parentNode.style.display =
                                  "none";
                                document.querySelector("body").style.overflow =
                                  "visible";
                              });

                          //구분 클래스네임 삭제
                          document
                            .querySelectorAll(".new_chat_row")[0]
                            .classList.remove("new_chat_row");
                        }
                      } else if (
                        item.type == "schedule" ||
                        item.type == "return"
                      ) {
                        //dom 삽입
                        let resendItem = document
                          .querySelectorAll(".toggle_button_3")[0]
                          .classList.contains("active")
                          ? myPermissionInfo == "팀장" ||
                            myPermissionInfo == "부팀장"
                            ? `<li class="chat_resend">답장</li>`
                            : ""
                          : `<li class="chat_resend">답장</li>`;
                        const menuString = `<div class="chat_long_menu"><input class="copy_target" value='${item.message}' type="text" /><div class="chat_long_menu_white"></div><ul>${resendItem}</ul></div>`;

                        if (item.type == "return") {
                          document.querySelectorAll(
                            ".chat_data_inner>div[idx='" + item.etc + "']"
                          )[0] &&
                            document
                              .querySelectorAll(
                                ".chat_data_inner>div[idx='" + item.etc + "']"
                              )[0]
                              .remove();
                        }
                        let statusClassName, title;
                        if (item.schedule.status == 0) {
                          //요청
                          statusClassName = "request";
                          title = "일정이 요청되었습니다.";
                        } else if (item.schedule.status == 1) {
                          //수락
                          statusClassName = "admit";
                          title = `일정이 수락되었습니다.`;
                        } else if (item.schedule.status == 2) {
                          //완료
                          statusClassName = "complete";
                          title = `일정이 완료되었습니다.`;
                        } else if (item.schedule.status == 5) {
                          //보류
                          statusClassName = "stay";
                          title = `일정이 보류되었습니다.`;
                        } else if (item.schedule.status == 10) {
                          //취소
                          statusClassName = "cancel";
                          title = `일정이 취소되었습니다.`;
                        }

                        const htmlString = `<div data-schedule_id="${
                          item.schedule.id
                        }" idx="${
                          item.id
                        }" class="schedule_wrapper new_schedule_wrapper new_chat_row message_${
                          item.id
                        }">${menuString}<div class="schedule_bottom_box"><p class="time">${chatDate(
                          item.created_at
                        )}</p><span class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span></div><div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                          item.schedule.contents
                        }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                          item.schedule.place
                        }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                          item.schedule.begin_date
                        )} ~<br>${
                          item.schedule.end_date
                            ? dateSecondCutter(item.schedule.end_date)
                            : ""
                        }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                          item.schedule.price
                        )}원</span> </div>`;
                        appendTarget.insertAdjacentHTML(
                          "beforeend",
                          htmlString
                        );
                        if (
                          item.schedule.accept_user_id == myId ||
                          myPermissionInfo == "팀장" ||
                          myPermissionInfo == "부팀장"
                        ) {
                        } else {
                          if (item.schedule.status !== 0) {
                            document.querySelectorAll(
                              ".new_schedule_wrapper"
                            )[0].style.display = "none";
                          }
                        }
                        document
                          .querySelectorAll(".new_schedule_wrapper")[0]
                          .classList.remove("new_schedule_wrapper");
                        document
                          .querySelectorAll(".new_schedule_detail_button")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".right_popup")
                              .forEach((item, idx) => {
                                _animateRightModule(false, item);
                              });

                            setTimeout(() => {
                              _animateRightModule(
                                true,
                                document.querySelectorAll(".d_4")[0]
                              );
                              updateScheduleData(
                                item.schedule.id,
                                myPermissionInfo
                              );
                            }, 500);
                            document
                              .querySelectorAll(".common_more_wrapper li")
                              .forEach((item2, idx2) => {
                                item2.setAttribute(
                                  "data-schedule_id",
                                  item.schedule.id
                                );
                              });
                            document
                              .querySelectorAll(".edit_schedule")[0]
                              .setAttribute(
                                "data-schedule_id",
                                item.schedule.id
                              );
                          });
                        document
                          .querySelectorAll(".new_schedule_detail_button")[0]
                          .classList.remove("new_schedule_detail_button");

                        //답장기능
                        document.querySelectorAll(
                          ".new_chat_row .chat_resend"
                        )[0] &&
                          document
                            .querySelectorAll(".new_chat_row .chat_resend")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".chat_data_area")[0]
                                .insertAdjacentHTML(
                                  "beforeend",
                                  `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>[일정] ${item.schedule.contents}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                );
                              document
                                .querySelectorAll("div.response_prev_ui img")[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".response_prev_ui")[0]
                                    .remove();
                                });
                              this.parentNode.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "visible";
                            });

                        //탭홀드 이벤트 할당
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .setAttribute("data-long-press-delay", 750);
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("long-press", function (e) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                            document.querySelector("body").style.overflow =
                              "hidden";
                          });
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("mousedown", function (e) {
                            if (e.button == 2 || e.which == 3) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            }
                          });
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_white"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "visible";
                          });
                      } else if (
                        item.type == "notice" ||
                        item.type == undefined
                      ) {
                        //공지사항일 경우
                        const htmlString = `<div idx="${
                          item.id
                        }" class="chat_row_2 chat_row_notice"> <div style="background:url('${
                          item.profile_url
                            ? item.profile_url
                            : "/assets/images/icon_profile_basic.svg"
                        }') no-repeat" class="chat_profile ${
                          item.is_leader == 1
                            ? "chatLeader_profile"
                            : item.is_sub_leader == 1
                            ? "chatSubLeader_profile"
                            : ""
                        } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                          item.name
                        }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p>${
                          item.message
                        }</p> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div> </div> </div> </div> </div> </div>`;
                        appendTarget.insertAdjacentHTML(
                          "beforeend",
                          htmlString
                        );
                        noticeFunctionUpdate();
                      } else if (
                        item.type_ == "notice_modify" ||
                        item.type_ == "notice_delete"
                      ) {
                        noticeFunctionUpdate();
                      }

                      if (document.querySelectorAll(".new_chat_profile")[0]) {
                        memberDetailFunction(
                          document.querySelectorAll(".new_chat_profile")[0],
                          item.teamwork_user_id,
                          item.user_id,
                          item
                        );
                        document
                          .querySelectorAll(".new_chat_profile")[0]
                          ?.classList.remove("new_chat_profile");
                      }
                    }

                    if (data.data.teamwork_messages.data.length - 1 == idx) {
                      lastItem =
                        document.querySelectorAll(".chat_data_inner>*")[1];
                      scrollBottom();
                    }
                  });
              } else {
                scrollBottom();
              }
              setTimeout(() => {
                document.querySelectorAll(".chat_loading")[0] &&
                  document.querySelectorAll(".chat_loading")[0].remove();
                anime({
                  targets: document.querySelectorAll(".chat_data_area")[0],
                  easing: "easeOutExpo",
                  opacity: [0, 1],
                  duration: 100,
                });

                if (getParameterByName("isOpenMenu") == "1") {
                  document.querySelectorAll(".chat_menu_button")[0].click();
                }
              }, 10);
            }
          });
      }

      function excute(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.teamwork_messages.data.length !== 0) {
              // document.querySelectorAll(".teamwork_start_date")[0].textContent = data.data.teamwork_messages.data[data.data.teamwork_messages.data.length - 1].created_at.split(" ")[0];
              const reversedArr = data.data.teamwork_messages.data;
              reversedArr.forEach((item, idx) => {
                let msgString;
                //메세지가 250자 이상일 경우 처리
                if (item.message && String(item.message).length > 250) {
                  msgString = `${item.message.substring(
                    0,
                    250
                  )}... <span origin="${
                    item.message
                  }" class="new_show_more">더보기</span>`;
                } else {
                  msgString = `${item.message}`;
                }
                if (item.user_id == myId) {
                  //내가 보낸 메세지
                  if (item.type == "file") {
                    //파일일 경우 (row 추가 o)
                    if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                      //이미지일 경우 (row 추가 o)
                      const htmlString = `<div idx="${
                        item.id
                      }" class="new_chat_row_img chat_row user_new_chat_row"> <div class="chat_row_wrapper_my"> <img class="chat_img_right" src="${
                        item.file_url
                      }" /> <div class="chat_info_right"> <span id_arr="${
                        item.unread_message_ids
                      }" class="un_read">${
                        item.unread_message_count
                          ? item.unread_message_count
                          : ""
                      }</span> <p class="time">${chatDate(
                        item.created_at
                      )}</p> </div> </div> </div>`;
                      appendTarget
                        .querySelectorAll("div")[0]
                        .insertAdjacentHTML("beforebegin", htmlString);
                      imgViewerFunction(
                        document.querySelectorAll(
                          ".new_chat_row_img .chat_img_right"
                        )[0],
                        item.file_url,
                        item.name,
                        item
                      );
                      document
                        .querySelectorAll(".new_chat_row_img")[0]
                        .classList.remove("new_chat_row_img");
                    } else {
                      if (videoArr.indexOf(item.file_extension) !== -1) {
                        //동영상일경우
                        var videoName =
                          item.file_url.split("/")[
                            item.file_url.split("/").length - 1
                          ];
                        const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div><div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" controls><source src="${
                          item.file_url
                        }#t=0.1"type="video/${
                          item.file_extension
                        }"></video></div></div>`;
                        appendTarget
                          .querySelectorAll("div")[0]
                          .insertAdjacentHTML("beforebegin", htmlString);
                        // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                        // document.querySelectorAll(".new_video")[0].load();
                        document
                          .querySelectorAll(".new_video")[0]
                          .classList.remove("new_video");
                      } else {
                        //다운로드가능한 파일일 경우
                        const htmlString = `<div class="user_new_chat_row new_chat_file_row chat_file_row chat_file_row_right"> <a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                          item.file_url
                        )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                          item.expiration_date
                        }</h1> <span>용량 : ${
                          item.file_size
                        }kb</span> </div> </div> </div>`;
                        appendTarget
                          .querySelectorAll("div")[0]
                          .insertAdjacentHTML("beforebegin", htmlString);
                        document
                          .querySelectorAll(
                            ".new_chat_file_row .chat_file_row_inner"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.querySelectorAll("a")[0].click();
                          });
                        document
                          .querySelectorAll(".new_chat_file_row")[0]
                          .classList.remove("new_chat_file_row");
                      }
                    }
                  } else if (item.type == "message") {
                    //메세지일 경우
                    if (item.parent_id) {
                      //답장일 경우
                      fetch(
                        `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        }
                      )
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          let resMsg;
                          if (data.data.teamwork_message.type == "file") {
                            resMsg = fileNameCutter(
                              data.data.teamwork_message.file_url
                            );
                          } else {
                            resMsg =
                              data.data.teamwork_message.type === "schedule"
                                ? "[일정]"
                                : data.data.teamwork_message.message;
                          }
                          const htmlString = `<div idx="${
                            item.id
                          }" class="chat_row"> <div class="chat_row_wrapper_my"> <div class="response_msg_wrapper schedule_message message_${
                            item.id
                          }"><div class="response_msg_wrapper_top"><h1>${
                            data.data.teamwork_message.name
                          }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div>`;
                          appendTarget
                            .querySelectorAll("div")[0]
                            .insertAdjacentHTML("beforebegin", htmlString);

                          document
                            .querySelectorAll(
                              `.schedule_message.message_${item.id}`
                            )[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(
                                  `.message_${item.parent_id}`
                                )[0]
                                .scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                });
                            });

                          if (
                            (varUAApp.match("olapse/Android") != null ||
                              varUAApp.match("olapse/iOS") != null) &&
                            document.querySelectorAll(".autolinkMsg")[0]
                          ) {
                            let msgLink = document
                              .querySelectorAll(".autolinkMsg")[0]
                              .getAttribute("href");
                            if (msgLink.indexOf("idx=") !== -1) {
                              document
                                .querySelectorAll(".autolinkMsg")[0]
                                .setAttribute(
                                  "href",
                                  "/join_team.html?idx=" +
                                    msgLink.split("idx=")[1].split("&")[0]
                                );
                            }
                            document
                              .querySelectorAll(".autolinkMsg")[0]
                              .classList.remove("autolinkMsg");
                          }
                        });

                      if (document.querySelectorAll(".new_show_more")[0]) {
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .addEventListener("click", function () {
                            this.parentNode.textContent =
                              this.getAttribute("origin");
                            this.remove();
                          });
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .classList.remove("new_show_more");
                      }
                    } else {
                      //일반 메세지일 경우
                      const htmlString = `<div idx="${
                        item.id
                      }" class="chat_row"> <div class="chat_row_wrapper_my"> <p class="msg_item">${msgString.autoLink()}</p> <div class="chat_info_right"> <span id_arr="${
                        item.unread_message_ids
                      }" class="un_read">${
                        item.unread_message_count
                          ? item.unread_message_count
                          : ""
                      }</span> <p class="time">${chatDate(
                        item.created_at
                      )}</p> </div> </div> </div>`;
                      appendTarget
                        .querySelectorAll("div")[0]
                        .insertAdjacentHTML("beforebegin", htmlString);
                      if (
                        (varUAApp.match("olapse/Android") != null ||
                          varUAApp.match("olapse/iOS") != null) &&
                        document.querySelectorAll(".autolinkMsg")[0]
                      ) {
                        let msgLink = document
                          .querySelectorAll(".autolinkMsg")[0]
                          .getAttribute("href");
                        if (msgLink.indexOf("idx=") !== -1) {
                          document
                            .querySelectorAll(".autolinkMsg")[0]
                            .setAttribute(
                              "href",
                              "/join_team.html?idx=" +
                                msgLink.split("idx=")[1].split("&")[0]
                            );
                        }
                        document
                          .querySelectorAll(".autolinkMsg")[0]
                          .classList.remove("autolinkMsg");
                      }
                      if (document.querySelectorAll(".new_show_more")[0]) {
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .addEventListener("click", function () {
                            this.parentNode.textContent =
                              this.getAttribute("origin");
                            this.remove();
                          });
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .classList.remove("new_show_more");
                      }
                    }
                  } else if (item.type == "schedule" || item.type == "return") {
                    if (item.type == "return") {
                      document.querySelectorAll(
                        ".chat_data_inner>div[idx='" + item.etc + "']"
                      )[0] &&
                        document
                          .querySelectorAll(
                            ".chat_data_inner>div[idx='" + item.etc + "']"
                          )[0]
                          .remove();
                    }
                    let statusClassName, title;
                    if (item.schedule.status == 0) {
                      //요청
                      statusClassName = "request";
                      title = "일정이 요청되었습니다.";
                    } else if (item.schedule.status == 1) {
                      //수락
                      statusClassName = "admit";
                      title = `일정이 수락되었습니다.`;
                    } else if (item.schedule.status == 2) {
                      //완료
                      statusClassName = "complete";
                      title = `일정이 완료되었습니다.`;
                    } else if (item.schedule.status == 5) {
                      //보류
                      statusClassName = "stay";
                      title = `일정이 보류되었습니다.`;
                    } else if (item.schedule.status == 10) {
                      //취소
                      statusClassName = "cancel";
                      title = `일정이 취소되었습니다.`;
                    }
                    const htmlString = `<div data-schedule_id="${
                      item.schedule.id
                    }" idx="${item.id}" class="schedule_wrapper message_${
                      item.id
                    }"><div class="schedule_bottom_box"><p class="time">${chatDate(
                      item.created_at
                    )}</p><span id_arr="${
                      item.unread_message_ids
                    }" class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span></div> <div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                      item.schedule.contents
                    }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                      item.schedule.place
                    }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                      item.schedule.begin_date
                    )} ~<br>${
                      item.schedule.end_date
                        ? dateSecondCutter(item.schedule.end_date)
                        : ""
                    }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                      item.schedule.price
                    )}원</span> </div>`;
                    appendTarget
                      .querySelectorAll("div")[0]
                      .insertAdjacentHTML("beforebegin", htmlString);
                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".right_popup")
                          .forEach((item, idx) => {
                            _animateRightModule(false, item);
                          });
                        setTimeout(() => {
                          _animateRightModule(
                            true,
                            document.querySelectorAll(".d_4")[0]
                          );
                          updateScheduleData(
                            item.schedule.id,
                            myPermissionInfo
                          );
                        }, 500);
                        document
                          .querySelectorAll(".common_more_wrapper li")
                          .forEach((item2, idx2) => {
                            item2.setAttribute(
                              "data-schedule_id",
                              item.schedule.id
                            );
                          });
                        document
                          .querySelectorAll(".edit_schedule")[0]
                          .setAttribute("data-schedule_id", item.schedule.id);
                      });
                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .classList.remove("new_schedule_detail_button");
                  } else if (item.type == "notice" || item.type == undefined) {
                    //공지사항일 경우
                    const htmlString = `<div idx="${
                      item.id
                    }" class="chat_row chat_row_notice"> <div class="chat_row_wrapper_my"> <p>${
                      item.message
                    }</p> <div class="chat_info_right"> <span id_arr="${
                      item.unread_message_ids
                    }" class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span> <p class="time">${chatDate(
                      item.created_at
                    )}</p> </div> </div> </div>`;
                    appendTarget
                      .querySelectorAll("div")[0]
                      .insertAdjacentHTML("beforebegin", htmlString);
                    noticeFunctionUpdate();
                  } else if (
                    item.type_ == "notice_modify" ||
                    item.type_ == "notice_delete"
                  ) {
                    noticeFunctionUpdate();
                  }
                } else {
                  //타인이 보낸 메세지
                  if (item.type == "file") {
                    //파일일 경우
                    if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                      //이미지일 경우

                      //dom 삽입
                      let resendItem = document
                        .querySelectorAll(".toggle_button_3")[0]
                        .classList.contains("active")
                        ? myPermissionInfo == "팀장" ||
                          myPermissionInfo == "부팀장"
                          ? `<li class="chat_resend">답장</li>`
                          : ""
                        : `<li class="chat_resend">답장</li>`;
                      const htmlString = `<div idx="${
                        item.id
                      }" class="chat_row_2 new_chat_row chat_row_img_2 new_chat_row_img"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a><div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><div style="background: url('${
                        item.profile_url
                          ? item.profile_url
                          : "/assets/images/icon_profile_basic.svg"
                      }') no-repeat" class="chat_profile ${
                        item.is_leader == 1
                          ? "chatLeader_profile"
                          : item.is_sub_leader == 1
                          ? "chatSubLeader_profile"
                          : ""
                      } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                        item.name
                      }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <img class="chat_row_img_2_inner" src="${
                        item.file_url
                      }" alt=""> <div class="chat_info_right"> <span id_arr="${
                        item.unread_message_ids
                      }" class="un_read">${
                        item.unread_message_count
                          ? item.unread_message_count
                          : ""
                      }</span> <p class="time">${chatDate(
                        item.created_at
                      )}</p> </div> </div> </div> </div> </div> </div>`;
                      appendTarget
                        .querySelectorAll("div")[0]
                        .insertAdjacentHTML("beforebegin", htmlString);

                      //이미지 뷰어 처리
                      imgViewerFunction(
                        document.querySelectorAll(
                          ".new_chat_row_img .chat_row_img_2_inner"
                        )[0],
                        item.file_url,
                        item.name,
                        item
                      );
                      document
                        .querySelectorAll(".new_chat_row_img")[0]
                        .classList.remove("new_chat_row_img");

                      //탭홀드 이벤트 할당
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .setAttribute("data-long-press-delay", 750);
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .addEventListener("long-press", function (e) {
                          if (
                            !window.matchMedia("(max-width: 768px)").matches
                          ) {
                            e.preventDefault();
                          }
                          this.querySelectorAll(
                            ".chat_long_menu"
                          )[0].style.display = "block";
                          document.querySelector("body").style.overflow =
                            "hidden";
                        });
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .addEventListener("mousedown", function (e) {
                          if (e.button == 2 || e.which == 3) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            document.querySelector("body").style.overflow =
                              "hidden";
                          }
                        });
                      document
                        .querySelectorAll(
                          ".new_chat_row .chat_long_menu_white"
                        )[0]
                        .addEventListener("click", function () {
                          this.parentNode.style.display = "none";
                          document.querySelector("body").style.overflow =
                            "auto";
                        });

                      //답장기능
                      document.querySelectorAll(
                        ".new_chat_row .chat_resend"
                      )[0] &&
                        document
                          .querySelectorAll(".new_chat_row .chat_resend")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".chat_data_area")[0]
                              .insertAdjacentHTML(
                                "beforeend",
                                `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>이미지</span><img src="/assets/images/icon_response_end.svg" /></div>`
                              );
                            document
                              .querySelectorAll("div.response_prev_ui img")[0]
                              .addEventListener("click", function () {
                                document
                                  .querySelectorAll(".response_prev_ui")[0]
                                  .remove();
                              });
                            this.parentNode.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "auto";
                          });

                      //공유기능
                      document
                        .querySelectorAll(
                          ".new_chat_row .chat_long_menu_share"
                        )[0]
                        .addEventListener("click", function () {
                          magShare(
                            this.parentNode.parentNode.parentNode.getAttribute(
                              "idx"
                            )
                          );
                        });
                      //저장기능
                      document
                        .querySelectorAll(
                          ".new_chat_row .chat_long_menu_save"
                        )[0]
                        .addEventListener("click", function () {
                          this.parentNode.parentNode.parentNode
                            .querySelectorAll("a")[0]
                            .click();
                          document.querySelector("body").style.overflow =
                            "auto";
                        });

                      //구분 클래스네임 삭제
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .classList.remove("new_chat_row");
                    } else {
                      if (videoArr.indexOf(item.file_extension) !== -1) {
                        //동영상일경우
                        let resendItem = document
                          .querySelectorAll(".toggle_button_3")[0]
                          .classList.contains("active")
                          ? myPermissionInfo == "팀장" ||
                            myPermissionInfo == "부팀장"
                            ? `<li class="chat_resend">답장</li>`
                            : ""
                          : `<li class="chat_resend">답장</li>`;
                        var videoName =
                          item.file_url.split("/")[
                            item.file_url.split("/").length - 1
                          ];
                        const htmlString = `<div idx="${
                          item.id
                        }" class="chat_row_2 new_chat_row"> <a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div> <div style="background:url('${
                          item.profile_url
                            ? item.profile_url
                            : "/assets/images/icon_profile_basic.svg"
                        }') no-repeat" class="chat_profile ${
                          item.is_leader == 1
                            ? "chatLeader_profile"
                            : item.is_sub_leader == 1
                            ? "chatSubLeader_profile"
                            : ""
                        } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                          item.name
                        }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="videoBox"><div class="videoHead"><p class="videoName">${videoName}</p><a href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=video&file_extension=${item.file_extension}`} download><img src="/assets/images/icon_download.svg"></img></a></div><video preload="metadata" class="new_video" controls><source src="${
                          item.file_url
                        }#t=0.1"type="video/${
                          item.file_extension
                        }"></video></div> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div> </div> </div> </div> </div> </div>`;
                        appendTarget
                          .querySelectorAll("div")[0]
                          .insertAdjacentHTML("beforebegin", htmlString);
                        // document.querySelectorAll(".new_video")[0].setAttribute("poster",videoImgSet(document.querySelectorAll(".new_video")[0]));
                        // document.querySelectorAll(".new_video")[0].load();
                        document
                          .querySelectorAll(".new_video")[0]
                          .classList.remove("new_video");
                        //탭홀드 이벤트 할당
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .setAttribute("data-long-press-delay", 750);
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("long-press", function (e) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            document.querySelector("body").style.overflow =
                              "hidden";
                          });
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("mousedown", function (e) {
                            if (e.button == 2 || e.which == 3) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            }
                          });
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_white"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "auto";
                          });

                        //답장기능
                        document.querySelectorAll(
                          ".new_chat_row .chat_resend"
                        )[0] &&
                          document
                            .querySelectorAll(".new_chat_row .chat_resend")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".chat_data_area")[0]
                                .insertAdjacentHTML(
                                  "beforeend",
                                  `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>동영상</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                );
                              document
                                .querySelectorAll("div.response_prev_ui img")[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".response_prev_ui")[0]
                                    .remove();
                                });
                              this.parentNode.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "auto";
                            });

                        //공유기능
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_share"
                          )[0]
                          .addEventListener("click", function () {
                            magShare(
                              this.parentNode.parentNode.parentNode.getAttribute(
                                "idx"
                              )
                            );
                            document.querySelector("body").style.overflow =
                              "auto";
                          });
                        //저장기능
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_save"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.parentNode.parentNode
                              .querySelectorAll("a")[0]
                              .click();
                            document.querySelector("body").style.overflow =
                              "auto";
                          });

                        //구분 클래스네임 삭제
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .classList.remove("new_chat_row");
                      } else {
                        //다운로드가능한 파일일 경우
                        let resendItem = document
                          .querySelectorAll(".toggle_button_3")[0]
                          .classList.contains("active")
                          ? myPermissionInfo == "팀장" ||
                            myPermissionInfo == "부팀장"
                            ? `<li class="chat_resend">답장</li>`
                            : ""
                          : `<li class="chat_resend">답장</li>`;
                        const htmlString = `<div idx="${
                          item.id
                        }" class="chat_row_2 chat_row_2_file new_chat_row_2 new_chat_row"><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div class="chat_long_menu"><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_save">저장</li><li class="chat_long_menu_share">공유</li>${resendItem}</ul></div><a class="chat_file_downloader" href=${`https://api.olapse.com/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`} download></a> <div style="background: url('${
                          item.profile_url
                            ? item.profile_url
                            : "/assets/images/icon_profile_basic.svg"
                        }') no-repeat" class="chat_profile ${
                          item.is_leader == 1
                            ? "chatLeader_profile"
                            : item.is_sub_leader == 1
                            ? "chatSubLeader_profile"
                            : ""
                        } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                          item.name
                        }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="chat_file_row_inner"> <div class="chat_file_top"> <p class="file_icon"></p> <span>${fileNameCutter(
                          item.file_url
                        )}</span> </div> <div class="chat_file_bottom"> <h1>유효기간 : ${
                          item.expiration_date
                        }</h1> <span>용량 : ${
                          item.file_size
                        }kb</span> </div> </div> <div class="chat_info_right"> <span id_arr="${
                          item.unread_message_ids
                        }" class="un_read">${
                          item.unread_message_count
                            ? item.unread_message_count
                            : ""
                        }</span> <p class="time">${chatDate(
                          item.created_at
                        )}</p> </div> </div> </div> </div> </div> </div>`;
                        appendTarget
                          .querySelectorAll("div")[0]
                          .insertAdjacentHTML("beforebegin", htmlString);
                        document
                          .querySelectorAll(".new_chat_row_2 .chat_info")[0]
                          .addEventListener("click", function () {
                            this.parentNode.querySelectorAll("a")[0].click();
                          });
                        document
                          .querySelectorAll(".new_chat_row_2")[0]
                          .classList.remove("new_chat_row_2");

                        //탭홀드 이벤트 할당
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .setAttribute("data-long-press-delay", 750);
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("long-press", function (e) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            document.querySelector("body").style.overflow =
                              "hidden";
                          });
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .addEventListener("mousedown", function (e) {
                            if (e.button == 2 || e.which == 3) {
                              if (
                                !window.matchMedia("(max-width: 768px)").matches
                              ) {
                                e.preventDefault();
                              }
                              this.querySelectorAll(
                                ".chat_long_menu"
                              )[0].style.display = "block";
                              document.querySelector("body").style.overflow =
                                "hidden";
                            }
                          });
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_white"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "auto";
                          });

                        //답장기능
                        document.querySelectorAll(
                          ".new_chat_row .chat_resend"
                        )[0] &&
                          document
                            .querySelectorAll(".new_chat_row .chat_resend")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".chat_data_area")[0]
                                .insertAdjacentHTML(
                                  "beforeend",
                                  `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>파일</span><img src="/assets/images/icon_response_end.svg" /></div>`
                                );
                              document
                                .querySelectorAll("div.response_prev_ui img")[0]
                                .addEventListener("click", function () {
                                  document
                                    .querySelectorAll(".response_prev_ui")[0]
                                    .remove();
                                });
                              this.parentNode.parentNode.style.display = "none";
                              document.querySelector("body").style.overflow =
                                "auto";
                            });

                        //공유기능
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_share"
                          )[0]
                          .addEventListener("click", function () {
                            magShare(
                              this.parentNode.parentNode.parentNode.getAttribute(
                                "idx"
                              )
                            );
                            document.querySelector("body").style.overflow =
                              "auto";
                          });
                        //저장기능
                        document
                          .querySelectorAll(
                            ".new_chat_row .chat_long_menu_save"
                          )[0]
                          .addEventListener("click", function () {
                            this.parentNode.parentNode.parentNode
                              .querySelectorAll("a")[0]
                              .click();
                            document.querySelector("body").style.overflow =
                              "auto";
                          });

                        //구분 클래스네임 삭제
                        document
                          .querySelectorAll(".new_chat_row")[0]
                          .classList.remove("new_chat_row");
                      }
                    }
                  } else if (item.type == "message") {
                    //메세지일 경우

                    if (item.parent_id) {
                      //답장일 경우
                      appendTarget.insertAdjacentHTML(
                        "beforeend",
                        `<div idx="${item.id}" class="prev_response_element"></div>`
                      );
                      fetch(
                        `https://api.olapse.com/api/teamwork/message/detail?id=${item.parent_id}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        }
                      )
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          let resMsg;
                          if (data.data.teamwork_message.type == "file") {
                            resMsg = fileNameCutter(
                              data.data.teamwork_message.file_url
                            );
                          } else {
                            resMsg =
                              data.data.teamwork_message.type === "schedule"
                                ? "[일정]"
                                : data.data.teamwork_message.message;
                          }

                          const htmlString = `<div idx="${
                            item.id
                          }" class="chat_row_2 new_chat_row schedule_message"> <div style="background:url('${
                            item.profile_url
                              ? item.profile_url
                              : "/assets/images/icon_profile_basic.svg"
                          }') no-repeat" class="chat_profile ${
                            item.is_leader == 1
                              ? "chatLeader_profile"
                              : item.is_sub_leader == 1
                              ? "chatSubLeader_profile"
                              : ""
                          } new_chat_profile"></div><div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                            item.name
                          }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <div class="response_msg_wrapper"><div class="response_msg_wrapper_top"><h1>${
                            data.data.teamwork_message.name
                          }</h1><span>${resMsg}</span></div><p class="msg_item">${msgString.autoLink()}</p></div> <div class="chat_info_right"> <span id_arr="${
                            item.unread_message_ids
                          }" class="un_read">${
                            item.unread_message_count
                              ? item.unread_message_count
                              : ""
                          }</span> <p class="time">${chatDate(
                            item.created_at
                          )}</p> </div> </div> </div> </div> </div> </div>`;
                          document
                            .querySelectorAll(".prev_response_element")
                            .forEach((item2, idx2) => {
                              if (item2.getAttribute("idx") == item.id) {
                                item2.insertAdjacentHTML(
                                  "afterend",
                                  htmlString
                                );
                                if (
                                  (varUAApp.match("olapse/Android") != null ||
                                    varUAApp.match("olapse/iOS") != null) &&
                                  document.querySelectorAll(".autolinkMsg")[0]
                                ) {
                                  let msgLink = document
                                    .querySelectorAll(".autolinkMsg")[0]
                                    .getAttribute("href");
                                  if (msgLink.indexOf("idx=") !== -1) {
                                    document
                                      .querySelectorAll(".autolinkMsg")[0]
                                      .setAttribute(
                                        "href",
                                        "/join_team.html?idx=" +
                                          msgLink.split("idx=")[1].split("&")[0]
                                      );
                                  }
                                  document
                                    .querySelectorAll(".autolinkMsg")[0]
                                    .classList.remove("autolinkMsg");
                                }
                                item2.remove();
                                if (
                                  document.querySelectorAll(".new_show_more")[0]
                                ) {
                                  document
                                    .querySelectorAll(".new_show_more")[0]
                                    .addEventListener("click", function () {
                                      this.parentNode.textContent =
                                        this.getAttribute("origin");
                                      this.remove();
                                    });
                                  document
                                    .querySelectorAll(".new_show_more")[0]
                                    .classList.remove("new_show_more");
                                }
                              }
                            });
                        });
                    } else {
                      //dom 삽입
                      let resendItem = document
                        .querySelectorAll(".toggle_button_3")[0]
                        .classList.contains("active")
                        ? myPermissionInfo == "팀장" ||
                          myPermissionInfo == "부팀장"
                          ? `<li class="chat_resend">답장</li>`
                          : ""
                        : `<li class="chat_resend">답장</li>`;
                      const htmlString = `<div idx="${
                        item.id
                      }" class="chat_row_2 new_chat_row"><div class="chat_long_menu"><input class="copy_target" value='${
                        item.message
                      }' type="text" /><div class="chat_long_menu_white"></div><ul><li class="chat_long_menu_copy">복사</li>${resendItem}</ul></div> <div style="background:url('${
                        item.profile_url
                          ? item.profile_url
                          : "/assets/images/icon_profile_basic.svg"
                      }') no-repeat" class="chat_profile ${
                        item.is_leader == 1
                          ? "chatLeader_profile"
                          : item.is_sub_leader == 1
                          ? "chatSubLeader_profile"
                          : ""
                      } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                        item.name
                      }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p class="msg_item">${msgString}</p> <div class="chat_info_right"> <span id_arr="${
                        item.unread_message_ids
                      }" class="un_read">${
                        item.unread_message_count
                          ? item.unread_message_count
                          : ""
                      }</span> <p class="time">${chatDate(
                        item.created_at
                      )}</p> </div> </div> </div> </div> </div> </div>`;
                      appendTarget
                        .querySelectorAll("div")[0]
                        .insertAdjacentHTML("beforebegin", htmlString);
                      //250자 이상 처리
                      if (document.querySelectorAll(".new_show_more")[0]) {
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .addEventListener("click", function () {
                            this.parentNode.textContent =
                              this.getAttribute("origin");
                            this.remove();
                          });
                        document
                          .querySelectorAll(".new_show_more")[0]
                          .classList.remove("new_show_more");
                      }

                      //탭홀드 이벤트 할당
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .setAttribute("data-long-press-delay", 750);
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .addEventListener("long-press", function (e) {
                          if (
                            !window.matchMedia("(max-width: 768px)").matches
                          ) {
                            e.preventDefault();
                          }
                          this.querySelectorAll(
                            ".chat_long_menu"
                          )[0].style.display = "block";
                          document.querySelector("body").style.overflow =
                            "hidden";
                        });
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .addEventListener("mousedown", function (e) {
                          if (e.button == 2 || e.which == 3) {
                            if (
                              !window.matchMedia("(max-width: 768px)").matches
                            ) {
                              e.preventDefault();
                            }
                            this.querySelectorAll(
                              ".chat_long_menu"
                            )[0].style.display = "block";
                            document.querySelector("body").style.overflow =
                              "hidden";
                          }
                        });
                      document
                        .querySelectorAll(
                          ".new_chat_row .chat_long_menu_white"
                        )[0]
                        .addEventListener("click", function () {
                          this.parentNode.style.display = "none";
                          document.querySelector("body").style.overflow =
                            "visible";
                        });

                      //메세지 복사 기능
                      document
                        .querySelectorAll(
                          ".new_chat_row .chat_long_menu_copy"
                        )[0]
                        .addEventListener("click", function () {
                          copyToClipboard(
                            this.parentNode.parentNode.querySelectorAll(
                              ".copy_target"
                            )[0]
                          );
                          this.parentNode.parentNode.style.display = "none";
                          createPopup({
                            type: "confirm",
                            title: "복사완료",
                            content:
                              "선택하신 메세지를 클립보드에 복사하였습니다.",
                          });
                          document.querySelector("body").style.overflow =
                            "visible";
                        });

                      //답장기능
                      document.querySelectorAll(
                        ".new_chat_row .chat_resend"
                      )[0] &&
                        document
                          .querySelectorAll(".new_chat_row .chat_resend")[0]
                          .addEventListener("click", function () {
                            document
                              .querySelectorAll(".chat_data_area")[0]
                              .insertAdjacentHTML(
                                "beforeend",
                                `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>${item.message}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                              );
                            document
                              .querySelectorAll("div.response_prev_ui img")[0]
                              .addEventListener("click", function () {
                                document
                                  .querySelectorAll(".response_prev_ui")[0]
                                  .remove();
                              });
                            this.parentNode.parentNode.style.display = "none";
                            document.querySelector("body").style.overflow =
                              "visible";
                          });

                      //구분 클래스네임 삭제
                      document
                        .querySelectorAll(".new_chat_row")[0]
                        .classList.remove("new_chat_row");
                    }
                  } else if (item.type == "schedule" || item.type == "return") {
                    //dom 삽입
                    let resendItem = document
                      .querySelectorAll(".toggle_button_3")[0]
                      .classList.contains("active")
                      ? myPermissionInfo == "팀장" ||
                        myPermissionInfo == "부팀장"
                        ? `<li class="chat_resend">답장</li>`
                        : ""
                      : `<li class="chat_resend">답장</li>`;
                    const menuString = `<div class="chat_long_menu"><input class="copy_target" value='${item.message}' type="text" /><div class="chat_long_menu_white"></div><ul>${resendItem}</ul></div>`;

                    if (item.type == "return") {
                      document.querySelectorAll(
                        ".chat_data_inner>div[idx='" + item.etc + "']"
                      )[0] &&
                        document
                          .querySelectorAll(
                            ".chat_data_inner>div[idx='" + item.etc + "']"
                          )[0]
                          .remove();
                    }
                    let statusClassName, title;
                    if (item.schedule.status == 0) {
                      //요청
                      statusClassName = "request";
                      title = "일정이 요청되었습니다.";
                    } else if (item.schedule.status == 1) {
                      //수락
                      statusClassName = "admit";
                      title = `일정이 수락되었습니다.`;
                    } else if (item.schedule.status == 2) {
                      //완료
                      statusClassName = "complete";
                      title = `일정이 완료되었습니다.`;
                    } else if (item.schedule.status == 5) {
                      //보류
                      statusClassName = "stay";
                      title = `일정이 보류되었습니다.`;
                    } else if (item.schedule.status == 10) {
                      //취소
                      statusClassName = "cancel";
                      title = `일정이 취소되었습니다.`;
                    }

                    const htmlString = `<div data-schedule_id="${
                      item.schedule.id
                    }" idx="${
                      item.id
                    }" class="schedule_wrapper new_chat_row message_${
                      item.id
                    }">${menuString}<div class="schedule_bottom_box"><p class="time">${chatDate(
                      item.created_at
                    )}</p><span class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span></div><div class="schedule_header ${statusClassName}"> <h1>${title}</h1> <p class="new_schedule_detail_button">상세보기</p> </div> <div class="schedule_row_1"> <h2>${
                      item.schedule.contents
                    }</h2> </div> <div class="schedule_row_2"> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_label.svg" alt=""> <span>${
                      item.schedule.place
                    }</span> </div> <div class="schedule_row_2_inner_row"> <img src="/assets/images/icon_schedule_s.svg" alt=""> <span>${dateSecondCutter(
                      item.schedule.begin_date
                    )} ~<br>${
                      item.schedule.end_date
                        ? dateSecondCutter(item.schedule.end_date)
                        : ""
                    }</span> </div> </div> <span class="schedule_price blue">${numberWithCommas(
                      item.schedule.price
                    )}원</span> </div>`;

                    appendTarget
                      .querySelectorAll("div")[0]
                      .insertAdjacentHTML("beforebegin", htmlString);

                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .addEventListener("click", function () {
                        document
                          .querySelectorAll(".right_popup")
                          .forEach((item, idx) => {
                            _animateRightModule(false, item);
                          });

                        setTimeout(() => {
                          _animateRightModule(
                            true,
                            document.querySelectorAll(".d_4")[0]
                          );
                          updateScheduleData(
                            item.schedule.id,
                            myPermissionInfo
                          );
                        }, 500);
                        document
                          .querySelectorAll(".common_more_wrapper li")
                          .forEach((item2, idx2) => {
                            item2.setAttribute(
                              "data-schedule_id",
                              item.schedule.id
                            );
                          });
                        document
                          .querySelectorAll(".edit_schedule")[0]
                          .setAttribute("data-schedule_id", item.schedule.id);
                      });
                    document
                      .querySelectorAll(".new_schedule_detail_button")[0]
                      .classList.remove("new_schedule_detail_button");

                    //답장기능
                    document.querySelectorAll(
                      ".new_chat_row .chat_resend"
                    )[0] &&
                      document
                        .querySelectorAll(".new_chat_row .chat_resend")[0]
                        .addEventListener("click", function () {
                          document
                            .querySelectorAll(".chat_data_area")[0]
                            .insertAdjacentHTML(
                              "beforeend",
                              `<div idx="${item.id}" class="response_prev_ui"><h1>${item.name}</h1><span>[일정] ${item.schedule.contents}</span><img src="/assets/images/icon_response_end.svg" /></div>`
                            );
                          document
                            .querySelectorAll("div.response_prev_ui img")[0]
                            .addEventListener("click", function () {
                              document
                                .querySelectorAll(".response_prev_ui")[0]
                                .remove();
                            });
                          this.parentNode.parentNode.style.display = "none";
                          document.querySelector("body").style.overflow =
                            "visible";
                        });

                    //탭홀드 이벤트 할당
                    document
                      .querySelectorAll(".new_chat_row")[0]
                      .setAttribute("data-long-press-delay", 750);
                    document
                      .querySelectorAll(".new_chat_row")[0]
                      .addEventListener("long-press", function (e) {
                        if (!window.matchMedia("(max-width: 768px)").matches) {
                          e.preventDefault();
                        }
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].style.display = "block";
                        this.querySelectorAll(
                          ".chat_long_menu"
                        )[0].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                        document.querySelector("body").style.overflow =
                          "hidden";
                      });
                    document
                      .querySelectorAll(".new_chat_row")[0]
                      .addEventListener("mousedown", function (e) {
                        if (e.button == 2 || e.which == 3) {
                          if (
                            !window.matchMedia("(max-width: 768px)").matches
                          ) {
                            e.preventDefault();
                          }
                          this.querySelectorAll(
                            ".chat_long_menu"
                          )[0].style.display = "block";
                          this.querySelectorAll(
                            ".chat_long_menu"
                          )[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                          document.querySelector("body").style.overflow =
                            "hidden";
                        }
                      });
                    document
                      .querySelectorAll(
                        ".new_chat_row .chat_long_menu_white"
                      )[0]
                      .addEventListener("click", function () {
                        this.parentNode.style.display = "none";
                        document.querySelector("body").style.overflow =
                          "visible";
                      });
                  } else if (item.type == "notice" || item.type == undefined) {
                    //공지사항일 경우
                    const htmlString = `<div idx="${
                      item.id
                    }" class="chat_row_2 chat_row_notice"> <div style="background:url('${
                      item.profile_url
                        ? item.profile_url
                        : "/assets/images/icon_profile_basic.svg"
                    }') no-repeat" class="chat_profile ${
                      item.is_leader == 1
                        ? "chatLeader_profile"
                        : item.is_sub_leader == 1
                        ? "chatSubLeader_profile"
                        : ""
                    } new_chat_profile"></div> <div class="chat_info"> <div class="chat_info_left"> <h1 class="chat_name">${
                      item.name
                    }</h1> <div class="text_chat_wrapper"> <div class="text_chat_inner_row"> <p>${
                      item.message
                    }</p> <div class="chat_info_right"> <span id_arr="${
                      item.unread_message_ids
                    }" class="un_read">${
                      item.unread_message_count ? item.unread_message_count : ""
                    }</span> <p class="time">${chatDate(
                      item.created_at
                    )}</p> </div> </div> </div> </div> </div> </div>`;
                    appendTarget
                      .querySelectorAll("div")[0]
                      .insertAdjacentHTML("beforebegin", htmlString);
                    noticeFunctionUpdate();
                  } else if (
                    item.type_ == "notice_modify" ||
                    item.type_ == "notice_delete"
                  ) {
                    noticeFunctionUpdate();
                  }

                  if (document.querySelectorAll(".new_chat_profile")[0]) {
                    memberDetailFunction(
                      document.querySelectorAll(".new_chat_profile")[0],
                      item.teamwork_user_id,
                      item.user_id,
                      item
                    );
                    document
                      .querySelectorAll(".new_chat_profile")[0]
                      ?.classList.remove("new_chat_profile");
                  }
                }
                addDateFunc(item, idx, reversedArr.length - 1, false);
                if (reversedArr.length - 1 == idx) {
                  if (window.matchMedia("(min-width: 768px)").matches) {
                    window.scrollTo(0, lastItem.offsetTop + 104);
                  } else {
                    document
                      .querySelectorAll(".chat_data_inner")[0]
                      .scrollTo(0, lastItem.offsetTop + 104);
                  }
                  lastItem = document.querySelectorAll(".chat_data_inner>*")[1];
                }
              });
            }
          });
      }
    };

    const getTeamworkDetailInfoUpdate = () => {
      var mHSize =
        document.querySelectorAll(".chatting_header")[0].clientHeight +
        document.querySelectorAll(".chat_write")[0].clientHeight;
      if (document.querySelectorAll(".chat_loading")[0]) {
        document.querySelectorAll(".chat_loading")[0].style.height =
          "calc(100vh - " + mHSize + "px)";
        document.querySelectorAll(".chat_loading")[0].style.top =
          document.querySelectorAll(".chatting_header")[0].clientHeight + "px";
      }

      //첫번째 페이지로 init
      inner(
        `https://api.olapse.com/api/teamwork/detail?page=1&id=${props.teamworkId}`
      );
      function inner(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            //팀장일 때, 일반 팀원일때의 분기 처리
            if (data.data.teamwork_user.is_leader == 1) {
              //팀워크 설정 진입 방지
              document
                .querySelectorAll(".go_edit")[0]
                .removeEventListener("click", function () {
                  _animateRightModule(
                    true,
                    document.querySelectorAll(".d_2")[0]
                  );
                });
              document
                .querySelectorAll(".go_edit")[0]
                .addEventListener("click", function () {
                  _animateRightModule(
                    true,
                    document.querySelectorAll(".d_2")[0]
                  );
                });
              //나가기, 팀워크 삭제하기 표시 설정
              document.querySelectorAll(".end_teamwork")[0].textContent =
                "팀워크 삭제";
              endTeamwork("remove");
            } else {
              document.querySelectorAll(".end_teamwork")[0].textContent =
                "나가기";
              endTeamwork("leave");
            }
            //내 팀워크 아이디값 할당
            teamworkUserId = data.data.teamwork_user.id;
            document
              .querySelectorAll(".btnMyCal")[0]
              .addEventListener("click", function () {
                myCalFunction(teamworkUserId);
              });
            //메뉴 정보 기입
            document.querySelectorAll(
              "div.teamwork_row div.teamwork_col h1"
            )[0].textContent = data.data.teamwork.title;
            document.querySelectorAll(
              "div.teamwork_row div.teamwork_col span"
            )[0].textContent = `참여 멤버 : ${data.data.teamwork.total_user_count}명`;
            //첫번째 메세지 시간 표기
            // data.data.teamwork_messages.data[0] ? (document.querySelectorAll(".line_noticeStart .teamwork_start_date")[0].textContent = data.data.teamwork_messages.data[data.data.teamwork_messages.data.length - 1].created_at.split(" ")[0]) : (document.querySelectorAll(".line_noticeStart .teamwork_start_date")[0].textContent = "팀워크 채팅을 시작해보세요.");
            //타이틀
            document.querySelectorAll(".team_work_title_1")[0].textContent =
              data.data.teamwork.title;
            if (data.data.teamwork.title == "나와의 채팅") {
              document.querySelectorAll(
                "div.chat_sub_write_menu ul li"
              )[1].style.display = "none";
              document.querySelectorAll(".chat_menu_button")[0].style.display =
                "none";
              document.querySelectorAll(
                ".chat_myMenu_button"
              )[0].style.display = "block";
            }
          });
      }
    };

    //전체 팝업 닫기
    function disableAllPopup() {
      document.querySelectorAll(".right_popup").forEach((item, idx) => {
        item.style.display = "none";
      });
    }

    //복사
    function copyToClipboard(copyTarget) {
      copyTarget.select();
      document.execCommand("Copy");
    }

    //멤버 리스트 관련 기능
    function memberListFunction() {
      document
        .querySelector("#member_list")
        .addEventListener("click", function () {
          getTeamworkMemberList();
          if (
            document
              .querySelectorAll(".toggle_button_3")[0]
              .classList.contains("active")
          ) {
            if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
              disableAllPopup();
              _animateRightModule(true, document.querySelectorAll(".d_3")[0]);
            } else {
              createPopup({
                type: "confirm",
                title: "조회 불가",
                content: "멤버간 프로필 보기가 제한된 팀워크입니다.",
              });
            }
          } else {
            disableAllPopup();
            _animateRightModule(true, document.querySelectorAll(".d_3")[0]);
          }
        });
      //실행
      // getTeamworkMemberList();
      editMember();

      //멤버 리스트 가져오기
      function getTeamworkMemberList() {
        fetch(
          `https://api.olapse.com/api/teamwork/member?id=${getParameterByName(
            "idx"
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.teamwork_users.length !== 0) {
              document.querySelectorAll(".member_list")[0].innerHTML = "";
              document.querySelectorAll(".member_list")[1].innerHTML = "";
              data.data.teamwork_users.forEach((item, idx) => {
                function appendAll(appendTarget) {
                  const htmlString = `<li teamworkUserId="${item.id}" idx="${
                    item.user_id
                  }" class="new_item"> <p class="new_profile_item" style="background:url(${
                    item.profile_url
                      ? item.profile_url
                      : "/assets/images/icon_profile_basic.svg"
                  }) no-repeat"></p> <div> <h1>${item.name}</h1> </div> </li>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  if (item.is_leader == 1) {
                    //리더일 경우
                    if (item.user_id == myId) {
                      document.querySelectorAll(
                        ".edit_member"
                      )[0].style.display = "block";
                    }
                    document
                      .querySelectorAll(".new_item .new_profile_item")[0]
                      .classList.add("master");
                  }
                  if (item.is_sub_leader == 1) {
                    document
                      .querySelectorAll(".new_item .new_profile_item")[0]
                      .classList.add("sub_master");
                  }

                  document
                    .querySelectorAll(".new_item")[0]
                    .addEventListener("click", function () {
                      if (
                        !this.parentNode.parentNode.classList.contains(
                          "all_memeber_list_2"
                        )
                      ) {
                        if (this.classList.contains("active")) {
                          this.classList.remove("active");
                        } else {
                          this.classList.add("active");
                        }
                      }
                    });
                  if (
                    document
                      .querySelectorAll(".new_item")[0]
                      .parentNode.parentNode.classList.contains(
                        "all_memeber_list_2"
                      )
                  ) {
                    memberDetailFunction(
                      document.querySelectorAll(".new_item")[0],
                      item.id,
                      item.user_id,
                      item
                    );
                    document
                      .querySelectorAll(".member_name")[0]
                      .setAttribute("idx", item.id);
                  }
                  document
                    .querySelectorAll(".new_item")[0]
                    .classList.remove("new_item");
                }
                appendAll(document.querySelectorAll(".member_list")[0]);
                appendAll(document.querySelectorAll(".member_list")[1]);
                // appendAll(document.querySelectorAll(".member_list")[2]);
              });
              getMyMemberList();
            }
          });
      }

      //추가된 멤버 리스트 가져오기
      function getMyMemberList() {
        fetch("https://api.olapse.com/api/member", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.user_friends.length !== 0) {
              data.data.user_friends.forEach((item, idx) => {
                const htmlString = `<li idx="${
                  item.id
                }" class="new_item"> <p class="new_profile_item" style="background:url(${
                  item.profile_url
                    ? item.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                }) no-repeat"></p> <div> <h1>${item.name}</h1> </div> </li>`;
                document
                  .querySelectorAll(".member_list_for_add")[0]
                  .insertAdjacentHTML("beforeend", htmlString);

                document
                  .querySelectorAll(".new_item")[0]
                  .addEventListener("click", function () {
                    if (
                      !this.parentNode.parentNode.classList.contains(
                        "all_memeber_list_2"
                      )
                    ) {
                      if (this.classList.contains("active")) {
                        this.classList.remove("active");
                      } else {
                        this.classList.add("active");
                      }
                    }
                  });

                document
                  .querySelectorAll(".new_item")[0]
                  .classList.remove("new_item");
              });
            }
          });
      }
      //결제타입 두개 기능
      // let cardSelArr = [];
      // document.querySelectorAll("div.payment_select_popup_wrapper div.payment_select_popup_box ul li").forEach((item, idx) => {
      //     item.addEventListener("click", function () {
      //         if (cardSelArr.length < 1) {
      //             if (item.classList.contains("active")) {
      //                 cardSelArr.splice(cardSelArr.indexOf(item), 1);
      //                 item.classList.remove("active");
      //             } else {
      //                 cardSelArr.push(item);
      //                 item.classList.add("active");
      //             }
      //         } else {
      //             if (item.classList.contains("active")) {
      //                 item.classList.remove("active");
      //                 cardSelArr.splice(cardSelArr.indexOf(item), 1);
      //             } else {
      //                 cardSelArr[0].classList.remove("active");
      //                 cardSelArr.shift();
      //                 cardSelArr.push(item);
      //                 item.classList.add("active");
      //             }
      //         }
      //     });
      // });
      // filteringBtns("div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li", "active");
      filteringBtns(
        "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li",
        "active"
      );

      //멤버 편집
      function editMember() {
        document
          .querySelectorAll(".edit_member")[0]
          .addEventListener("click", function () {
            document.querySelectorAll(".memeber_list_popup")[0].style.display =
              "flex";
            _animationAppearTopDown(
              document.querySelectorAll(".member_list_box")[0]
            );
          });
        document
          .querySelectorAll(".cancel_popup_button")[0]
          .addEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(".member_list_box")[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup"
              )[0].style.display = "none";
            }, 300);
          });
        document
          .querySelector("#add_member")
          .addEventListener("click", function () {
            if (
              document
                .querySelectorAll("div.pers ul li")[0]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else if (
              document
                .querySelectorAll("div.pers ul li")[1]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "flex";
              _animationAppearTopDown(
                document.querySelectorAll(
                  ".memeber_list_popup_2 .member_list_box"
                )[0]
              );
            }
          });
        document
          .querySelectorAll(".memeber_list_popup_2 .cancel_popup_button")[0]
          .addEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(
                ".memeber_list_popup_2 .member_list_box"
              )[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "none";
            }, 300);
          });
      }

      //멤버 내보내기
      document
        .querySelectorAll(".remove_member_button")[0]
        .addEventListener("click", function () {
          outMember();
        });

      function outMember() {
        let formdata = new FormData();
        formdata.append("teamwork_id", getParameterByName("idx"));
        document
          .querySelectorAll(".member_list_editable li.active")
          .forEach((item, idx) => {
            formdata.append(
              "teamwork_user_ids[]",
              item.getAttribute("teamworkUserId")
            );
          });

        fetch(`https://api.olapse.com/api/teamwork/member/out`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "내보내기 완료",
                content: "선택한 멤버를 내보냈습니다.",
                custom: () => {
                  location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "내보내기 실패",
                content: data.alert,
              });
            }
          });
      }

      //전화기능
      document
        .querySelectorAll(".customer_phone_number_row span")[0]
        .addEventListener("click", function () {
          this.textContent !== "-" && phoneCall(this.textContent);
        });
    }

    function memberListFunctionUpdate() {
      function memberListClick() {
        getTeamworkMemberList();
        if (
          document
            .querySelectorAll(".toggle_button_3")[0]
            .classList.contains("active")
        ) {
          if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
            disableAllPopup();
            _animateRightModule(true, document.querySelectorAll(".d_3")[0]);
          } else {
            createPopup({
              type: "confirm",
              title: "조회 불가",
              content: "멤버간 프로필 보기가 제한된 팀워크입니다.",
            });
          }
        } else {
          disableAllPopup();
          _animateRightModule(true, document.querySelectorAll(".d_3")[0]);
        }
      }
      document
        .querySelector("#member_list")
        .removeEventListener("click", function () {
          memberListClick();
        });
      document
        .querySelector("#member_list")
        .addEventListener("click", function () {
          memberListClick();
        });

      //실행
      editMemberDel();
      editMember();

      //멤버 리스트 가져오기
      function getTeamworkMemberList() {
        fetch(
          `https://api.olapse.com/api/teamwork/member?id=${getParameterByName(
            "idx"
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.teamwork_users.length !== 0) {
              document.querySelectorAll(".member_list")[0].innerHTML = "";
              document.querySelectorAll(".member_list")[1].innerHTML = "";
              document.querySelectorAll(".member_list")[0].textContent = "";
              document.querySelectorAll(".member_list")[1].textContent = "";
              data.data.teamwork_users.forEach((item, idx) => {
                function appendAll(appendTarget) {
                  const htmlString = `<li teamworkUserId="${item.id}" idx="${
                    item.user_id
                  }" class="new_item"> <p class="new_profile_item" style="background:url(${
                    item.profile_url
                      ? item.profile_url
                      : "/assets/images/icon_profile_basic.svg"
                  }) no-repeat"></p> <div> <h1>${item.name}</h1> </div> </li>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  if (item.is_leader == 1) {
                    //리더일 경우
                    if (item.user_id == myId) {
                      document.querySelectorAll(
                        ".edit_member"
                      )[0].style.display = "block";
                    }
                    document
                      .querySelectorAll(".new_item .new_profile_item")[0]
                      .classList.add("master");
                  }
                  if (item.is_sub_leader == 1) {
                    document
                      .querySelectorAll(".new_item .new_profile_item")[0]
                      .classList.add("sub_master");
                  }
                  document
                    .querySelectorAll(".new_item")[0]
                    .removeEventListener("click", function () {
                      if (
                        !this.parentNode.parentNode.classList.contains(
                          "all_memeber_list_2"
                        )
                      ) {
                        if (this.classList.contains("active")) {
                          this.classList.remove("active");
                        } else {
                          this.classList.add("active");
                        }
                      }
                    });
                  document
                    .querySelectorAll(".new_item")[0]
                    .addEventListener("click", function () {
                      if (
                        !this.parentNode.parentNode.classList.contains(
                          "all_memeber_list_2"
                        )
                      ) {
                        if (this.classList.contains("active")) {
                          this.classList.remove("active");
                        } else {
                          this.classList.add("active");
                        }
                      }
                    });
                  if (
                    document
                      .querySelectorAll(".new_item")[0]
                      .parentNode.parentNode.classList.contains(
                        "all_memeber_list_2"
                      )
                  ) {
                    memberDetailFunction(
                      document.querySelectorAll(".new_item")[0],
                      item.id,
                      item.user_id,
                      item
                    );
                    document
                      .querySelectorAll(".member_name")[0]
                      .setAttribute("idx", item.id);
                  }
                  document
                    .querySelectorAll(".new_item")[0]
                    .classList.remove("new_item");
                }
                appendAll(document.querySelectorAll(".member_list")[0]);
                appendAll(document.querySelectorAll(".member_list")[1]);
                // appendAll(document.querySelectorAll(".member_list")[2]);
              });
              getMyMemberList();
            }
          });
      }

      //추가된 멤버 리스트 가져오기
      function getMyMemberList() {
        fetch("https://api.olapse.com/api/member", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            document.querySelectorAll(".member_list_for_add")[0].textContent =
              "";
            if (data.data.user_friends.length !== 0) {
              data.data.user_friends.forEach((item, idx) => {
                const htmlString = `<li idx="${
                  item.id
                }" class="new_item"> <p class="new_profile_item" style="background:url(${
                  item.profile_url
                    ? item.profile_url
                    : "/assets/images/icon_profile_basic.svg"
                }) no-repeat"></p> <div> <h1>${item.name}</h1> </div> </li>`;
                document
                  .querySelectorAll(".member_list_for_add")[0]
                  .insertAdjacentHTML("beforeend", htmlString);

                document
                  .querySelectorAll(".new_item")[0]
                  .removeEventListener("click", function () {
                    if (
                      !this.parentNode.parentNode.classList.contains(
                        "all_memeber_list_2"
                      )
                    ) {
                      if (this.classList.contains("active")) {
                        this.classList.remove("active");
                      } else {
                        this.classList.add("active");
                      }
                    }
                  });

                document
                  .querySelectorAll(".new_item")[0]
                  .addEventListener("click", function () {
                    if (
                      !this.parentNode.parentNode.classList.contains(
                        "all_memeber_list_2"
                      )
                    ) {
                      if (this.classList.contains("active")) {
                        this.classList.remove("active");
                      } else {
                        this.classList.add("active");
                      }
                    }
                  });

                document
                  .querySelectorAll(".new_item")[0]
                  .classList.remove("new_item");
              });
            }
          });
      }

      //멤버 편집
      function editMemberDel() {
        document
          .querySelectorAll(".edit_member")[0]
          .removeEventListener("click", function () {
            document.querySelectorAll(".memeber_list_popup")[0].style.display =
              "flex";
            _animationAppearTopDown(
              document.querySelectorAll(".member_list_box")[0]
            );
          });
        document
          .querySelectorAll(".cancel_popup_button")[0]
          .removeEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(".member_list_box")[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup"
              )[0].style.display = "none";
            }, 300);
          });
        document
          .querySelector("#add_member")
          .removeEventListener("click", function () {
            if (
              document
                .querySelectorAll("div.pers ul li")[0]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else if (
              document
                .querySelectorAll("div.pers ul li")[1]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "flex";
              _animationAppearTopDown(
                document.querySelectorAll(
                  ".memeber_list_popup_2 .member_list_box"
                )[0]
              );
            }
          });
        document
          .querySelectorAll(".memeber_list_popup_2 .cancel_popup_button")[0]
          .removeEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(
                ".memeber_list_popup_2 .member_list_box"
              )[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "none";
            }, 300);
          });
      }
      function editMember() {
        document
          .querySelectorAll(".edit_member")[0]
          .addEventListener("click", function () {
            document.querySelectorAll(".memeber_list_popup")[0].style.display =
              "flex";
            _animationAppearTopDown(
              document.querySelectorAll(".member_list_box")[0]
            );
          });
        document
          .querySelectorAll(".cancel_popup_button")[0]
          .addEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(".member_list_box")[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup"
              )[0].style.display = "none";
            }, 300);
          });
        document
          .querySelector("#add_member")
          .addEventListener("click", function () {
            if (
              document
                .querySelectorAll("div.pers ul li")[0]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else if (
              document
                .querySelectorAll("div.pers ul li")[1]
                .classList.contains("active")
            ) {
              if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
                document.querySelectorAll(
                  ".memeber_list_popup_2"
                )[0].style.display = "flex";
                _animationAppearTopDown(
                  document.querySelectorAll(
                    ".memeber_list_popup_2 .member_list_box"
                  )[0]
                );
              } else {
                createPopup({
                  type: "confirm",
                  title: "초대 불가",
                  content: "초대 권한이 없습니다.",
                });
              }
            } else {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "flex";
              _animationAppearTopDown(
                document.querySelectorAll(
                  ".memeber_list_popup_2 .member_list_box"
                )[0]
              );
            }
          });
        document
          .querySelectorAll(".memeber_list_popup_2 .cancel_popup_button")[0]
          .addEventListener("click", function () {
            _animationDisappearBottomUp(
              document.querySelectorAll(
                ".memeber_list_popup_2 .member_list_box"
              )[0]
            );
            setTimeout(() => {
              document.querySelectorAll(
                ".memeber_list_popup_2"
              )[0].style.display = "none";
            }, 300);
          });
      }

      //멤버 내보내기
      document
        .querySelectorAll(".remove_member_button")[0]
        .removeEventListener("click", function () {
          outMember();
        });
      document
        .querySelectorAll(".remove_member_button")[0]
        .addEventListener("click", function () {
          outMember();
        });

      function outMember() {
        let formdata = new FormData();
        formdata.append("teamwork_id", getParameterByName("idx"));
        document
          .querySelectorAll(".member_list_editable li.active")
          .forEach((item, idx) => {
            formdata.append(
              "teamwork_user_ids[]",
              item.getAttribute("teamworkUserId")
            );
          });

        fetch(`https://api.olapse.com/api/teamwork/member/out`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "내보내기 완료",
                content: "선택한 멤버를 내보냈습니다.",
                custom: () => {
                  location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "내보내기 실패",
                content: data.alert,
              });
            }
          });
      }
    }

    //설정 토글 버튼 기능
    function toggleSettings() {
      toggleMsgHide();
      toggleTeamworkAlarm();
    }

    //일정 완료처리
    let paymentType1Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_1 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType1Chk) {
          paymentType1Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.com/api/teamwork/schedule/complete`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType1Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "일정완료",
                    content: "일정을 완료하였습니다.",
                    custom: () => {
                      // location.reload(true);
                      var pel = document.querySelectorAll(
                        ".payment_select_popup_wrapper_1"
                      )[0];
                      _animationDisappearBottomUp(
                        pel.querySelectorAll(".payment_select_popup_box")[0]
                      );
                      _animateRightModule(
                        false,
                        document.querySelectorAll(".d_4")[0]
                      );
                      setTimeout(() => {
                        pel.style.display = "none";
                      }, 400);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "일정완료 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType1Chk = true;
            createPopup({
              type: "reTry",
              title: "일정 완료 불가",
              content: "일정을 하나 이상 선택해주세요.",
            });
          }
        }
      });

    //일정 완료처리
    let paymentType2Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_2 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType2Chk) {
          paymentType2Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.com/api/teamwork/schedule/type/modify`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType2Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "변경완료",
                    content: "결제타입을 변경하였습니다.",
                    custom: () => {
                      location.reload(true);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "타입변경 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType2Chk = true;
            createPopup({
              type: "reTry",
              title: "타입 변경 불가",
              content: "타입을 하나 이상 선택해주세요.",
            });
          }
        }
      });

    //일정 완료 취소(단순 팝업 닫기)
    document
      .querySelectorAll(
        ".payment_select_popup_box_button_wrapper button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        _animationDisappearBottomUp(
          document.querySelectorAll(
            "div.payment_select_popup_wrapper div.payment_select_popup_box"
          )[0]
        );
        setTimeout(() => {
          document.querySelectorAll(
            ".payment_select_popup_wrapper"
          )[0].style.display = "none";
        }, 400);
      });

    //메세지 숨김 토글
    function toggleMsgHide() {
      document
        .querySelectorAll(".hide_msg_button")[0]
        .addEventListener("click", function () {
          excute();
        });
      function excute() {
        let formdata = new FormData();
        formdata.append("teamwork_user_id", teamworkUserId);
        fetch(`https://api.olapse.com/api/teamwork/hide/message`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              window.location.reload(true);
            }
          });
      }
    }
    //알람 토글
    function toggleTeamworkAlarm() {
      document
        .querySelectorAll(".alarm_toggle_button")[0]
        .addEventListener("click", function () {
          excute();
        });
      function excute() {
        let formdata = new FormData();
        formdata.append("teamwork_user_id", teamworkUserId);
        fetch(`https://api.olapse.com/api/teamwork/alarm`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {});
      }
    }
    //팀워크 나가기, 삭제하기
    function endTeamwork(type) {
      document
        .querySelectorAll(".end_teamwork")[0]
        .addEventListener("click", function () {
          if (type == "leave") {
            createPopup({
              type: "custom_select_2",
              title: `${
                document.querySelectorAll(".team_work_title_1")[0].textContent
              } 팀워크를 나가시겠습니까?`,
              content: "팀워크를 나가면 모든 데이터가 삭제됩니다.",
              button_text_1: "취소",
              button_text_2: "삭제",
              firstButtonClickFunction: () => {
                //팀워크 나가기
                let formdata = new FormData();
                formdata.append("teamwork_user_id", teamworkUserId);
                fetch(`https://api.olapse.com/api/teamwork/leave`, {
                  method: "POST",
                  body: formdata,
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    setTimeout(() => {
                      if (data.success == true) {
                        createPopup({
                          type: "custom",
                          title: "팀워크 나가기",
                          content: "팀워크를 나갔습니다.",
                          custom: () => {
                            window.location.href = "/chat";
                          },
                        });
                      } else {
                        createPopup({
                          type: "reTry",
                          title: "팀워크 나가기 실패",
                          content: data.alert,
                        });
                      }
                    }, 500);
                  });
              },
              secondButtonClickFunction: () => {},
            });
            document.querySelectorAll(
              "div.popup_wrapper div.popup_box button.button_right"
            )[0].style.color = "#ff5555";
            document.querySelectorAll(
              "div.popup_wrapper div.popup_box div.popup_text_wrapper h1"
            )[0].style.textAlign = "center";
          } else if (type == "remove") {
            //팀워크 삭제
            createPopup({
              type: "custom_select_2",
              title: `${
                document.querySelectorAll(".team_work_title_1")[0].textContent
              } 팀워크를 삭제 하시겠습니까?`,
              content: "팀워크를 삭제하면 모든 데이터가 삭제됩니다.",
              button_text_1: "취소",
              button_text_2: "삭제",
              firstButtonClickFunction: () => {
                //팀워크 지우기
                let formdata = new FormData();
                formdata.append("teamwork_user_id", teamworkUserId);
                fetch(`https://api.olapse.com/api/teamwork/delete`, {
                  method: "POST",
                  body: formdata,
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    setTimeout(() => {
                      if (data.success == true) {
                        createPopup({
                          type: "custom",
                          title: "팀워크 삭제",
                          content: "팀워크를 삭제하였습니다.",
                          custom: () => {
                            window.location.href = "/chat";
                          },
                        });
                      } else {
                        createPopup({
                          type: "reTry",
                          title: "팀워크 삭제 실패",
                          content: data.alert,
                        });
                      }
                    }, 500);
                  });
              },
              secondButtonClickFunction: () => {},
            });
            document.querySelectorAll(
              "div.popup_wrapper div.popup_box button.button_right"
            )[0].style.color = "#ff5555";
            document.querySelectorAll(
              "div.popup_wrapper div.popup_box div.popup_text_wrapper h1"
            )[0].style.textAlign = "center";
          }
        });
    }

    //토글버튼 단순 기능
    document.querySelectorAll(".toggle_button").forEach((item, idx) => {
      toggleClass(item, item, "active");
    });
    //캘린더 검색 팝업 닫기
    document
      .querySelectorAll(".chat_search_popup_wrapper_background_black")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(
          ".chat_search_popup_wrapper"
        )[0].style.display = "none";
        searchCalendar.gotoDate(getToday());
      });

    //팀워크 정보 세팅
    function settingTeamworkData() {
      if (getParameterByName("idx")) {
        fetch(
          `https://api.olapse.com/api/teamwork/info?teamwork_id=${getParameterByName(
            "idx"
          )}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            document.querySelectorAll(
              ".teamwork_row_menu_img"
            )[0].style.background = `url('${
              data.data.teamwork.image_url
                ? data.data.teamwork.image_url
                : "/assets/images/icon_profile_basic.svg"
            }') no-repeat`;
            (document.querySelectorAll(".cal_count")[0].textContent =
              data.data.calculate_count),
              (document.querySelectorAll(".cal_count_2")[0].textContent =
                data.data.schedule_count),
              (document.querySelectorAll(".photo_count")[0].textContent =
                data.data.image_count),
              (document.querySelectorAll(".file_count")[0].textContent =
                data.data.file_count),
              (document.querySelectorAll(".notice_count")[0].textContent =
                data.data.notice_count);
            data.data.is_hide_teamwork_message == 1 &&
              document
                .querySelectorAll(".hide_msg_button")[0]
                .classList.add("active");
            data.data.use_alarm == 1 &&
              document
                .querySelectorAll(".alarm_toggle_button")[0]
                .classList.add("active");
            if (data.data.is_hide_teamwork_message == 1) {
              isHideMsg = true;
            }

            document
              .querySelectorAll(".toggle_button_1")[0]
              .classList.remove("active");

            document.querySelector("#modi_name").value =
              data.data.teamwork.title;
            data.data.teamwork.use_card == 1 &&
              document
                .querySelectorAll(".toggle_button_1")[0]
                .classList.add("active");

            document
              .querySelectorAll(".toggle_button_2")[0]
              .classList.remove("active");
            document.querySelectorAll(
              ".modi_teamwork_password"
            )[0].style.display = "none";
            document.querySelectorAll(
              ".button_change_password"
            )[0].style.display = "none";
            if (data.data.teamwork.use_password == 1) {
              document
                .querySelectorAll(".toggle_button_2")[0]
                .classList.add("active");
              document.querySelectorAll(
                ".modi_teamwork_password"
              )[0].style.display = "block";
              document.querySelectorAll(
                ".button_change_password"
              )[0].style.display = "flex";
              // document.querySelectorAll(".modi_teamwork_password")[0].value = data.data.teamwork.password;
            }

            document
              .querySelectorAll(".toggle_button_2")[0]
              .addEventListener("click", function () {
                if (this.classList.contains("active")) {
                  document.querySelectorAll(
                    ".modi_teamwork_password"
                  )[0].style.display = "block";
                  document.querySelectorAll(
                    ".button_change_password"
                  )[0].style.display = "block";
                } else {
                  document.querySelectorAll(
                    ".modi_teamwork_password"
                  )[0].style.display = "none";
                  document.querySelectorAll(
                    ".button_change_password"
                  )[0].style.display = "none";
                }
              });
            document
              .querySelectorAll(".toggle_button_3")[0]
              .classList.remove("active");
            if (data.data.teamwork.use_view_profile == 1) {
              document
                .querySelectorAll(".toggle_button_3")[0]
                .classList.add("active");
              isBlock = true;
            }
            document
              .querySelectorAll("div.pers ul li")[0]
              .classList.remove("active");
            document
              .querySelectorAll("div.pers ul li")[1]
              .classList.remove("active");
            document
              .querySelectorAll("div.pers ul li")[2]
              .classList.remove("active");
            document.querySelectorAll(
              "div.create_wrapper_2 div.photo_area label"
            )[0].style.background = `url(${
              data.data.teamwork.image_url
                ? data.data.teamwork.image_url
                : "/assets/images/icon_profile_basic.svg"
            }) no-repeat`;
            if (data.data.teamwork.invite_auth == 0) {
              document
                .querySelectorAll("div.pers ul li")[0]
                .classList.add("active");
            } else if (data.data.teamwork.invite_auth == 1) {
              document
                .querySelectorAll("div.pers ul li")[1]
                .classList.add("active");
            } else if (data.data.teamwork.invite_auth == 2) {
              document
                .querySelectorAll("div.pers ul li")[2]
                .classList.add("active");
            }
          });
      }
    }

    document
      .querySelectorAll(".write_notice")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".chatting_area")[0].style.display = "none";
        document.querySelectorAll(".writeTeamworkNotice")[0].style.display =
          "flex";
        document.querySelectorAll(".submit_notice")[0].textContent = "작성완료";
        document.querySelectorAll(
          ".writeTeamworkNotice .right_area_title"
        )[0].textContent = "공지사항 작성";
        document.querySelector("#noti_title").value = "";
        document.querySelector("#noti_content").value = "";
      });

    // fetch(`https://api.olapse.com/api/teamwork/info?teamwork_id=${getParameterByName("idx")}`, {
    //     method: "GET",
    //     headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    // }).then(function (response) {
    //         return response.json();
    // }).then(function (data) {

    //     });
    function teamworkDataUpdate() {
      fetch(
        `https://api.olapse.com/api/teamwork/info?teamwork_id=${getParameterByName(
          "idx"
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          document
            .querySelectorAll(".toggle_button_1")[0]
            .classList.remove("active");

          document.querySelector("#modi_name").value = data.data.teamwork.title;
          data.data.teamwork.use_card == 1 &&
            document
              .querySelectorAll(".toggle_button_1")[0]
              .classList.add("active");

          document
            .querySelectorAll(".toggle_button_2")[0]
            .classList.remove("active");
          document.querySelectorAll(
            ".modi_teamwork_password"
          )[0].style.display = "none";
          document.querySelectorAll(
            ".button_change_password"
          )[0].style.display = "none";
          if (data.data.teamwork.use_password == 1) {
            document
              .querySelectorAll(".toggle_button_2")[0]
              .classList.add("active");
            document.querySelectorAll(
              ".modi_teamwork_password"
            )[0].style.display = "block";
            document.querySelectorAll(
              ".button_change_password"
            )[0].style.display = "flex";
            // document.querySelectorAll(".modi_teamwork_password")[0].value = data.data.teamwork.password;
          }

          document
            .querySelectorAll(".toggle_button_3")[0]
            .classList.remove("active");
          if (data.data.teamwork.use_view_profile == 1) {
            document
              .querySelectorAll(".toggle_button_3")[0]
              .classList.add("active");
            isBlock = true;
          }
          document
            .querySelectorAll("div.pers ul li")[0]
            .classList.remove("active");
          document
            .querySelectorAll("div.pers ul li")[1]
            .classList.remove("active");
          document
            .querySelectorAll("div.pers ul li")[2]
            .classList.remove("active");
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label"
          )[0].style.background = `url(${
            data.data.teamwork.image_url
              ? data.data.teamwork.image_url
              : "/assets/images/icon_profile_basic.svg"
          }) no-repeat`;
          if (data.data.teamwork.invite_auth == 0) {
            document
              .querySelectorAll("div.pers ul li")[0]
              .classList.add("active");
          } else if (data.data.teamwork.invite_auth == 1) {
            document
              .querySelectorAll("div.pers ul li")[1]
              .classList.add("active");
          } else if (data.data.teamwork.invite_auth == 2) {
            document
              .querySelectorAll("div.pers ul li")[2]
              .classList.add("active");
          }
        });
    }

    document
      .querySelectorAll(".toggle_button_1")[0]
      .addEventListener("click", function () {
        var $this = this;
        if (this.classList.contains("active")) {
          fetch("https://api.olapse.com/api/mypage", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (
                data.data.user_business == null ||
                data.data.user_business.status == 0
              ) {
                createPopup({
                  type: "reTry",
                  title: "인증 실패",
                  content: "사업자 인증 및 PG 사 연동 후 이용할 수 있습니다.",
                });
                $this.classList.remove("active");
              }
            });
        }
      });

    document
      .querySelectorAll(".button_change_password")[0]
      .addEventListener("click", function () {
        let formdata = new FormData();
        if (
          document.querySelectorAll(".modi_teamwork_password")[0].value == ""
        ) {
          createPopup({
            type: "reTry",
            title: "수정실패",
            content: "비밀번호를 입력해주세요.",
          });
        } else {
          formdata.append("teamwork_id", getParameterByName("idx"));
          formdata.append(
            "use_password",
            document
              .querySelectorAll(".toggle_button_2")[0]
              .classList.contains("active")
              ? "1"
              : "0"
          );
          formdata.append(
            "password",
            document.querySelectorAll(".modi_teamwork_password")[0].value
          );

          fetch(`https://api.olapse.com/api/teamwork/info/password/modify`, {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.success == true) {
                createPopup({
                  type: "confirm",
                  title: "수정완료",
                  content: "비밀번호를 수정하였습니다.",
                });
              } else {
                createPopup({
                  type: "reTry",
                  title: "수정실패",
                  content: data.alert,
                });
              }
            });
        }
      });

    function editTeamwork() {
      let formdata = new FormData();
      formdata.append("teamwork_id", getParameterByName("idx"));
      // formdata.append("users[]", teamworkUserId);
      formdata.append("title", document.querySelector("#modi_name").value);

      if (
        document.querySelectorAll(
          "div.create_wrapper_2 div.photo_area label input"
        )[0].files[0]
      ) {
        formdata.append(
          "image",
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label input"
          )[0].files[0],
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label input"
          )[0].files[0].name
        );
      }
      formdata.append(
        "use_card",
        document
          .querySelectorAll(".toggle_button_1")[0]
          .classList.contains("active")
          ? "1"
          : "0"
      );
      formdata.append(
        "use_password",
        document
          .querySelectorAll(".toggle_button_2")[0]
          .classList.contains("active")
          ? "1"
          : "0"
      );
      formdata.append(
        "use_view_profile",
        document
          .querySelectorAll(".toggle_button_3")[0]
          .classList.contains("active")
          ? "1"
          : "0"
      );
      if (
        document
          .querySelectorAll(".toggle_button_2")[0]
          .classList.contains("active")
      ) {
        formdata.append(
          "password",
          document.querySelectorAll(".modi_teamwork_password")[0].value
        );
      }

      formdata.append(
        "invite_auth",
        document
          .querySelectorAll("div.pers ul li.active")[0]
          .getAttribute("idx")
      );

      fetch(`https://api.olapse.com/api/teamwork/info/modify`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "수정완료",
              content: "팀워크를 수정하였습니다.",
              custom: () => {
                location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "수정실패",
              content: data.alert,
            });
          }
        });
    }

    function menuModi() {
      if (getParameterByName("idx")) {
        filteringBtns("div.pers ul li", "active");
        document
          .querySelectorAll(".submit_modi")[0]
          .addEventListener("click", function () {
            editTeamwork();
          });
        imgUpload(
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label input"
          )[0],
          document.querySelectorAll(
            "div.create_wrapper_2 div.photo_area label"
          )[0]
        );
      }
    }

    function imgUpload(trigger, target) {
      trigger.addEventListener("input", function (e) {
        let fr = new FileReader();
        fr.readAsDataURL(this.files[0]);
        fr.onload = function () {
          target.style.background = `url(${this.result}) no-repeat`;
        };
      });
    }
    let resultArr = [];
    let resultArr2 = [];
    getMyId();

    if (getParameterByName("schedule_idx")) {
      setTimeout(() => {
        _animateRightModule(true, document.querySelectorAll(".d_4")[0]);
        updateScheduleData(
          getParameterByName("schedule_idx"),
          myPermissionInfo
        );
      }, 500);
    }
    let markInstance;

    let secType = 0;
    let shingType = 0;
    searchText();
    //검색 기능
    markInstance = new Mark(document.querySelector(".chat_data_inner"), {
      exclude: ["h1", ".time", "span"],
    });
    function shPoint(allArr, pointEl) {
      allArr.forEach(function (item, idx) {
        item.classList.remove("pointMark");
        if (allArr.length - 1 == idx) {
          pointEl.classList.add("pointMark");
        }
      });
    }
    const shDownFunc = () => {
      if (startSearchIndex < resultArr2.length) {
        startSearchIndex++;
        shPoint(resultArr2, resultArr2[startSearchIndex - 1]);
        if (window.matchMedia("(max-width: 768px)").matches) {
          document
            .querySelectorAll(".chat_data_inner")[0]
            .scrollTo(
              0,
              resultArr2[startSearchIndex - 1].getBoundingClientRect().top +
                document.querySelectorAll(".chat_data_inner")[0].scrollTop -
                200
            );
        } else {
          window.scrollTo(
            0,
            resultArr2[startSearchIndex - 1].getBoundingClientRect().top +
              window.pageYOffset -
              200
          );
        }
        document.querySelectorAll(
          ".search_navi_wrapper span"
        )[0].textContent = `${startSearchIndex}/${resultArr2.length}`;
      }
    };
    const shUpFunc = () => {
      if (startSearchIndex > 1) {
        startSearchIndex--;
        shPoint(resultArr2, resultArr2[startSearchIndex - 1]);
        if (window.matchMedia("(max-width: 768px)").matches) {
          document
            .querySelectorAll(".chat_data_inner")[0]
            .scrollTo(
              0,
              resultArr2[startSearchIndex - 1].getBoundingClientRect().top +
                document.querySelectorAll(".chat_data_inner")[0].scrollTop -
                200
            );
        } else {
          window.scrollTo(
            0,
            resultArr2[startSearchIndex - 1].getBoundingClientRect().top +
              window.pageYOffset -
              200
          );
        }
        document.querySelectorAll(
          ".search_navi_wrapper span"
        )[0].textContent = `${startSearchIndex}/${resultArr2.length}`;
      }
    };

    function searchText() {
      document
        .querySelectorAll(".chatting_area_show .chat_search_button")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            ".chatting_area_show .search_module"
          )[0].style.display = "flex";
        });
      document
        .querySelectorAll(".chatting_area_show .close_search_module")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            ".chatting_area_show .search_module"
          )[0].style.display = "none";
          markInstance.unmark();
          document.querySelectorAll(".search_navi_wrapper")[0].style.display =
            "none";
        });

      document
        .querySelectorAll(".search_submit_button")[0]
        .addEventListener("click", function () {
          shingType = 1;
          if (markInstance !== undefined) {
            markInstance.unmark({
              done: function () {
                resultArr = [];
                resultArr2 = [];
                searchChatWord(
                  document.querySelector(".chatting_area_show #search_input")
                    .value
                );
              },
            });
          } else {
            resultArr = [];
            resultArr2 = [];
            searchChatWord(
              document.querySelector(".chatting_area_show #search_input").value
            );
          }
        });
    }
    let searchTime = 0;

    function searchChatWord(keyword) {
      startSearchIndex = 1;
      function performMark(value) {
        markInstance.mark(value, {
          done: (info) => {
            resultArr = [];
            resultArr2 = [];
            document.querySelectorAll(".search_navi_wrapper")[0].style.display =
              "flex";
            document.querySelectorAll("mark").forEach((item, idx) => {
              getParents(item, "body").forEach((item, idx) => {
                if (item.getAttribute("idx")) {
                  resultArr.push(item);
                }
              });
            });
            const set = new Set(resultArr);
            // resultArr2 = [...set];
            resultArr2.unshift(...set);
            // document.querySelectorAll(".search_navi_wrapper span")[0].textContent = `${startSearchIndex}/${resultArr2.length}`;
            document.querySelectorAll(
              ".search_navi_wrapper span"
            )[0].textContent = `${resultArr2.length}/${resultArr2.length}`;
            startSearchIndex = resultArr2.length;
            searchTime++;
          },
          exclude: ["h1", ".time", "span"],
        });
      }
      function performMarkV2(value) {
        markInstance.unmark({
          done: function () {
            markInstance.mark(value, {
              done: (info) => {
                document.querySelectorAll(
                  ".search_navi_wrapper"
                )[0].style.display = "flex";
                document
                  .querySelectorAll(".search_navi_wrapper img")[0]
                  .removeEventListener("click", shDownFunc);
                document
                  .querySelectorAll(".search_navi_wrapper img")[1]
                  .removeEventListener("click", shUpFunc);

                if (searchTime !== 0) {
                  document
                    .querySelectorAll(".search_navi_wrapper img")[0]
                    .addEventListener("click", shDownFunc);
                  document
                    .querySelectorAll(".search_navi_wrapper img")[1]
                    .addEventListener("click", shUpFunc);
                }

                if (window.matchMedia("(max-width: 768px)").matches) {
                  document
                    .querySelectorAll(".chat_data_inner")[0]
                    .scrollTo(
                      0,
                      resultArr2[resultArr2.length - 1].getBoundingClientRect()
                        .top +
                        document.querySelectorAll(".chat_data_inner")[0]
                          .scrollTop -
                        200
                    );
                } else {
                  window.scrollTo(
                    0,
                    resultArr2[resultArr2.length - 1].getBoundingClientRect()
                      .top +
                      window.pageYOffset -
                      200
                  );
                  shPoint(resultArr2, resultArr2[resultArr2.length - 1]);
                }
                secType = 1;
                shingType = 0;
              },
              exclude: ["h1", ".time", "span"],
            });
          },
        });
      }
      fetch(
        `https://api.olapse.com/api/teamwork/message/search?id=${getParameterByName(
          "idx"
        )}&keyword=${keyword}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.data.teamwork_message) {
            const firstMsgId = data.data.teamwork_message.id;
            searchId();
            function searchId() {
              let matched = 0;
              document
                .querySelectorAll(".chat_data_inner .chat_row_2")
                .forEach((item, idx) => {
                  if (firstMsgId == item.getAttribute("idx")) {
                    matched++;
                    secType = 1;
                  }
                });
              document
                .querySelectorAll(".chat_data_inner .chat_row")
                .forEach((item, idx) => {
                  if (firstMsgId == item.getAttribute("idx")) {
                    matched++;
                    secType = 1;
                  }
                });

              if (
                document.querySelectorAll(".rest_chat_data_button")[0].style
                  .display == "none"
              ) {
                matched++;
                secType = 1;
              }
              if (shingType == 1) {
                setTimeout(() => {
                  if (matched > 0 && secType == 0) {
                    setTimeout(() => {
                      performMarkV2(keyword);
                    }, 300);
                  } else if (secType == 0) {
                    document.querySelectorAll(".rest_chat_data_button")[0] &&
                      document
                        .querySelectorAll(".rest_chat_data_button")[0]
                        .click();
                    setTimeout(() => {
                      searchId();
                      performMark(keyword);
                    }, 500);
                  } else if (matched !== 0) {
                    document.querySelectorAll(".rest_chat_data_button")[0] &&
                      document
                        .querySelectorAll(".rest_chat_data_button")[0]
                        .click();
                    setTimeout(() => {
                      searchId();
                      performMark(keyword);
                      setTimeout(() => {
                        performMarkV2(keyword);
                      }, 300);
                    }, 500);
                  }
                }, 500);
              }
            }
          } else {
            createPopup({
              type: "reTry",
              title: "결과없음",
              content: "일치하는 결과가 없습니다.",
            });
          }
        });
    }

    const searchCalendarEl = document.querySelectorAll(
      ".chat_search_popup_wrapper_cal"
    )[0];
    searchCalendar = new FullCalendar.Calendar(searchCalendarEl, {
      plugins: [dayGridPlugin, listPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      dateClick: function (info) {
        if (info.dayEl.getAttribute("disabled") !== "true") {
          if (
            document.querySelectorAll(
              ".line_notice[data-date='" + info.dateStr + "']"
            )[0] &&
            document.querySelectorAll(
              ".line_notice[data-date='" + info.dateStr + "']"
            )[0].previousElementSibling !== null
          ) {
            if (window.innerWidth > 768) {
              window.scrollTo(
                0,
                document
                  .querySelectorAll(
                    ".line_notice[data-date='" + info.dateStr + "']"
                  )[0]
                  .getBoundingClientRect().top +
                  window.pageYOffset -
                  200
              );
            } else {
              document
                .querySelectorAll(".chat_data_inner")[0]
                .scrollTo(
                  0,
                  document.querySelectorAll(
                    ".line_notice[data-date='" + info.dateStr + "']"
                  )[0].offsetTop - 150
                );
            }
            document.querySelectorAll(
              ".chat_search_popup_wrapper"
            )[0].style.display = "none";
          } else {
            fetch(
              `https://api.olapse.com/api/teamwork/message/search?id=${getParameterByName(
                "idx"
              )}&date=${info.dateStr}`,
              {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                let matched = 0;
                if (data.data.teamwork_message) {
                  const firstMsgId = data.data.teamwork_message.id;
                  searchId();
                  function searchId() {
                    document
                      .querySelectorAll(".chat_data_inner > div")
                      .forEach((item, idx) => {
                        if (firstMsgId == item.getAttribute("idx")) {
                          // window.scrollTo(0, item.getBoundingClientRect().top + window.pageYOffset - 200);
                          matched++;
                        }
                      });
                    setTimeout(() => {
                      if (matched > 0) {
                        if (window.innerWidth > 768) {
                          window.scrollTo(
                            0,
                            document
                              .querySelectorAll(
                                ".line_notice[data-date='" + info.dateStr + "']"
                              )[0]
                              .getBoundingClientRect().top +
                              window.pageYOffset -
                              200
                          );
                        } else {
                          document
                            .querySelectorAll(".chat_data_inner")[0]
                            .scrollTo(
                              0,
                              document.querySelectorAll(
                                ".line_notice[data-date='" + info.dateStr + "']"
                              )[0].offsetTop - 150
                            );
                        }
                        return false;
                      } else {
                        document.querySelectorAll(
                          ".rest_chat_data_button"
                        )[0] &&
                          document
                            .querySelectorAll(".rest_chat_data_button")[0]
                            .click();
                        setTimeout(() => {
                          searchId();
                        }, 500);
                      }
                    }, 500);
                  }
                } else {
                  createPopup({
                    type: "reTry",
                    title: "결과없음",
                    content: "일치하는 결과가 없습니다.",
                  });
                }
              });
            document.querySelectorAll(
              ".chat_search_popup_wrapper"
            )[0].style.display = "none";
          }
        }
      },
    });

    searchCalendar.setOption("locale", "ko");
    searchCalendar.render();
    document
      .querySelectorAll("div.memeber_price_wrapper div.date_picker_row")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".popup_wrapper_2_payment")[0].style.display =
          "flex";
        searchCalendar.render();
      });

    document
      .querySelectorAll(
        ".chat_search_popup_wrapper_box .fc-button-group button"
      )
      .forEach((item, idx) => {
        item.addEventListener("click", function () {
          const date = new Date(searchCalendar.getDate().toISOString());
          openSearchSelector(`${date.getFullYear()}-${date.getMonth() + 1}`);
        });
      });
    return () => {
      echo.connector.socket.close();
    };
  }, []);

  const backToTeamwork = () => {
    navigate("/chat", { replace: true });
    if (
      !window.matchMedia("(min-width: 768px)").matches &&
      getParameterByName("idx") == ""
    ) {
      document.querySelectorAll(".chat_wrapper")[0].style.display = "block";
      document.querySelectorAll(".left_menu_bar")[0].style.display = "block";
      document.querySelectorAll("div.chatting_area")[0].style.display = "none";
    }
    // if (window.matchMedia("(min-width: 767px)").matches) {
    //     navigate("/chat", { replace: true });
    // } else {
    //     window.location.href = "/chat";
    // }
  };

  const addScheduleTrigger = () => {
    //일정 등록 폼 클리어
    if (
      document.querySelectorAll(".team_work_title_1")[0].textContent ==
      "나와의 채팅"
    ) {
      document.querySelectorAll(".d_8 div.white_m_header span")[0].textContent =
        "개인 일정 등록";
    } else {
      document.querySelectorAll(".d_8 div.white_m_header span")[0].textContent =
        "팀워크 일정 등록";
    }

    document.querySelectorAll(".date_picker_container")[0].textContent = "일시";
    document.querySelectorAll(".date_picker_container")[1].textContent = "";
    document.querySelector("#content").value = "";
    document.querySelector("#location").value = "";
    document.querySelector("#phone_number").value = "";
    document.querySelector("#price").value = "";
    document.querySelectorAll(".date_picker_container")[1].style.display =
      "none";

    if (document.querySelectorAll(".d_8 .added_file")[0]) {
      document.querySelectorAll(".d_8 .added_file").forEach((item, idx) => {
        item.remove();
      });
    }
    if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
      _animateRightModule(true, document.querySelectorAll(".d_8")[0]);
    } else {
      createPopup({
        type: "confirm",
        title: "일정 생성 불가",
        content: "팀장, 부팀장만 생성 가능합니다.",
      });
    }
  };

  const searchDelValue = () => {
    document.querySelectorAll(
      ".chatting_area_show .search_input_wrapper input"
    )[0].value = "";
  };

  return (
    <>
      <div className="chatting_area chatting_area_show">
        <div className="chatting_header">
          <div className="top search_module">
            <div className="left">
              <div className="search_input_wrapper">
                <div className="inner_col">
                  <img
                    className="search_submit_button"
                    src="/assets/images/icon_search.svg"
                    alt=""
                  />
                  <input placeholder="검색" id="search_input" type="text" />
                </div>
                <img
                  onClick={() =>
                    openSearchSelector(
                      getToday().split("-")[0] + "-" + getToday().split("-")[1]
                    )
                  }
                  src="/assets/images/icon_cal.svg"
                  alt=""
                />
                {/* <p onClick={() => searchDelValue()} className="del_text" /> */}
              </div>
            </div>
            <span className="close_search_module">취소</span>
          </div>
          <div className="left">
            <img
              onClick={() => backToTeamwork()}
              className="teamwork_back"
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <div className="center mobile_element">
            <span className="team_work_title_1" />
          </div>
          <div className="right mobile_element chat_icon_wrapper">
            <img
              className="chat_search_button"
              src="/assets/images/icon_search.svg"
              alt=""
            />
            <img
              onClick={() =>
                _animateRightModule(
                  true,
                  document.querySelectorAll(".right_popup.d_1")[0]
                )
              }
              className="chat_menu_button"
              src="/assets/images/icon_ham.svg"
              alt=""
            />
            <img
              onClick={() => {
                createPopup({
                  type: "custom_select_2",
                  title: "개인일정 카드결제 연동",
                  content:
                    "<div class='myChatMenuPopupItem'><div class='create_col'><h2>개인 일정 카드 결제 받기</h2><span>사업자 인증 및 카드결제 연동 후 이용할 수 있습니다.</span></div><div class='toggle_button toggle_button_myPopup'><p /></div></div>",
                  button_text_1: "취소",
                  button_text_2: "저장",
                  firstButtonClickFunction: () => {
                    //저장
                    let formdata = new FormData();
                    formdata.append("teamwork_id", getParameterByName("idx"));
                    // formdata.append("users[]", teamworkUserId);
                    formdata.append(
                      "title",
                      document.querySelector("#modi_name").value
                    );

                    if (
                      document.querySelectorAll(
                        "div.create_wrapper_2 div.photo_area label input"
                      )[0].files[0]
                    ) {
                      formdata.append(
                        "image",
                        document.querySelectorAll(
                          "div.create_wrapper_2 div.photo_area label input"
                        )[0].files[0],
                        document.querySelectorAll(
                          "div.create_wrapper_2 div.photo_area label input"
                        )[0].files[0].name
                      );
                    }
                    formdata.append(
                      "use_card",
                      document
                        .querySelectorAll(".toggle_button_myPopup")[0]
                        .classList.contains("active")
                        ? "1"
                        : "0"
                    );
                    formdata.append(
                      "use_password",
                      document
                        .querySelectorAll(".toggle_button_2")[0]
                        .classList.contains("active")
                        ? "1"
                        : "0"
                    );
                    formdata.append(
                      "use_view_profile",
                      document
                        .querySelectorAll(".toggle_button_3")[0]
                        .classList.contains("active")
                        ? "1"
                        : "0"
                    );
                    if (
                      document
                        .querySelectorAll(".toggle_button_2")[0]
                        .classList.contains("active")
                    ) {
                      formdata.append(
                        "password",
                        document.querySelectorAll(".teamwork_password")[0].value
                      );
                    }

                    formdata.append(
                      "invite_auth",
                      document
                        .querySelectorAll("div.pers ul li.active")[0]
                        .getAttribute("idx")
                    );

                    fetch(`https://api.olapse.com/api/teamwork/info/modify`, {
                      method: "POST",
                      body: formdata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        setTimeout(() => {
                          if (data.success == true) {
                            createPopup({
                              type: "custom",
                              title: "수정완료",
                              content:
                                "개인일정 카드결제 연동을 수정하였습니다.",
                              custom: () => {
                                location.reload(true);
                              },
                            });
                          } else {
                            createPopup({
                              type: "reTry",
                              title: "수정실패",
                              content: data.alert,
                            });
                          }
                        }, 350);
                      });
                  },
                  secondButtonClickFunction: () => {},
                });

                fetch(
                  `https://api.olapse.com/api/teamwork/info?teamwork_id=${getParameterByName(
                    "idx"
                  )}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    data.data.teamwork.use_card == 1 &&
                      document
                        .querySelectorAll(".toggle_button_myPopup")[0]
                        .classList.add("active");
                  });

                document
                  .querySelectorAll(".toggle_button_myPopup")
                  .forEach((item, idx) => {
                    toggleClass(item, item, "active");
                  });

                document
                  .querySelectorAll(".toggle_button_myPopup")[0]
                  .addEventListener("click", function () {
                    var $this = this;
                    if (this.classList.contains("active")) {
                      fetch("https://api.olapse.com/api/mypage", {
                        method: "GET",
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (
                            data.data.user_business == null ||
                            data.data.user_business.status == 0
                          ) {
                            _animationDisappearBottomUp(
                              document.querySelectorAll(".popup_box")[0]
                            );
                            setTimeout(() => {
                              createPopup({
                                type: "reTry",
                                title: "인증 실패",
                                content:
                                  "사업자 인증 및 PG 사 연동 후 이용할 수 있습니다.",
                              });
                              $this.classList.remove("active");
                            }, 350);
                          }
                        });
                    }
                  });
              }}
              className="chat_myMenu_button"
              src="/assets/images/icon_ham.svg"
              alt=""
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div style={{ paddingTop: "4rem" }} className="chat_data_area">
          <div className="rest_chat_data_button">
            <h1>이전 대화를 불러오시겠습니까?</h1>
            <button>확인</button>
          </div>
          <div className="line_notice line_noticeStart">
            <span className="teamwork_start_date">
              팀워크 채팅을 시작해보세요.
            </span>
          </div>
          <div className="chat_data_inner"></div>
          <div className="chat_loading">
            <div className="load_item" />
            <span>
              채팅을 불러오는 중입니다.
              <br />
              잠시만 기다려 주세요.
            </span>
          </div>
        </div>
        <div className="chat_write">
          <ul className="left_button_list">
            <li onClick={() => addScheduleTrigger()} className="add" />
            <li className="plus" />
          </ul>
          <div className="write_input_wrapper">
            <textarea
              onKeyUp={(e) => {
                if (e.keyCode == 13 || e.charCode == 13) {
                  e.preventDefault();
                  sendMsgExcute(
                    document.querySelector("#msgSender").value,
                    props.teamworkId
                  );
                  document.querySelector("#msgSender").value = "";
                }
              }}
              onChange={(e) => {
                if (String(e.target.value).length >= 1) {
                  document
                    .querySelectorAll(".chat_submit")[0]
                    .classList.add("active");
                } else {
                  document
                    .querySelectorAll(".chat_submit")[0]
                    .classList.remove("active");
                }
              }}
              onFocus={() => {
                let varUAApp = navigator.userAgent;
                if (varUAApp.match("olapse/iOS") != null) {
                  document.querySelectorAll(
                    ".chat_data_inner"
                  )[0].style.maxHeight = VisualViewport.height - 235 + "px";
                }
              }}
              onBlur={() => {
                let varUAApp = navigator.userAgent;
                if (varUAApp.match("olapse/iOS") != null) {
                  document.querySelectorAll(
                    ".chat_data_inner"
                  )[0].style.maxHeight = "calc(100vh - 235px)";
                }
              }}
              id="msgSender"
              autoComplete="off"
              type="text"
              placeholder="메세지를 입력해주세요."
            />
            <button
              onClick={() =>
                sendMsgExcute(
                  document.querySelector("#msgSender").value,
                  props.teamworkId
                )
              }
              className="chat_submit"
            />
          </div>
        </div>
        <div className="chat_sub_write_menu">
          <ul>
            <li>
              <label>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .gif, .bmp, .png, .tif, .tiff, .tga, .psd, .ai, .mp4, .m4v, .avi, .asf, .wmv, .mkv, .ts, .mpg, .mpeg, .mov, .flv, .ogv, .mp3, .wav, .flac, .tta, .tak, .aac, .wma, .ogg, .m4a, .doc, .docx, .hwp, .txt, .rtf, .xml, .pdf, .wks, .wps, .xps, .md, .odf, .odt, .ods, .odp, .csv, .tsv, .xls, .xlsx, .ppt, .pptx, .pages, .key, .numbers, .show, .ce, .zip, .gz, .bz2, .rar, .7z, .lzh, .alz"
                />
                <p />
                <span>사진/파일</span>
              </label>
            </li>
            <li>
              <a className="write_notice">
                <p />
                <span>공지</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="search_navi_wrapper">
          <div>
            <img src="/assets/images/icon_search_bottom.svg" alt="" />
            <img src="/assets/images/icon_search_top.svg" alt="" />
            <span></span>
          </div>
        </div>
      </div>
      <TeamworkModule teamworkId={props.teamworkId} />

      {/* 일정 선택 팝업 */}
      <div className="popup_wrapper_2 popup_wrapper_2_payment">
        <div className="date_picker_box">
          <h1>일시 설정</h1>
          <div className="time_col">
            <div className="time_row start">
              <div className="col active">
                <span className="start_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
            <div className="time_row end">
              <div className="col">
                <span className="end_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="fullcal_wrapper" />
          <ul className="button_wrapper">
            <li className="hide_date_popup">취소</li>
            <li className="blue submit_date_popup">적용</li>
          </ul>
        </div>
        <div className="background_black" />
      </div>

      <div className="chat_search_popup_wrapper">
        <div className="chat_search_popup_wrapper_background_black"></div>
        <div className="chat_search_popup_wrapper_box">
          <h1>날짜 이동</h1>
          <div className="chat_search_popup_wrapper_cal"></div>
        </div>
      </div>
    </>
  );
};
export default ChatInner;

// 2022-02-24 인수인계
// 기능 : 데이터저장소 두 번째 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  numberWithCommas,
  toggleClass,
  getToday,
  getParameterByName,
} from "../shared/script";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import { webOpenBridge } from "./bridge";
const DataSave2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://api.olapse.com/api/data/store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (
          data.data.user_data_store &&
          data.data.user_data_store.status == 0
        ) {
          createPopup({
            type: "custom",
            title: "데이터 저장소",
            content: "이미 데이터저장소를 구독하고 있습니다.",
            custom: () => {
              navigate("/more/data_save_3");
            },
          });
        }
      });

    init();

    function getMonthV2(num) {
      var tDate = new Date(getToday());
      var date;
      if (String(tDate.getDate()).length == 1) {
        date = "0" + tDate.getDate();
      } else {
        date = tDate.getDate();
      }

      tDate.setMonth(tDate.getMonth() + num);
      if (String(tDate.getMonth()).length !== 1) {
        return tDate.getFullYear() + "-" + tDate.getMonth() + "-" + date;
      } else {
        return tDate.getFullYear() + "-" + "0" + tDate.getMonth() + "-" + date;
      }
    }

    function getDataSavePriceInfo() {
      fetch(`https://api.olapse.com/api/data/store/price`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          document
            .querySelectorAll(".data_save_box")[0]
            .insertAdjacentHTML(
              "beforeend",
              `<h2><span>${numberWithCommas(
                data.data.price
              )}원</span><span> / 월</span></h2>`
            );
          document.querySelectorAll(
            ".subs_date_1"
          )[0].textContent = `오늘부터 ~ ${
            getMonthV2(2).split("-")[1] == "00"
              ? getMonthV2(2).split("-")[0] -
                1 +
                "-" +
                "12-" +
                getMonthV2(2).split("-")[2]
              : getMonthV2(2)
          }`;
          document.querySelectorAll(".next_payment_date")[0].textContent =
            getMonthV2(3).split("-")[1] == "00"
              ? getMonthV2(3).split("-")[0] -
                1 +
                "-" +
                "12-" +
                getMonthV2(3).split("-")[2]
              : getMonthV2(3);
        });
    }

    function setCheckBox() {
      const trigger = document.querySelectorAll(
          "div.agree_wrapper ul li input"
        ),
        buttonEl = document.querySelectorAll("div.agree_wrapper button")[0];
      trigger.forEach((item, idx) => {
        item.addEventListener("input", function () {
          if (this.classList.contains("master")) {
            if (this.checked == true) {
              trigger.forEach((item, idx) => {
                item.checked = true;
                buttonEl.classList.add("active");
              });
            } else {
              trigger.forEach((item, idx) => {
                item.checked = false;
                buttonEl.classList.remove("active");
              });
            }
          } else {
            if (item.checked == false) {
              trigger[0].checked = false;
              buttonEl.classList.remove("active");
            }
            if (
              document.querySelectorAll("div.agree_wrapper ul li input:checked")
                .length == 3
            ) {
              trigger[0].checked = true;
              buttonEl.classList.add("active");
            }
          }
        });
      });
    }

    function openAgreeText() {
      const triggerEl = document.querySelectorAll("div.agree_wrapper ul li");
      triggerEl.forEach((item, idx) => {
        if (idx !== 0) {
          item.addEventListener("click", function (e) {
            if (e.target.tagName == "LI") {
              _agreePopupActive(
                document.querySelectorAll(".agree_popup_wrapper")[0],
                document.querySelectorAll(".agree_popup_wrapper")[0]
              );
            }
          });
        }
      });
      document
        .querySelectorAll(".agree_popup_wrapper button")[0]
        .addEventListener("click", function () {
          _agreePopupOut(
            document.querySelectorAll(".agree_popup_wrapper")[0],
            document.querySelectorAll(".agree_popup_wrapper")[0]
          );
        });
    }
    function nextV2() {
      const button = document.querySelectorAll("div.agree_wrapper button")[0];
      button.addEventListener("click", function () {
        let varUAApp = navigator.userAgent;
        if (
          varUAApp.match("olapse/iOS") != null ||
          varUAApp.match("olapse/ios") != null
        ) {
          window.webkit.messageHandlers.inAppPurchases.postMessage({});
        } else {
          if (varUAApp.match("olapse/Android") != null) {
            createPopup({
              type: "custom",
              title: "웹 결제 안내",
              content:
                "데이터 저장소 구독하기는 웹에서 가능합니다.<br>웹에서 마저 진행하시겠습니까?",
              custom: () => {
                webOpenBridge();
              },
            });
          } else {
            if (button.classList.contains("active")) {
              navigate("/more/subs");
            } else {
              createPopup({
                type: "reTry",
                title: "약관동의",
                content: "모든 필수 약관에 동의해주세요.",
              });
            }
          }
        }
      });
    }

    document
      .querySelectorAll(".open_info")[0]
      .addEventListener("click", function () {
        navigate("/more/data_save_1");
      });

    function init() {
      getDataSavePriceInfo();
      setCheckBox();
      toggleClass(
        document.querySelectorAll("div.agree_wrapper ul li")[0],
        document.querySelectorAll("div.agree_wrapper ul")[0],
        "active"
      );
      openAgreeText();
      nextV2();
    }
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="구독하기" isInfo="1" />
        <div className="data_save_2_wrapper">
          {/* 박스 */}
          <div className="data_save_box">
            <h1>데이터 저장소</h1>
            <ul>
              <li>
                <h1>구독기간</h1>
                <span className="subs_date_1" />
              </li>
              <li>
                <h1>다음결제일</h1>
                <span className="next_payment_date" />
              </li>
            </ul>
          </div>
          {/* 동의 */}
          <div style={{ marginTop: "4rem" }} className="agree_wrapper">
            <ul className="active">
              <li>
                <label>
                  <input className="master" type="checkbox" />
                  <span>전체 동의</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" />
                  <span>데이터 저장소 이용 동의 (필수)</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" />
                  <span>개인정보 수집 및 이용 동의 (필수)</span>
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" />
                  <span>월 정기결제 동의 (필수)</span>
                </label>
              </li>
            </ul>
            <button>결제하기</button>
          </div>
        </div>
        {/* footer */}
        <div className="data_save_footer">
          <h1>자동결제 해지 및 미납 안내</h1>
          <span>
            정기결제 취소 및 환불 정책
            <br />
            구독 해지하기
            <br />
            1. 더보기를 엽니다
            <br />
            2. 데이터저장소 메뉴를 선택합니다.
            <br />
            3. 구독해지를 클릭합니다.
            <br />
            4. 안내를 따릅니다.
            <br />
            회원이 해지를 요청하면 월간 결제 기간이 종료될 때까지는 서비스를
            계속 이용할 수 있습니다. 관련 법률이 허용하는 범위에서 결제 금액은
            환불되지 않으며 한 달 중 서비스를 이용하지 않은 기간에 대한 환불은
            제공하지 않습니다.
            <br />
            예를들어 1월1일에 데이터 구독서비스를 이용하고 1월 13일에
            구독서비스를 취소하기로 한경우 다음과 같이 처리 됩니다.
            <br />
            l 1월 30일까지 구독항목에 액세스를 할수 있습니다.
            <br />
            l 다음달 2월1일에는 월간 구독료가 청구되지 않습니다.
            <br />
            l 구독을 통해 이용할 수 있었던 데이터 저장소 및 엑셀 다운로드 기능,
            대화 불러오기 기능을 더 이상 액세스 할 수 없게 됩니다.
            <br />
            <br />
            정기 결제 재개 또는 재가입 하기
            <br />
            정기 결제가 취소된 상태에서 정기결제를 진행하게 되면 모든 일정과
            데이터에 액세스가 가능합니다.
            <br />
            단, "팀워크"에서 6개월동안 사용기록이 없으면 자동으로 모든 데이터가
            삭제되니 삭제가 되면 ""팀워크"" 내의 모든 데이터를 액세스 및 복구 할
            수가 없습니다.
            <br />
            예를 들면 1월 1일에 "팀워크"를 개설을 하고 2월1일부터 8월1일까지
            6개월 동안 "팀워크"내에 사용 기록이 없어 삭제가 된 상황에서 8월 1일
            데이터 저장소를 구매 한다 하더라도 삭제 시점부터의 이전 데이터는
            복구 되지 않습니다.
          </span>
        </div>
      </div>

      <div className="agree_popup_wrapper">
        <div className="agree_header">
          <h1>서비스 이용약관</h1>
        </div>
        <div className="agree_content">
          (약관 내용 수신이후 정렬)Olapse 회원 이용약관 제 1 조 (목적) 본 약관은
          주식회사 어디어디 (이하 "회사") 가 제공하는 유·무선 온라인상에서
          제공되는 olapse 서비스(이하 "서비스") 이용과 관련하여 회원과 회사 간의
          권리, 의무, 책임사항 및 회원의 서비스 이용조건 및 절차 등 기본적인
          사항을 규정함을 그 목적으로 합니다. 제 2 조 (약관의 효력 및 변경) 1.
          본 약관은 서비스를 이용하고자 하는 모든 회원에게 그 효력이 발생합니다.
          2. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 화면에
          게시하거 나 회원이 요구할 경우 약관의 사본을 회원에게 제공할 수
          있으며, 기타의 방법 으로 회사가 회원에게 공지합니다
        </div>
        <button>닫기</button>
      </div>
    </>
  );
};
export default DataSave2;

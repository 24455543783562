import React from "react";
import ReactDOM from "react-dom";
import Router from "./Routes";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>
        <Router />
    </BrowserRouter>,
    document.getElementById("root")
);

// 2022-02-24 인수인계
// 기능 : 사진/동영상 모아보기 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import TeamworkList from "./teamwork_list";
import { imgViewerFunction, imgViewerFunctionSetting } from "../shared/script";
import createPopup from "./createPopup";
const Photo = () => {
  const navigate = useNavigate();
  const imgTypeArr = ["jpg", "jpeg", "gif", "bmp", "png"];
  const videoArr = [
    "mp4",
    "m4v",
    "avi",
    "asf",
    "wmv",
    "mkv",
    "ts",
    "mpg",
    "mpeg",
    "mov",
    "flv",
    "ogv",
  ];
  let keyword = "";
  let url;
  let flow = 1;
  let pageNumber = 1;
  let lastNumber = 1;
  let photoStart;
  let chkNumber = 3,
    lastChk = false;

  // document.addEventListener('scroll', () => {
  //     if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
  //         if(pageNumber < lastNumber){
  //             pageNumber = pageNumber + 1;
  //             photoStart();
  //         }
  //     }
  // });

  const getPhotoList = (teamworkId, all) => {
    const appendTarget = document.querySelectorAll(".photo_wrapper")[0];
    appendTarget.querySelectorAll(".photo_row").forEach((item, idx) => {
      item.remove();
    });
    photoStart = () => {
      if (all == true) {
        url = `https://api.olapse.com/api/teamwork/all/picture?page=${flow}&teamwork_id$keyword=${keyword}`;
        inner(url);
      } else {
        url = `https://api.olapse.com/api/teamwork/all/picture?page=${flow}&teamwork_id=${teamworkId}&keyword=${keyword}`;
        inner(url);
      }
    };
    photoStart();

    function inner(url) {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            lastChk = flow == data.data.messages.last_page ? true : false;
            // lastNumber = data.data.messages.last_page;
            if (data.data.messages.data.length !== 0) {
              noData(false);
              data.data.messages.data.forEach((item, idx) => {
                let rowString;
                if (imgTypeArr.indexOf(item.file_extension) !== -1) {
                  rowString = `<div class="new_photo_row photo_row"> <span class="created_at_photo">${
                    item.created_at.split(" ")[0]
                  }</span> <ul><li class="new_photo_item" url="${
                    item.file_url
                  }" style="background:url(${
                    item.file_url
                  }) no-repeat"></li></ul> </div>`;
                  appendTarget.insertAdjacentHTML("beforeend", rowString);
                } else if (videoArr.indexOf(item.file_extension) !== -1) {
                  rowString = `<div class="photo_row"> <span class="created_at_photo">${
                    item.created_at.split(" ")[0]
                  }</span> <ul><li><video preload="metadata" controls><source src="${
                    item.file_url
                  }"type="video/${
                    item.file_extension
                  }"></video></li></ul> </div>`;
                  appendTarget.insertAdjacentHTML("beforeend", rowString);
                }
              });
              insert();
              function insert() {
                document.querySelectorAll(".photo_row").forEach((item, idx) => {
                  if (
                    item.nextElementSibling &&
                    item.querySelectorAll(".created_at_photo")[0].textContent ==
                      item.nextElementSibling.querySelectorAll(
                        ".created_at_photo"
                      )[0].textContent
                  ) {
                    item
                      .querySelectorAll("ul")[0]
                      .insertAdjacentElement(
                        "beforeend",
                        item.nextElementSibling
                          .querySelectorAll("li")[0]
                          .cloneNode(true)
                      );
                    item.nextElementSibling.remove();
                    item
                      .querySelectorAll(".new_photo_item")
                      .forEach((item, idx) => {
                        imgViewerFunction(
                          item,
                          item.getAttribute("url"),
                          "사진 모아보기",
                          item
                        );
                      });

                    insert();
                  }
                });
              }

              // if(pageNumber == 1 && pageNumber < lastNumber){
              //     pageNumber = pageNumber + 1;
              //     photoStart();
              // }
              if (flow < chkNumber && !lastChk) {
                flow++;
                photoStart();
              } else {
                chkNumber = flow + 2;
                document.querySelectorAll(
                  ".chat_loadingDate"
                )[0].style.display = "none";
              }
            } else {
              noData(true);
            }
          }
        });
    }

    //스크롤 하단 체크
    function bottomChk() {
      let scrollTop = document.documentElement.scrollTop,
        innerHeight = window.innerHeight,
        scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

      if (scrollTop + innerHeight >= scrollHeight) {
        return true;
      } else {
        return false;
      }
    }

    //추가 페이지 있을 시 추가 세팅
    function addPage() {
      if (bottomChk() && !lastChk && flow !== 1) {
        flow++;
        photoStart();
      }
    }
    document.addEventListener("scroll", function () {
      addPage();
    });
  };
  const photoClickEv = (target) => {
    if (!target.classList.contains("active")) {
      pageNumber = 1;
      lastNumber = 1;
      getPhotoList(target.getAttribute("idx"), false);
    }
  };
  const allEv = () => {
    if (
      !document
        .querySelectorAll(".teamword_list li.all")[0]
        .classList.contains("active")
    ) {
      pageNumber = 1;
      lastNumber = 1;
      getPhotoList(null, true);
    }
  };
  useEffect(() => {
    if (!getParameterByName("idx")) {
      pageNumber = 1;
      lastNumber = 1;
      getPhotoList(null, true);
    }
    imgViewerFunctionSetting();

    const searchModuleElement = document.querySelectorAll(
      "div.top.search_module"
    )[0];
    //닫기
    if (document.querySelectorAll(".close_search_module")[0]) {
      document
        .querySelectorAll(".close_search_module")[0]
        .addEventListener("click", function () {
          searchModuleElement.style.display = "none";
        });
    }

    //열기
    if (document.querySelectorAll(".open_search_module")[0]) {
      document
        .querySelectorAll(".open_search_module")[0]
        .addEventListener("click", function () {
          searchModuleElement.style.display = "flex";
          document
            .querySelectorAll(
              "div.top.search_module div.left div.search_input_wrapper input#search_input"
            )[0]
            .focus();
        });
    }
    if (
      document.querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper input#search_input"
      )[0]
    ) {
      document
        .querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper input#search_input"
        )[0]
        .addEventListener("keydown", function (e) {
          if (e.keyCode == 13) {
            searchModuleElement.style.display = "none";
            keyword = this.value;
            getPhotoList(null, true);
          }
        });
    }
    if (document.querySelectorAll(".search_submit_button")[0]) {
      document
        .querySelectorAll(".search_submit_button")[0]
        .addEventListener("click", function () {
          keyword = document.querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0].value;
          searchModuleElement.style.display = "none";
          getPhotoList(null, true);
        });
    }
    if (
      document.querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper p.del_text"
      )[0]
    ) {
      document
        .querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper p.del_text"
        )[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0].value = "";
        });
    }
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="사진/동영상 모아보기" isSearch={0} isOpenMenu={1} />
        <TeamworkList clickEv={photoClickEv} allEv={allEv} />
        <div className="photo_wrapper">
          <div className="no_data">
            <span>사진/동영상이 없습니다.</span>
          </div>
        </div>
      </div>
      {/* 이미지 확대보기(공용) */}
      <div className="img_viewer">
        <a
          className="img_viewer_downloader"
          style={{ display: "none" }}
          download
        />
        <div className="img_viewer_header">
          <img
            className="close_viewer"
            src="/assets/images/icon_close_type_1.svg"
            alt=""
          />
          <span>이름</span>
          <img
            className="download_img"
            src="/assets/images/icon_download.svg"
            alt=""
          />
        </div>
        <div className="slide_left" />
        <div className="slide_right" />
        <div className="img_viewer_bottom">
          <div className="size_controller">
            <img src="/assets/images/icon_small.svg" alt="" />
            <span>120%</span>
          </div>
          <ul>
            <li>
              <img src="/assets/images/icon_minus.svg" alt="" />
            </li>
            <li>
              <img src="/assets/images/icon_plus_w.svg" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Photo;

// 2022-02-24 인수인계
// 기능 : 내 정보 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";

const Myinfo = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch("https://api.olapse.com/api/mypage", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //프로필
        data.data.profile_url &&
          (document.querySelectorAll(
            "div.pc_right_area div.right_area_my_info p.right_area_my_info_profile"
          )[0].style.background = `url(${data.data.profile_url}) no-repeat`);
        //이름 , 상태메세지
        document.querySelectorAll(
          "div.pc_right_area div.right_area_my_info div.right_area_my_info_profile_col h1"
        )[0].textContent = data.data.name;
        var phoneForm = data.data.phone
          .replace(/(.{3})/, "$1-")
          .replace(/(.{8})/, "$1-");
        data.data.message &&
          (document.querySelectorAll(
            "div.pc_right_area div.right_area_my_info div.right_area_my_info_profile_col span"
          )[0].textContent = phoneForm);

        if (data.data.user_business && data.data.user_business.status == 1) {
          //승인완료
          document.querySelectorAll(".more_b_info_wrapper")[0].style.display =
            "flex";
          (document.querySelectorAll(".c_name")[0].textContent =
            data.data.user_business.name),
            (document.querySelectorAll(".ceo_name")[0].textContent =
              data.data.user_business.ceo_name),
            (document.querySelectorAll(".b_number")[0].textContent =
              data.data.user_business.business_number),
            (document.querySelectorAll(".b_address")[0].textContent =
              data.data.user_business.address);
        } else if (
          data.data.user_business &&
          data.data.user_business.status == 0
        ) {
          //변동
          document.querySelectorAll("div.b_in_progress")[0].style.display =
            "flex";
        }
      });

    fetch(`https://api.olapse.com/api/business/result`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_business && data.data.user_business.status == 0) {
          //심사중
          document.querySelectorAll("div.b_in_progress")[0].style.display =
            "flex";
        } else if (
          data.data.user_business &&
          data.data.user_business.status == 2
        ) {
          //반려
          document.querySelectorAll(
            ".right_b_info_wrapper_rejected"
          )[0].style.display = "flex";
          document.querySelectorAll(
            ".right_b_info_wrapper_rejected span"
          )[0].textContent =
            "반려사유 : " + data.data.user_business.reject_reason;
        } else if (!data.data.user_business) {
          //신청 전
          document.querySelectorAll(
            ".right_b_info_wrapper_none"
          )[0].style.display = "flex";
        }
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="내 정보" />
        <div className="right_area_my_info">
          <div
            onClick={() => navigate("/more/edit_info")}
            className="right_area_my_info_wrapper"
          >
            <p className="right_area_my_info_profile" />
            <div className="right_area_my_info_profile_col">
              <h1 />
              <span />
            </div>
          </div>

          <div className="right_b_info_wrapper">
            <div className="right_b_info_wrapper_none">
              <h1>등록된 사업자 정보가 없습니다.</h1>
              <a onClick={() => navigate("/more/submit_b")}>
                사업자 인증 및 카드결제 연동
              </a>
            </div>
            <div className="right_b_info_wrapper_rejected">
              <h1>사업자 등록이 반려되었습니다.</h1>
              <span></span>
              <a onClick={() => navigate("/more/submit_b")}>사업자 재인증</a>
            </div>

            <div className="b_in_progress">
              <img src="/assets/images/icon_progress.svg" alt="" />
              <span>
                사업자 인증 및 카드결제 연동이
                <br />
                신청되어 검수중에 있습니다.
              </span>
            </div>

            <div style={{ display: "none" }} className="more_b_info_wrapper">
              <ul>
                <li>
                  <h1>사업자 정보</h1>
                  <button
                    onClick={() => {
                      createPopup({
                        type: "custom_select_2",
                        title: "사업자 정보 변경",
                        content:
                          "사업자번호 또는 대표자가 변경될 경우<br>승인 대기기간동안 카드결제 연동을 이용할 수 없습니다.<br>사업자 정보를 변경하시겠습니까?",
                        button_text_1: "취소",
                        button_text_2: "확인",
                        firstButtonClickFunction: () => {
                          navigate("/more/submit_b");
                        },
                        secondButtonClickFunction: () => {},
                      });
                    }}
                  >
                    변경
                  </button>
                </li>
                <li>
                  <h1>상호명/대표자명</h1>
                  <h1>
                    <span className="c_name" />
                    <span className="ceo_name" />
                  </h1>
                </li>
                <li>
                  <h1>사업자 번호</h1>
                  <span className="b_number" />
                </li>
                <li>
                  <h1>사업장 주소</h1>
                  <span className="b_address" />
                </li>
              </ul>
            </div>
          </div>

          <div className="right_cal_list">
            <h1>일정 내역</h1>
            <ul>
              <li onClick={() => navigate("/more/schedule_list?isReq=0")}>
                <p />
                <span>보낸일정</span>
              </li>
              <li onClick={() => navigate("/more/schedule_list?isReq=1")}>
                <p />
                <span>받은일정</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Myinfo;

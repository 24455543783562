// 2022-02-24 인수인계
// 기능 : 비밀번호 찾기 스탭1 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import createPopup from "../components/createPopup";
import { inputChanger } from "../shared/script";

const FindPassFirst = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inputChanger();
    let timeout, step1, savedPhoneNumber, phonePass;

    const phoneTrigger = document.querySelectorAll(".submit_number")[0];
    phoneTrigger.addEventListener("click", function () {
      phonePass = false;
      savedPhoneNumber = document.querySelector("#phone_number").value;
      step1 = false;
      if (passPhone(document.querySelector("#phone_number").value)) {
        getNumber(savedPhoneNumber);
      } else {
        createPopup({
          type: "reTry",
          title: "인증요청 실패",
          content: "휴대폰 번호를 다시 확인해주세요.",
        });
      }
    });

    document
      .querySelectorAll(".next_button")[0]
      .addEventListener("click", function () {
        if (step1 == true) {
          confirmNumber(
            savedPhoneNumber,
            document.querySelector("#code").value
          );
        } else {
          createPopup({
            type: "confirm",
            title: "인증 실패",
            content: "휴대폰 번호로 인증코드를 발송해주세요.",
          });
        }
      });

    //인증번호 확인
    function confirmNumber(phone, code) {
      if (timeout == true) {
        createPopup({
          type: "confirm",
          title: "인증 시간 초과",
          content:
            "입력 가능 시간을 초과하였습니다. <br /> 재발송 후 다시 인증을 진행해주세요.",
        });
      } else {
        if (
          String(document.querySelector("#name").value).length !== 0 &&
          String(document.querySelector("#id").value).length !== 0
        ) {
          findPass(document.querySelector("#name").value, phone, code);
        } else if (String(document.querySelector("#name").value).length == 0) {
          createPopup({
            type: "reTry",
            title: "확인 실패",
            content: "회원가입시 등록한 이름을 입력해주세요.",
          });
        } else if (String(document.querySelector("#id").value).length == 0) {
          createPopup({
            type: "reTry",
            title: "확인 실패",
            content: "회원가입시 등록한 아이디를 입력해주세요.",
          });
        }
      }
    }

    //휴대폰 번호 유효성
    function passPhone(phoneNumber) {
      var patternPhone =
        /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
      if (!patternPhone.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }
    }
    //휴대폰 인증 요청
    function getNumber(phoneNumber) {
      let formdata = new FormData();
      formdata.append("phone", phoneNumber);
      formdata.append("is_join", 0);
      fetch("https://api.olapse.com/api/sms/certification", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          locale: "ko",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "confirm",
              title: "인증요청 성공",
              content: "입력하신 번호로 인증번호를 발송하였습니다.",
            });
            timeout = false;
            initTimer();
            step1 = true;
            phoneTrigger.textContent = "재발송";
          } else {
            createPopup({
              type: "reTry",
              title: "조회불가",
              content: "입력하신 정보로 조회되는 아이디가 없습니다.",
            });
          }
        });
    }

    //타이머
    function paddedFormat(num) {
      return num < 10 ? "0" + num : num;
    }

    function startCountDown(duration, element) {
      let secondsRemaining = duration;
      let min = 0;
      let sec = 0;

      let countInterval = setInterval(function () {
        min = parseInt(secondsRemaining / 60);
        sec = parseInt(secondsRemaining % 60);

        element.textContent = `${paddedFormat(min)}:${paddedFormat(sec)}`;

        secondsRemaining = secondsRemaining - 1;
        if (secondsRemaining < 0) {
          clearInterval(countInterval);
          timeout = true;
        }
      }, 1000);
    }

    function initTimer() {
      let time_minutes = 3;
      let time_seconds = 0;

      let duration = time_minutes * 60 + time_seconds;

      let element = document.querySelector("#count-down-timer");
      element.style.display = "block";
      element.textContent = `${paddedFormat(time_minutes)}:${paddedFormat(
        time_seconds
      )}`;

      startCountDown(--duration, element);
    }

    function findPass(name, phone, code) {
      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("phone", phone);
      formdata.append("certification_code", code);
      formdata.append("login_id", document.querySelector("#id").value);
      fetch("https://api.olapse.com/api/find/password", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            navigate(`/find_pass_2?token=${data.data.token}`);
          } else {
            createPopup({
              type: "reTry",
              title: "비밀번호 찾기 실패",
              content: "입력하신 정보를 다시 확인해주세요.",
            });
          }
        })
        .catch((e) => {
          createPopup({
            type: "reTry",
            title: "비밀번호 찾기 실패",
            content: "입력하신 정보를 다시 확인해주세요.",
          });
        });
    }
  }, []);

  return (
    <>
      <div id="wrap">
        <UserHeader />
        <div className="user_wrapper">
          <h1 className="page_title">비밀번호 찾기</h1>
          <div className="input_wrapper">
            <div className="input_row">
              <h1>아이디</h1>
              <div className="input_outter">
                <input id="id" placeholder="아이디" type="text" />
              </div>
              <h1 className="mt_15">이름</h1>
              <div className="input_outter">
                <input id="name" placeholder="이름" type="text" />
              </div>
              <h1 className="mt_15">휴대폰 인증</h1>
              <div className="input_outter">
                <input
                  id="phone_number"
                  placeholder="휴대폰 번호"
                  type="number"
                />
                <p className="submit_number">전송</p>
              </div>
              <div className="input_outter">
                <input id="code" placeholder="인증 번호" type="number" />
                <p id="count-down-timer" className="red">
                  03:00
                </p>
              </div>
            </div>
          </div>
          <button className="button_type_1 next_button">인증</button>
        </div>
      </div>
    </>
  );
};

export default FindPassFirst;

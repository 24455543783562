// 2022-02-24 인수인계
// 기능 : 비밀번호 찾기 스탭2 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import createPopup from "../components/createPopup";
import { getParameterByName, inputChanger } from "../shared/script.js";

const FindPassFirst = () => {
  const navigate = useNavigate();

  let passwordRef =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

  const samePassword = () => {
    const originEl = document.querySelector("#password"),
      copyEl = document.querySelector("#password_confirm");
    if (originEl.value == copyEl.value) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    inputChanger();
  }, []);

  const end = () => {
    if (
      samePassword() &&
      passwordRef.test(document.querySelector("#password").value)
    ) {
      submitAccount();
    } else if (!passwordRef.test(document.querySelector("#password").value)) {
      createPopup({
        type: "reTry",
        title: "비밀번호 재확인",
        content:
          "비밀번호는 영문,숫자,특수문자를 포함한 <br /> 8~20자리로 입력해주세요.",
      });
    } else if (!samePassword()) {
      createPopup({
        type: "reTry",
        title: "비밀번호 불일치",
        content: "비밀번호가 일치하지 않습니다.",
      });
    }
  };

  const submitAccount = () => {
    let formdata = new FormData();
    formdata.append("password", document.querySelector("#password").value);
    fetch("https://api.olapse.com/api/find/password/change", {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + getParameterByName("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          createPopup({
            type: "custom",
            title: "비밀번호 변경 완료",
            content: `비밀번호가 성공적으로 변경되었습니다.<br> 새 비밀번호로 로그인해주세요.`,
            button_text_1: "로그인",
            custom: () => {
              localStorage.removeItem("token");
              localStorage.removeItem("refresh_token");
              navigate("/login");
            },
          });
        }
      });
  };

  return (
    <>
      <div id="wrap">
        <UserHeader />
        <div className="user_wrapper">
          <div style={{ marginBottom: 100 }} className="page_desc">
            <h2>비밀번호 재설정</h2>
            <span>새로운 비밀번호를 설정해주세요.</span>
          </div>
          <div className="input_wrapper">
            <div className="input_row">
              <h1>새 비밀번호</h1>
              <div className="input_outter">
                <input
                  id="password"
                  placeholder="비밀번호 (영문+숫자+특수문자 8~20자리)"
                  type="password"
                />
              </div>
              <div className="input_outter">
                <input
                  id="password_confirm"
                  placeholder="비밀번호 확인"
                  type="password"
                />
              </div>
            </div>
          </div>
          <button className="button_type_3" onClick={() => end()}>
            완료
          </button>
        </div>
      </div>
    </>
  );
};

export default FindPassFirst;

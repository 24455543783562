// 2022-02-24 인수인계
// 기능 : 더보기페이지입니다.

import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {getParameterByName, toggleClass, IsLogin, inputChanger} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import MemberList from "../components/memberList";
import MoreMenu from "../components/moreMenu";
import Basic from "../components/basic";
import Myinfo from "../components/myInfo";
import CalList from "../components/cal_list";
import Setting from "../components/setting";
import CustomerCenter from "../components/customer_center_1";
import CustomerCenterWrite from "../components/customer_center_2";
import FaqDetail from "../components/faq_detail";
import NoticeList from "../components/notice";
import NoticeDetail from "../components/notice_detail";
import Alarm from "../components/alarm";
import Photo from "../components/photo";
import File from "../components/file";
import DateGather from "../components/date";
import EditInfo from "../components/edit_info";
import DataSave1 from "../components/data_save_1";
import DataSave2 from "../components/data_save_2";
import DataSave3 from "../components/data_save_3";
import Subs from "../components/data_save_subs";
import Submitb from "../components/submit_b";
import Card from "../components/card";
import PaymentDetail from "../components/payment_detail";
import PaymentDetail2 from "../components/payment_detail_2";
import Confirm from "../components/confirm";
import {bridge, colorBridgeGray} from "../components/bridge";
const More = () => {
    const pathName = window.location.pathname;
    let rightComp;
    if (pathName == "/more") {
        bridge();
        colorBridgeGray();
        rightComp = <Basic />;
    } else if (pathName == "/more/my_info") {
        rightComp = <Myinfo />;
    } else if (pathName == "/more/schedule_list") {
        rightComp = <CalList />;
    } else if (pathName == "/more/setting") {
        rightComp = <Setting />;
    } else if (pathName == "/more/customer_center/0") {
        rightComp = <CustomerCenter />;
    } else if (pathName == "/more/customer_center/1") {
        rightComp = <CustomerCenterWrite />;
    } else if (pathName == "/more/faq_detail") {
        rightComp = <FaqDetail />;
    } else if (pathName == "/more/notice") {
        rightComp = <NoticeList />;
    } else if (pathName == "/more/notice_detail") {
        rightComp = <NoticeDetail />;
    } else if (pathName == "/more/alarm") {
        rightComp = <Alarm />;
    } else if (pathName == "/more/photo") {
        rightComp = <Photo />;
    } else if (pathName == "/more/file") {
        rightComp = <File />;
    } else if (pathName == "/more/date") {
        rightComp = <DateGather />;
    } else if (pathName == "/more/edit_info") {
        rightComp = <EditInfo />;
    } else if (pathName == "/more/data_save_1") {
        rightComp = <DataSave1 />;
    } else if (pathName == "/more/data_save_2") {
        rightComp = <DataSave2 />;
    } else if (pathName == "/more/data_save_3") {
        rightComp = <DataSave3 />;
    } else if (pathName == "/more/subs") {
        rightComp = <Subs />;
    } else if (pathName == "/more/submit_b") {
        rightComp = <Submitb />;
    } else if (pathName == "/more/card") {
        rightComp = <Card />;
    } else if (pathName == "/more/payment_detail") {
        rightComp = <PaymentDetail />;
    } else if (pathName == "/more/payment_detail_2") {
        rightComp = <PaymentDetail2 />;
    } else if (pathName == "/more/confirm") {
        rightComp = <Confirm />;
    }
    useEffect(() => {
        inputChanger();
    }, []);

    return (
        <>
            <div className="wrap morePage">
                <MainMenu />
                <MemberList />
                <MoreMenu />
                {rightComp}
            </div>
        </>
    );
};

export default More;

// 2022-02-24 인수인계
// 기능 : 채팅페이지의 (/chat) 팀워크 리스트입니다.

import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import {
  noData,
  getParameterByName,
  filteringBtns,
  timeForToday,
  fetcherWithToken,
} from "../shared/script";
import SearchModuleDom2 from "../components/search_module_2";
import initSearchModule from "../shared/search";
import anime from "animejs";
import useSWR from "swr";
import axios from "axios";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import createPopup from "./createPopup";
import { readLastMsg } from "../shared/chat";
import { myPermission } from "../shared/chat.js";

const TeamworkList = () => {
  const navigate = useNavigate();
  // 팀워크 리스트 가져옴
  useEffect(() => {
    const saveMyTeamWorkId = (index) => {
      localStorage.setItem("chat_with_me_id", index);
    };

    const changePathname = (idx, e, item) => {
      if (
        !e.target.parentNode.classList.contains("teamwork_list_l_p_menu") &&
        !e.target.classList.contains("teamwork_list_l_p_menu")
      ) {
        // if(item.last_message_id !== null){
        //     readLastMsg(item.last_message_id, idx);
        // }
        if (!window.matchMedia("(min-width: 768px)").matches) {
          document.querySelectorAll(".chat_wrapper")[0].style.display = "none";
          document.querySelectorAll(".left_menu_bar")[0].style.display = "none";
        } else {
          navigate(`/chat`);
        }

        setTimeout(() => {
          navigate(`/chat?idx=${idx}`);
          myPermission();
          if (!window.matchMedia("(min-width: 768px)").matches) {
            setTimeout(() => {
              document.querySelectorAll(".chatting_area")[0].style.display =
                "block";
            }, 100);
          }
        }, 1);
        // if (window.matchMedia("(min-width: 768px)").matches) {
        //     setTimeout(() => {
        //         window.location.href = `/chat?idx=${idx}`;
        //         // myPermission();
        //     }, 500);
        // } else {
        //     setTimeout(() => {
        //         window.location.href = `/chat?idx=${idx}`;
        //         // myPermission();
        //     }, 500);
        // }
      }
    };

    const timeBefore = (item) => {
      if (item.last_created_at) {
        let dateValueString = item.last_created_at
          .replace(/-/g, ",")
          .replace(/:/g, ",")
          .replace(/ /g, ",");
        return timeForToday(
          dateValueString.split(",")[0],
          dateValueString.split(",")[1],
          dateValueString.split(",")[2],
          dateValueString.split(",")[3],
          dateValueString.split(",")[4],
          dateValueString.split(",")[5]
        );
      } else {
        return "";
      }
    };

    const tapHold = (data) => {
      if (document.querySelectorAll(".chat_list")[0]) {
        document.querySelectorAll(".chat_list")[0].innerHTML = "";
      }
      data?.data?.teamworks?.forEach((item, idx) => {
        let listBox = document.querySelectorAll(".chat_list")[0],
          listItem = `<li key=${idx} total="${
            item.total_user_count
          }" isalarm="${item.use_alarm}" isfixed="${
            item.is_fixed
          }" isfixedid="${
            item.teamwork_user_id
          }" style="opacity: 1" data-long-press-delay="750" class="chat_list_item ${
            item.use_view_profile == 1 && "disabled_chat"
          }" idx="${item.id}">
                    <div class="chat_list_col">
                        <p style="background: url(${
                          item.image_url
                            ? item.image_url
                            : "/assets/images/icon_teamwork_basic.svg"
                        })" class="chat_img"/>
                        <div class="chat_text_row">
                            <div class="team_work_title">
                                <h1>${item.title}</h1> <span>${
            item.total_user_count
          }</span>
                                <img style="display:${
                                  item.is_fixed == 0 ? "none" : "block"
                                }" src="/assets/images/icon_pin.svg" alt=""/>
                                <img style="display:${
                                  item.use_alarm == 1 ? "none" : "block"
                                }" src="/assets/images/icon_no_alarm.svg" alt=""/>
                            </div>
                            <div class="chat_thumb_text">
                                <span style="display:${
                                  String(item.message).length !== 0
                                    ? "block"
                                    : "none"
                                }">
                                    ${
                                      String(item.message).length !== 0
                                        ? item.message
                                        : ""
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="chat_list_col">
                        <span>${timeBefore(item)}</span>
                        <p style="display:${
                          (location.pathname == "/chat" &&
                            getParameterByName("idx") &&
                            getParameterByName("idx") == item.id) ||
                          item.unread_message_count == 0
                            ? "none"
                            : "flex"
                        }" class="chat_msg_count">
                            ${
                              item.unread_message_count > 99
                                ? "99+"
                                : item.unread_message_count
                            }
                        </p>
                    </div>
                </li>`;
        listBox.insertAdjacentHTML("beforeend", listItem);

        if (item.is_chat_with_me == 1) {
          saveMyTeamWorkId(item.id);
        }
        document
          .querySelectorAll(".chat_list_item")
          [idx]?.addEventListener("click", function (e) {
            changePathname(item.id, e, item);
          });

        document
          .querySelectorAll(".chat_list_item")
          [idx]?.addEventListener("long-press", function (e) {
            if (!window.matchMedia("(max-width: 768px)").matches) {
              e.preventDefault();
            }
            const targetElement = this;
            const teamworkTitle = this.querySelectorAll(".team_work_title img");
            if (item.is_chat_with_me == 1) {
              //나와의 채팅
              const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="fix_top new_fix_top">${
                this.getAttribute("isFixed") == 0
                  ? "상단에 고정"
                  : "상단에 고정 해제"
              }</li></ul>`;
              this.insertAdjacentHTML("beforeend", htmlString);
              //상단 고정
              document
                .querySelectorAll(".new_fix_top")[0]
                .addEventListener("click", function (e) {
                  e.preventDefault();
                  let formdata = new FormData(),
                    selteamworkId = item.teamwork_user_id;

                  function fixedTeam(fixeddata) {
                    fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                      method: "POST",
                      body: fixeddata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        if (data.success == true) {
                          window.location.reload(true);
                        } else {
                          createPopup({
                            type: "confirm",
                            title: "고정 불가",
                            content: "채팅방은 3개까지 고정할 수 있습니다.",
                          });
                        }
                      });
                  }
                  if (
                    document.querySelectorAll(
                      ".chat_list_item[isfixed='1']"
                    )[2] &&
                    this.parentNode.parentNode.getAttribute("isfixed") == 0
                  ) {
                    formdata.append(
                      "teamwork_user_id",
                      document
                        .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                        .getAttribute("isfixedid")
                    );
                    fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                      method: "POST",
                      body: formdata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        if (data.success == true) {
                          let fixeddata = new FormData();
                          fixeddata.append("teamwork_user_id", selteamworkId);
                          fixedTeam(fixeddata);
                        } else {
                          createPopup({
                            type: "confirm",
                            title: "고정 불가",
                            content: data.alert,
                          });
                        }
                      });
                  } else {
                    formdata.append("teamwork_user_id", selteamworkId);
                    fixedTeam(formdata);
                  }
                });
              document
                .querySelectorAll(".sub_background_black")[0]
                .addEventListener("click", function () {
                  document
                    .querySelectorAll(".teamwork_list_l_p_menu")[0]
                    .remove();
                  this.remove();
                });

              document
                .querySelectorAll(".new_fix_top")[0]
                .classList.remove("new_fix_top");
            } else {
              if (item.is_leader == 1) {
                //팀장
                const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="sub_toggle_alarm new_sub_toggle_alarm">${
                  this.getAttribute("isAlarm") == 0 ? "알림 켜기" : "알림 끄기"
                }</li><li class="fix_top new_fix_top">${
                  this.getAttribute("isFixed") == 0
                    ? "상단에 고정"
                    : "상단에 고정 해제"
                }</li><li class="remove_teamwork red">팀워크 삭제</li></ul>`;
                this.insertAdjacentHTML("beforeend", htmlString);
                //알림토글
                document
                  .querySelectorAll(".new_sub_toggle_alarm")[0]
                  .addEventListener("click", function () {
                    let formdata = new FormData();
                    formdata.append("teamwork_user_id", item.teamwork_user_id);
                    fetch(`https://api.olapse.com/api/teamwork/alarm`, {
                      method: "POST",
                      body: formdata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        if (data.data.type == 1) {
                          teamworkTitle[1].style.display = "none";
                        } else {
                          teamworkTitle[1].style.display = "block";
                        }
                        targetElement.setAttribute("isAlarm", data.data.type);
                        document
                          .querySelectorAll(".teamwork_list_l_p_menu")[0]
                          .remove();
                        document
                          .querySelectorAll(".sub_background_black")[0]
                          .remove();
                      });
                  });
                //상단 고정
                document
                  .querySelectorAll(".new_fix_top")[0]
                  .addEventListener("click", function (e) {
                    e.preventDefault();
                    let formdata = new FormData(),
                      selteamworkId = item.teamwork_user_id;

                    function fixedTeam(fixeddata) {
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: fixeddata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            window.location.reload(true);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: "채팅방은 3개까지 고정할 수 있습니다.",
                            });
                          }
                        });
                    }
                    if (
                      document.querySelectorAll(
                        ".chat_list_item[isfixed='1']"
                      )[2] &&
                      this.parentNode.parentNode.getAttribute("isfixed") == 0
                    ) {
                      formdata.append(
                        "teamwork_user_id",
                        document
                          .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                          .getAttribute("isfixedid")
                      );
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: formdata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            let fixeddata = new FormData();
                            fixeddata.append("teamwork_user_id", selteamworkId);
                            fixedTeam(fixeddata);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: data.alert,
                            });
                          }
                        });
                    } else {
                      formdata.append("teamwork_user_id", selteamworkId);
                      fixedTeam(formdata);
                    }
                  });
                document
                  .querySelectorAll(".remove_teamwork")[0]
                  .addEventListener("click", function () {
                    createPopup({
                      type: "custom_select_2",
                      title: `${item.title} 팀워크를 삭제 하시겠습니까?`,
                      content: "팀워크를 삭제하면 모든 데이터가 삭제됩니다.",
                      button_text_1: "취소",
                      button_text_2: "삭제",
                      firstButtonClickFunction: () => {
                        //팀워크 지우기
                        let formdata = new FormData();
                        formdata.append(
                          "teamwork_user_id",
                          item.teamwork_user_id
                        );
                        fetch(`https://api.olapse.com/api/teamwork/delete`, {
                          method: "POST",
                          body: formdata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            setTimeout(() => {
                              if (data.success == true) {
                                createPopup({
                                  type: "custom",
                                  title: "팀워크 삭제",
                                  content: "팀워크를 삭제하였습니다.",
                                  custom: () => {
                                    window.location.href = "/chat";
                                  },
                                });
                              } else {
                                createPopup({
                                  type: "reTry",
                                  title: "팀워크 삭제 실패",
                                  content: data.alert,
                                });
                              }
                            }, 500);
                          });
                      },
                      secondButtonClickFunction: () => {
                        document
                          .querySelectorAll(".teamwork_list_l_p_menu")[0]
                          .remove();
                        document
                          .querySelectorAll(".sub_background_black")[0]
                          .remove();
                      },
                    });
                    document.querySelectorAll(
                      "div.popup_wrapper div.popup_box button.button_right"
                    )[0].style.color = "#ff5555";
                    document.querySelectorAll(
                      "div.popup_wrapper div.popup_box div.popup_text_wrapper h1"
                    )[0].style.textAlign = "center";
                  });

                document
                  .querySelectorAll(".sub_background_black")[0]
                  .addEventListener("click", function () {
                    document
                      .querySelectorAll(".teamwork_list_l_p_menu")[0]
                      .remove();
                    this.remove();
                  });
              } else {
                //일반 팀원
                //팀장
                const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="sub_toggle_alarm new_sub_toggle_alarm">${
                  this.getAttribute("isAlarm") == 0 ? "알림 켜기" : "알림 끄기"
                }</li><li class="fix_top new_fix_top">${
                  this.getAttribute("isFixed") == 0
                    ? "상단에 고정"
                    : "상단에 고정 해제"
                }</li><li class="remove_teamwork">팀워크 나가기</li></ul>`;
                this.insertAdjacentHTML("beforeend", htmlString);
                //알림토글
                document
                  .querySelectorAll(".new_sub_toggle_alarm")[0]
                  .addEventListener("click", function () {
                    let formdata = new FormData();
                    formdata.append("teamwork_user_id", item.teamwork_user_id);
                    fetch(`https://api.olapse.com/api/teamwork/alarm`, {
                      method: "POST",
                      body: formdata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (data) {
                        if (data.data.type == 1) {
                          teamworkTitle[1].style.display = "none";
                        } else {
                          teamworkTitle[1].style.display = "block";
                        }
                        targetElement.setAttribute("isAlarm", data.data.type);
                        document
                          .querySelectorAll(".teamwork_list_l_p_menu")[0]
                          .remove();
                        document
                          .querySelectorAll(".sub_background_black")[0]
                          .remove();
                      });
                  });
                //상단 고정
                document
                  .querySelectorAll(".new_fix_top")[0]
                  .addEventListener("click", function (e) {
                    e.preventDefault();
                    let formdata = new FormData(),
                      selteamworkId = item.teamwork_user_id;

                    function fixedTeam(fixeddata) {
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: fixeddata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            window.location.reload(true);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: "채팅방은 3개까지 고정할 수 있습니다.",
                            });
                          }
                        });
                    }
                    if (
                      document.querySelectorAll(
                        ".chat_list_item[isfixed='1']"
                      )[2] &&
                      this.parentNode.parentNode.getAttribute("isfixed") == 0
                    ) {
                      formdata.append(
                        "teamwork_user_id",
                        document
                          .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                          .getAttribute("isfixedid")
                      );
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: formdata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            let fixeddata = new FormData();
                            fixeddata.append("teamwork_user_id", selteamworkId);
                            fixedTeam(fixeddata);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: data.alert,
                            });
                          }
                        });
                    } else {
                      formdata.append("teamwork_user_id", selteamworkId);
                      fixedTeam(formdata);
                    }
                  });
                document
                  .querySelectorAll(".remove_teamwork")[0]
                  .addEventListener("click", function () {
                    createPopup({
                      type: "custom_select_2",
                      title: `${item.title} 팀워크를 나가시겠습니까?`,
                      content: "팀워크를 나가면 모든 데이터가 삭제됩니다.",
                      button_text_1: "취소",
                      button_text_2: "삭제",
                      firstButtonClickFunction: () => {
                        //팀워크 나가기
                        let formdata = new FormData();
                        formdata.append(
                          "teamwork_user_id",
                          item.teamwork_user_id
                        );
                        fetch(`https://api.olapse.com/api/teamwork/leave`, {
                          method: "POST",
                          body: formdata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            setTimeout(() => {
                              if (data.success == true) {
                                createPopup({
                                  type: "custom",
                                  title: "팀워크 나가기",
                                  content: "팀워크를 나갔습니다.",
                                  custom: () => {
                                    window.location.reload(true);
                                  },
                                });
                              } else {
                                createPopup({
                                  type: "reTry",
                                  title: "팀워크 나가기 실패",
                                  content: data.alert,
                                });
                              }
                            }, 500);
                          });
                      },
                      secondButtonClickFunction: () => {
                        document
                          .querySelectorAll(".teamwork_list_l_p_menu")[0]
                          .remove();
                        document
                          .querySelectorAll(".sub_background_black")[0]
                          .remove();
                      },
                    });
                    document.querySelectorAll(
                      "div.popup_wrapper div.popup_box button.button_right"
                    )[0].style.color = "#ff5555";
                  });

                document
                  .querySelectorAll(".sub_background_black")[0]
                  .addEventListener("click", function () {
                    document
                      .querySelectorAll(".teamwork_list_l_p_menu")[0]
                      .remove();
                    this.remove();
                  });
              }
            }
          });

        document
          .querySelectorAll(".chat_list_item")
          [idx]?.addEventListener("mousedown", function (e) {
            if (e.button == 2 || e.which == 3) {
              if (!window.matchMedia("(max-width: 768px)").matches) {
                e.preventDefault();
              }
              const targetElement = this;
              const teamworkTitle = this.querySelectorAll(
                ".team_work_title img"
              );
              if (item.is_chat_with_me == 1) {
                //나와의 채팅
                const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="fix_top new_fix_top">${
                  this.getAttribute("isFixed") == 0
                    ? "상단에 고정"
                    : "상단에 고정 해제"
                }</li></ul>`;
                this.insertAdjacentHTML("beforeend", htmlString);
                //상단 고정
                document
                  .querySelectorAll(".new_fix_top")[0]
                  .addEventListener("click", function (e) {
                    e.preventDefault();
                    let formdata = new FormData(),
                      selteamworkId = item.teamwork_user_id;

                    function fixedTeam(fixeddata) {
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: fixeddata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            window.location.reload(true);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: "채팅방은 3개까지 고정할 수 있습니다.",
                            });
                          }
                        });
                    }
                    if (
                      document.querySelectorAll(
                        ".chat_list_item[isfixed='1']"
                      )[2] &&
                      this.parentNode.parentNode.getAttribute("isfixed") == 0
                    ) {
                      formdata.append(
                        "teamwork_user_id",
                        document
                          .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                          .getAttribute("isfixedid")
                      );
                      fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                        method: "POST",
                        body: formdata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.success == true) {
                            let fixeddata = new FormData();
                            fixeddata.append("teamwork_user_id", selteamworkId);
                            fixedTeam(fixeddata);
                          } else {
                            createPopup({
                              type: "confirm",
                              title: "고정 불가",
                              content: data.alert,
                            });
                          }
                        });
                    } else {
                      formdata.append("teamwork_user_id", selteamworkId);
                      fixedTeam(formdata);
                    }
                  });
                document
                  .querySelectorAll(".sub_background_black")[0]
                  .addEventListener("click", function () {
                    document
                      .querySelectorAll(".teamwork_list_l_p_menu")[0]
                      .remove();
                    this.remove();
                  });

                document
                  .querySelectorAll(".new_fix_top")[0]
                  .classList.remove("new_fix_top");
              } else {
                if (item.is_leader == 1) {
                  //팀장
                  const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="sub_toggle_alarm new_sub_toggle_alarm">${
                    this.getAttribute("isAlarm") == 0
                      ? "알림 켜기"
                      : "알림 끄기"
                  }</li><li class="fix_top new_fix_top">${
                    this.getAttribute("isFixed") == 0
                      ? "상단에 고정"
                      : "상단에 고정 해제"
                  }</li><li class="remove_teamwork red">팀워크 삭제</li></ul>`;
                  this.insertAdjacentHTML("beforeend", htmlString);
                  //알림토글
                  document
                    .querySelectorAll(".new_sub_toggle_alarm")[0]
                    .addEventListener("click", function () {
                      let formdata = new FormData();
                      formdata.append(
                        "teamwork_user_id",
                        item.teamwork_user_id
                      );
                      fetch(`https://api.olapse.com/api/teamwork/alarm`, {
                        method: "POST",
                        body: formdata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.data.type == 1) {
                            teamworkTitle[1].style.display = "none";
                          } else {
                            teamworkTitle[1].style.display = "block";
                          }
                          targetElement.setAttribute("isAlarm", data.data.type);
                          document
                            .querySelectorAll(".teamwork_list_l_p_menu")[0]
                            .remove();
                          document
                            .querySelectorAll(".sub_background_black")[0]
                            .remove();
                        });
                    });
                  //상단 고정
                  document
                    .querySelectorAll(".new_fix_top")[0]
                    .addEventListener("click", function (e) {
                      e.preventDefault();
                      let formdata = new FormData(),
                        selteamworkId = item.teamwork_user_id;

                      function fixedTeam(fixeddata) {
                        fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                          method: "POST",
                          body: fixeddata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            if (data.success == true) {
                              window.location.reload(true);
                            } else {
                              createPopup({
                                type: "confirm",
                                title: "고정 불가",
                                content: "채팅방은 3개까지 고정할 수 있습니다.",
                              });
                            }
                          });
                      }
                      if (
                        document.querySelectorAll(
                          ".chat_list_item[isfixed='1']"
                        )[2] &&
                        this.parentNode.parentNode.getAttribute("isfixed") == 0
                      ) {
                        formdata.append(
                          "teamwork_user_id",
                          document
                            .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                            .getAttribute("isfixedid")
                        );
                        fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                          method: "POST",
                          body: formdata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            if (data.success == true) {
                              let fixeddata = new FormData();
                              fixeddata.append(
                                "teamwork_user_id",
                                selteamworkId
                              );
                              fixedTeam(fixeddata);
                            } else {
                              createPopup({
                                type: "confirm",
                                title: "고정 불가",
                                content: data.alert,
                              });
                            }
                          });
                      } else {
                        formdata.append("teamwork_user_id", selteamworkId);
                        fixedTeam(formdata);
                      }
                    });
                  document
                    .querySelectorAll(".remove_teamwork")[0]
                    .addEventListener("click", function () {
                      createPopup({
                        type: "custom_select_2",
                        title: `${item.title} 팀워크를 삭제 하시겠습니까?`,
                        content: "팀워크를 삭제하면 모든 데이터가 삭제됩니다.",
                        button_text_1: "취소",
                        button_text_2: "삭제",
                        firstButtonClickFunction: () => {
                          //팀워크 지우기
                          let formdata = new FormData();
                          formdata.append(
                            "teamwork_user_id",
                            item.teamwork_user_id
                          );
                          fetch(`https://api.olapse.com/api/teamwork/delete`, {
                            method: "POST",
                            body: formdata,
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          })
                            .then(function (response) {
                              return response.json();
                            })
                            .then(function (data) {
                              setTimeout(() => {
                                if (data.success == true) {
                                  createPopup({
                                    type: "custom",
                                    title: "팀워크 삭제",
                                    content: "팀워크를 삭제하였습니다.",
                                    custom: () => {
                                      window.location.href = "/chat";
                                    },
                                  });
                                } else {
                                  createPopup({
                                    type: "reTry",
                                    title: "팀워크 삭제 실패",
                                    content: data.alert,
                                  });
                                }
                              }, 500);
                            });
                        },
                        secondButtonClickFunction: () => {
                          document
                            .querySelectorAll(".teamwork_list_l_p_menu")[0]
                            .remove();
                          document
                            .querySelectorAll(".sub_background_black")[0]
                            .remove();
                        },
                      });
                      document.querySelectorAll(
                        "div.popup_wrapper div.popup_box button.button_right"
                      )[0].style.color = "#ff5555";
                      document.querySelectorAll(
                        "div.popup_wrapper div.popup_box div.popup_text_wrapper h1"
                      )[0].style.textAlign = "center";
                    });

                  document
                    .querySelectorAll(".sub_background_black")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".teamwork_list_l_p_menu")[0]
                        .remove();
                      this.remove();
                    });
                } else {
                  //일반 팀원
                  //팀장
                  const htmlString = `<div class="sub_background_black"></div><ul class="teamwork_list_l_p_menu"><li class="sub_toggle_alarm new_sub_toggle_alarm">${
                    this.getAttribute("isAlarm") == 0
                      ? "알림 켜기"
                      : "알림 끄기"
                  }</li><li class="fix_top new_fix_top">${
                    this.getAttribute("isFixed") == 0
                      ? "상단에 고정"
                      : "상단에 고정 해제"
                  }</li><li class="remove_teamwork">팀워크 나가기</li></ul>`;
                  this.insertAdjacentHTML("beforeend", htmlString);
                  //알림토글
                  document
                    .querySelectorAll(".new_sub_toggle_alarm")[0]
                    .addEventListener("click", function () {
                      let formdata = new FormData();
                      formdata.append(
                        "teamwork_user_id",
                        item.teamwork_user_id
                      );
                      fetch(`https://api.olapse.com/api/teamwork/alarm`, {
                        method: "POST",
                        body: formdata,
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        },
                      })
                        .then(function (response) {
                          return response.json();
                        })
                        .then(function (data) {
                          if (data.data.type == 1) {
                            teamworkTitle[1].style.display = "none";
                          } else {
                            teamworkTitle[1].style.display = "block";
                          }
                          targetElement.setAttribute("isAlarm", data.data.type);
                          document
                            .querySelectorAll(".teamwork_list_l_p_menu")[0]
                            .remove();
                          document
                            .querySelectorAll(".sub_background_black")[0]
                            .remove();
                        });
                    });
                  //상단 고정
                  document
                    .querySelectorAll(".new_fix_top")[0]
                    .addEventListener("click", function (e) {
                      e.preventDefault();
                      let formdata = new FormData(),
                        selteamworkId = item.teamwork_user_id;

                      function fixedTeam(fixeddata) {
                        fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                          method: "POST",
                          body: fixeddata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            if (data.success == true) {
                              window.location.reload(true);
                            } else {
                              createPopup({
                                type: "confirm",
                                title: "고정 불가",
                                content: "채팅방은 3개까지 고정할 수 있습니다.",
                              });
                            }
                          });
                      }
                      if (
                        document.querySelectorAll(
                          ".chat_list_item[isfixed='1']"
                        )[2] &&
                        this.parentNode.parentNode.getAttribute("isfixed") == 0
                      ) {
                        formdata.append(
                          "teamwork_user_id",
                          document
                            .querySelectorAll(".chat_list_item[isfixed='1']")[2]
                            .getAttribute("isfixedid")
                        );
                        fetch(`https://api.olapse.com/api/teamwork/fixed`, {
                          method: "POST",
                          body: formdata,
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                        })
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (data) {
                            if (data.success == true) {
                              let fixeddata = new FormData();
                              fixeddata.append(
                                "teamwork_user_id",
                                selteamworkId
                              );
                              fixedTeam(fixeddata);
                            } else {
                              createPopup({
                                type: "confirm",
                                title: "고정 불가",
                                content: data.alert,
                              });
                            }
                          });
                      } else {
                        formdata.append("teamwork_user_id", selteamworkId);
                        fixedTeam(formdata);
                      }
                    });
                  document
                    .querySelectorAll(".remove_teamwork")[0]
                    .addEventListener("click", function () {
                      createPopup({
                        type: "custom_select_2",
                        title: `${item.title} 팀워크를 나가시겠습니까?`,
                        content: "팀워크를 나가면 모든 데이터가 삭제됩니다.",
                        button_text_1: "취소",
                        button_text_2: "삭제",
                        firstButtonClickFunction: () => {
                          //팀워크 나가기
                          let formdata = new FormData();
                          formdata.append(
                            "teamwork_user_id",
                            item.teamwork_user_id
                          );
                          fetch(`https://api.olapse.com/api/teamwork/leave`, {
                            method: "POST",
                            body: formdata,
                            headers: {
                              Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            },
                          })
                            .then(function (response) {
                              return response.json();
                            })
                            .then(function (data) {
                              setTimeout(() => {
                                if (data.success == true) {
                                  createPopup({
                                    type: "custom",
                                    title: "팀워크 나가기",
                                    content: "팀워크를 나갔습니다.",
                                    custom: () => {
                                      window.location.reload(true);
                                    },
                                  });
                                } else {
                                  createPopup({
                                    type: "reTry",
                                    title: "팀워크 나가기 실패",
                                    content: data.alert,
                                  });
                                }
                              }, 500);
                            });
                        },
                        secondButtonClickFunction: () => {
                          document
                            .querySelectorAll(".teamwork_list_l_p_menu")[0]
                            .remove();
                          document
                            .querySelectorAll(".sub_background_black")[0]
                            .remove();
                        },
                      });
                      document.querySelectorAll(
                        "div.popup_wrapper div.popup_box button.button_right"
                      )[0].style.color = "#ff5555";
                    });

                  document
                    .querySelectorAll(".sub_background_black")[0]
                    .addEventListener("click", function () {
                      document
                        .querySelectorAll(".teamwork_list_l_p_menu")[0]
                        .remove();
                      this.remove();
                    });
                }
              }
            }
          });
      });
    };

    function updateUnreadMsg() {
      fetch(`https://api.olapse.com/api/teamwork/unread/message`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const targetElement = document.querySelectorAll(".chat span")[0];
          if (targetElement && data.data.unread_message_count !== 0) {
            (targetElement.style.display = "flex"),
              (targetElement.textContent =
                data.data.unread_message_count >= 100
                  ? "99+"
                  : data.data.unread_message_count);
          } else if (targetElement && data.data.unread_message_count == 0) {
            targetElement.style.display = "none";
          }
        });
    }
    updateUnreadMsg();
    initSearchModule();
    filteringBtns("div.search_result_wrapper ul:nth-of-type(1) li", "active");
    fetch(`https://api.olapse.com/api/teamwork`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        tapHold(data);
      });

    const echo = new Echo({
      host: "https://api.olapse.com:6006/",
      broadcaster: "socket.io",
      client: socketio,
    });
    let chkTime,
      endTime,
      startChk = false;

    let chkTimeCount = 0,
      endTimeCount = 0;

    function listReSet() {
      if (endTimeCount >= 2500 || chkTimeCount >= 1500) {
        chkTimeCount = 0;
        endTimeCount = 0;
        clearInterval(chkTime);
        clearInterval(endTime);
        startChk = false;
        fetch(`https://api.olapse.com/api/teamwork`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            tapHold(data);
          });
      }
    }

    let chatFSet = true;
    echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
      function pushSetting() {
        if (e.type !== "user_info_modify") {
          chkTimeCount = 0;
          if (!startChk) {
            (chkTimeCount = 0), (endTimeCount = 0);
            startChk = true;
            chkTime = setInterval(() => {
              chkTimeCount = chkTimeCount + 100;
              listReSet();
            }, 100);
            endTime = setInterval(() => {
              endTimeCount = endTimeCount + 100;
              listReSet();
            }, 100);
          }
        }
      }
      if (location.pathname == "/chat" && getParameterByName("idx") !== "") {
        if (chatFSet) {
          if (e.type == "read") {
            pushSetting();
            chatFSet = false;
          }
        } else {
          pushSetting();
        }
      } else {
        pushSetting();
      }
    });
  }, []);

  return (
    <div className="chat_wrapper">
      <div className="chat_header">
        <h1>팀워크</h1>
        <ul>
          <li>
            <a>
              <img src="/assets/images/icon_search.svg" alt="" />
            </a>
          </li>
          <li>
            <a onClick={() => navigate("/create")}>
              <img src="/assets/images/icon_chat_plus.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <div className="chat_list_wrapper">
        <ul className="chat_list"></ul>
        <div className="no_data">
          <span>참여한 팀워크가 없습니다.</span>
        </div>
      </div>
      <SearchModuleDom2 />
    </div>
  );
};
export default TeamworkList;

// 2022-02-24 인수인계
// 기능 : 데이터 저장소 세 번째 페이지입니다. 구독한 사용자만 접근가능하며 해당 페이지에서 해지할 수 있습니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { numberWithCommas, toggleClass, getToday } from "../shared/script";
import ChatHeader from "./chat_header";
import createPopup from "./createPopup";
const DataSave3 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let endDate;
    let orderNumber;
    init();

    function isPayment() {
      fetch(`https://api.olapse.com/api/data/store`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (new Date() > new Date(data.data.user_data_store.end_date)) {
            navigate("/more/data_save_2");
          }
          if (data.data.user_data_store) {
            document.querySelectorAll(".date_info")[0].textContent =
              data.data.user_data_store.begin_date.split(" ")[0] +
              "~" +
              data.data.user_data_store.end_date.split(" ")[0];
            endDate = data.data.user_data_store.end_date.split(" ")[0];
            document.querySelectorAll(".next_payment_day")[0].textContent =
              data.data.user_data_store.end_date.split(" ")[0];
            orderNumber = data.data.user_data_store.order_number;
          } else {
            createPopup({
              type: "custom",
              title: "미구독 사용자",
              content: "데이터저장소 구독권이 없습니다.",
              custom: () => {
                navigate("/more/data_save_2");
              },
            });
          }
        });
    }

    function getDataSavePriceInfo() {
      fetch(`https://api.olapse.com/api/data/store/price`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          document
            .querySelectorAll(".data_save_box")[0]
            .insertAdjacentHTML(
              "beforeend",
              `<h2><span>${numberWithCommas(
                data.data.price
              )}원</span><span> / 월</span></h2>`
            );
        });
    }

    document
      .querySelectorAll(".open_info")[0]
      .addEventListener("click", function () {
        navigate("/more/data_save_1");
      });

    //데이터 저장소 해지
    function endDataSave() {
      let formdata = new FormData();
      formdata.append("order_number", orderNumber);
      fetch(`https://api.olapse.com/api/data/store/cancel`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            setTimeout(() => {
              createPopup({
                type: "custom",
                title: "해지완료",
                content: "데이터저장소 해지가 완료되었습니다.",
                custom: () => {
                  localStorage.setItem("end_subs", "1");
                  navigate("/more");
                },
              });
            }, 500);
          } else {
            createPopup({
              type: "reTry",
              title: "해지 실패",
              content: data.alert,
            });
          }
        });
    }
    document
      .querySelectorAll(".stop_subs")[0]
      .addEventListener("click", function () {
        fetch(`https://api.olapse.com/api/data/store`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (
              data.data.user_data_store &&
              data.data.user_data_store.status == 0
            ) {
              createPopup({
                type: "custom_select_2",
                title: "데이터 저장소 해지",
                content: `<span>${endDate} 에 데이터 저장소<br>구독이 해지됩니다.<br>해당 날짜부터 데이터 저장소의<br>모든 기능을 이용할 수 없습니다.<br><br>데이터 저장소 구독을 해지하시겠습니까?</span>`,
                firstButtonClickFunction: () => {
                  endDataSave();
                },
                secondButtonClickFunction: () => {},
                button_text_1: "취소",
                button_text_2: "해지",
              });
              document.querySelectorAll(".button_right")[0].style.color =
                "#f00";
            } else {
              createPopup({
                type: "reTry",
                title: "해지 요청 실패",
                content:
                  "이미 해지요청되어<br>구독기간 종료 이후 해지처리 됩니다.",
              });
            }
          });
      });

    if (
      navigator.userAgent.match("olapse/iOS") != null ||
      navigator.userAgent.match("olapse/ios") != null
    ) {
      document.querySelectorAll(".stop_subs")[0].remove();
    }
    function init() {
      isPayment();
      getDataSavePriceInfo();
    }
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="데이터 저장소 구독정보" isInfo="1" />
        <div className="data_save_2_wrapper">
          {/* 박스 */}
          <div className="data_save_box data_save_box_subs">
            <h1>
              데이터 저장소
              <span className="now_subs_mark">구독중</span>
            </h1>
            <ul>
              <li>
                <h1>구독기간</h1>
                <span className="date_info" />
              </li>
              <li>
                <h1>다음결제일</h1>
                <span className="next_payment_day" />
              </li>
              <li>
                <h1>결제수단</h1>
                <span>신용카드</span>
              </li>
            </ul>
          </div>
          <button className="stop_subs">해지하기</button>
        </div>
        {/* footer */}
        <div style={{ marginTop: "3rem" }} className="data_save_footer">
          <h1>자동결제 해지 및 미납 안내</h1>
          <span>
            정기결제 취소 및 환불 정책
            <br />
            구독 해지하기
            <br />
            1. 더보기를 엽니다
            <br />
            2. 데이터저장소 메뉴를 선택합니다.
            <br />
            3. 구독해지를 클릭합니다.
            <br />
            4. 안내를 따릅니다.
            <br />
            회원이 해지를 요청하면 월간 결제 기간이 종료될 때까지는 서비스를
            계속 이용할 수 있습니다. 관련 법률이 허용하는 범위에서 결제 금액은
            환불되지 않으며 한 달 중 서비스를 이용하지 않은 기간에 대한 환불은
            제공하지 않습니다.
            <br />
            예를들어 1월1일에 데이터 구독서비스를 이용하고 1월 13일에
            구독서비스를 취소하기로 한경우 다음과 같이 처리 됩니다.
            <br />
            l 1월 30일까지 구독항목에 액세스를 할수 있습니다.
            <br />
            l 다음달 2월1일에는 월간 구독료가 청구되지 않습니다.
            <br />
            l 구독을 통해 이용할 수 있었던 데이터 저장소 및 엑셀 다운로드 기능,
            대화 불러오기 기능을 더 이상 액세스 할 수 없게 됩니다.
            <br />
            <br />
            정기 결제 재개 또는 재가입 하기
            <br />
            정기 결제가 취소된 상태에서 정기결제를 진행하게 되면 모든 일정과
            데이터에 액세스가 가능합니다.
            <br />
            단, "팀워크"에서 6개월동안 사용기록이 없으면 자동으로 모든 데이터가
            삭제되니 삭제가 되면 ""팀워크"" 내의 모든 데이터를 액세스 및 복구 할
            수가 없습니다.
            <br />
            예를 들면 1월 1일에 "팀워크"를 개설을 하고 2월1일부터 8월1일까지
            6개월 동안 "팀워크"내에 사용 기록이 없어 삭제가 된 상황에서 8월 1일
            데이터 저장소를 구매 한다 하더라도 삭제 시점부터의 이전 데이터는
            복구 되지 않습니다.
          </span>
        </div>
      </div>
    </>
  );
};
export default DataSave3;

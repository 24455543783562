// 2022-02-24 인수인계
// 기능 : 우측 UI 상단에 들어가는 공통 header

import { useNavigate } from "react-router-dom";
import { getParameterByName } from "../shared/script";

const ChatHeader = (props) => {
  const navigate = useNavigate();
  const back = () => {
    if (props.isCompleteBack == true) {
      navigate("/more");
    } else {
      if (props.isOpenMenu == 1) {
        navigate(`/chat?idx=${getParameterByName("idx")}&isOpenMenu=1`);
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <>
      <div className="pc_right_area_top">
        <div onClick={() => back()} className="left">
          <img src="/assets/images/icon_back.svg" alt="" />
        </div>
        <h1 className="right_area_title">{props.title}</h1>
        {props.isSearch == 1 && (
          <img
            className="open_search_module"
            src="/assets/images/icon_search.svg"
            alt=""
          />
        )}
        {props.isSearch == 1 && (
          <div className="top search_module">
            <div className="left">
              <div className="search_input_wrapper">
                <div className="inner_col">
                  <img
                    className="search_submit_button"
                    src="/assets/images/icon_search.svg"
                    alt=""
                  />
                  <input placeholder="검색" id="search_input" type="text" />
                </div>
                <p className="del_text" />
              </div>
            </div>
            <span className="close_search_module">취소</span>
          </div>
        )}
        {props.isInfo == 1 && (
          <div className="open_info">
            <img src="/assets/images/infoIcon.svg" alt="" />
          </div>
        )}
      </div>
    </>
  );
};
export default ChatHeader;

// 2022-02-24 인수인계
// 기능 : 최초 접속시 토큰여부에따라 알맞는 페이지로 리다이렉트 시켜주는 페이지입니다.

import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {inputChanger} from "../shared/script";

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.getItem("token") ? navigate("/main") : navigate("/login");
        inputChanger();
    }, []);
    return false;
};

export default Home;

// 2022-02-24 인수인계
// 기능 : 검색모듈 UI입니다. (멤버검색, 일정검색 등)

const SearchModuleDom2 = () => {
  return (
    <>
      <div className="module_wrapper search_module">
        <div className="top">
          <div className="left">
            <div className="search_input_wrapper">
              <div className="inner_col">
                <img src="/assets/images/icon_search.svg" alt="" />
                <input
                  placeholder="검색 (일정, 팀워크)"
                  id="search_input"
                  type="text"
                />
              </div>
              <p className="del_text" />
            </div>
          </div>
          <span>취소</span>
        </div>
        <div className="recent">
          <div className="row">
            <h1>최근검색어</h1>
            <span className="del_all">전체삭제</span>
          </div>
          <ul className="recent_search_list" />
        </div>
        <div className="search_result_wrapper">
          <ul>
            <li className="active">일정</li>
            <li>팀워크</li>
          </ul>
          <ul className="search_result_list data_list" />
          <div className="no_data">
            <span>결과가 없습니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchModuleDom2;

// 2022-02-24 인수인계
// 기능 : 검색 기능을 모아놓은 파일입니다.

import react, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { noData, numberWithCommas } from "./script";

//멤버, 팀워크 검색 모듈
let myMemberList = [];
// 1. 모듈 열기
const openModule = (triggerElement, targetModuleElement) => {
  if (triggerElement && targetModuleElement) {
    triggerElement.addEventListener("click", function () {
      document.querySelectorAll(".recent")[0].style.display = "block";
      document.querySelectorAll(".search_result_wrapper")[0].style.display =
        "none";
      targetModuleElement.style.display = "block";
      getRecentSearchList();
      document.querySelectorAll(".chat_wrapper")[0].style.overflowY = "hidden";
    });
  }
};

//2. 모듈 닫기
const closeModule = (triggerElement, targetModuleElement) => {
  if (triggerElement && targetModuleElement) {
    triggerElement.addEventListener("click", function () {
      document.querySelectorAll(".recent")[0].style.display = "none";
      document.querySelectorAll(".search_result_wrapper")[0].style.display =
        "none";
      targetModuleElement.style.display = "none";
      //초기화
      resetResult(document.querySelectorAll(".recent_search_list li"));
      resetResult(document.querySelectorAll(".search_result_list li"));
      document.querySelectorAll(".chat_wrapper")[0].style.overflowY = "auto";
    });
    function gnbCloseSet() {
      document.querySelectorAll(".recent")[0].style.display = "none";
      document.querySelectorAll(".search_result_wrapper")[0].style.display =
        "none";
      targetModuleElement.style.display = "none";
      //초기화
      resetResult(document.querySelectorAll(".recent_search_list li"));
      resetResult(document.querySelectorAll(".search_result_list li"));
      document.querySelectorAll(".chat_wrapper")[0].style.overflowY = "auto";
    }

    document
      .querySelectorAll("div.left_menu_bar ul li")[1]
      .addEventListener("click", function () {
        gnbCloseSet();
      });
    document
      .querySelectorAll("div.left_menu_bar ul li")[2]
      .addEventListener("click", function () {
        gnbCloseSet();
      });
  }
};

function resetResult(items) {
  items.forEach((item, idx) => {
    item.remove();
  });
}

//3. 최근 검색 리스트
function getRecentSearchList() {
  const appendtargetElement = document.querySelectorAll(
    ".recent_search_list"
  )[0];
  fetch("https://api.olapse.com/api/search/history", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.data.search_histories.data.length !== 0) {
        data.data.search_histories.data.forEach((item, idx) => {
          const HTMLString = `<li class="new_recent_item" keyword="${item.keyword}"> <h1>${item.keyword}</h1> <p class="del_recent_each_button"></p> </li>`;
          appendtargetElement.insertAdjacentHTML("beforeend", HTMLString);

          document
            .querySelectorAll(".new_recent_item h1")[0]
            .addEventListener("click", function () {
              document.querySelector("#search_input").value = item.keyword;
              search(item.keyword);
            });

          document
            .querySelectorAll(".new_recent_item .del_recent_each_button")[0]
            .addEventListener("click", function () {
              removeRecentEach(this.parentNode, item.id);
            });

          document
            .querySelectorAll(".new_recent_item")[0]
            .classList.remove("new_recent_item");
        });
      }
    });
}

//인풋 value 초기화
const removeTextContents = (targetTriggerButton, targetInputElement) => {
  if (targetTriggerButton && targetInputElement) {
    targetTriggerButton.addEventListener("click", function () {
      targetInputElement.value = "";
      targetInputElement.focus();
    });
  }
};

//4. 검색
const search = (keyword) => {
  let next;
  document.querySelectorAll(".search_result_list li").forEach((item) => {
    item.remove();
  });
  if (window.location.pathname == "/main") {
    searchInner(
      `https://api.olapse.com/api/search?keyword=${encodeURIComponent(
        keyword
      )}&page=1&type=member`
    );
    searchInner2(
      `https://api.olapse.com/api/search?keyword=${encodeURIComponent(
        keyword
      )}&page=1&type=teamwork`
    );
  } else {
    searchInner3(
      `https://api.olapse.com/api/teamwork/search?type=schedule&keyword=${encodeURIComponent(
        keyword
      )}&page=1`
    );
    searchInner2(
      `https://api.olapse.com/api/search?keyword=${encodeURIComponent(
        keyword
      )}&page=1&type=teamwork`
    );
  }

  function searchInner3(url) {
    inner(url);
    function inner(url) {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            if (data.data.results.data.length !== 0) {
              noData(false);
              data.data.results.data.forEach((item, idx) => {
                let className, title;
                if (item.status == 0) {
                  (className = "type_1"), (title = "요청");
                } else if (item.status == 1) {
                  (className = "type_2"), (title = `수락`);
                } else if (item.status == 2) {
                  (className = "type_3"), (title = `완료`);
                } else if (item.status == 5) {
                  (className = "type_4"), (title = `보류`);
                } else if (item.status == 10) {
                  (className = "type_5"), (title = `취소`);
                }
                let liTapSet =
                  window.location.pathname !== "/main"
                    ? document
                        .querySelectorAll(".search_result_wrapper ul")[0]
                        .querySelectorAll("li.active")[0].textContent == "일정"
                      ? ""
                      : "display:none"
                    : "";
                const htmlString = `<li class="new_cal_item active new_search_result_item_1" style="${liTapSet}" status="${
                  item.status
                }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
                  item.contents && item.contents
                }</span> <h1> <span class="date_created_at">${
                  item.created_at
                }</span> <span class="data_price_detail">${numberWithCommas(
                  item.price
                )}원</span> </h1> </div> </li>`;

                document
                  .querySelectorAll(".search_result_list")[0]
                  .insertAdjacentHTML("beforeend", htmlString);

                document
                  .querySelectorAll(".new_cal_item")[0]
                  .addEventListener("click", function () {
                    window.location.href = `/chat?idx=${item.teamwork_id}?schedule_idx=${item.id}`;
                  });
                document
                  .querySelectorAll(".new_cal_item")[0]
                  .classList.remove("new_cal_item");
              });
            } else {
              if (
                window.location.pathname !== "/main" &&
                document
                  .querySelectorAll(".search_result_wrapper ul")[0]
                  .querySelectorAll("li.active")[0].textContent == "일정"
              ) {
                noData(true);
              }
            }

            if (data.data.results.next_page_url) {
              inner(data.data.results.next_page_url + `&keyword=${keyword}`);
            }
          } else {
            createPopup({
              type: "confirm",
              title: "조회 실패",
              content: data.alert,
            });
          }
        });
    }
  }
  function searchInner(url) {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        next = data.data.datas.next_page_url;
        if (data.success == true) {
          const appendTargetElement = document.querySelectorAll(
            ".search_result_list"
          )[0];
          document.querySelectorAll(".recent")[0].style.display = "none";
          document.querySelectorAll(".search_result_wrapper")[0].style.display =
            "block";
          if (data.data.datas.data.length !== 0) {
            noData(false);
            data.data.datas.data.forEach((item) => {
              let searchResultHighLight = item.name,
                searchResultArr = [],
                length = Number(
                  String(document.querySelector("#search_input").value).length
                );
              if (
                getSameString(
                  item.name,
                  document.querySelector("#search_input").value
                ) !== -1
              ) {
                item.name.split("").forEach((item) => {
                  searchResultArr.push(item);
                });

                let index = Number(
                  getSameString(
                    item.name,
                    document.querySelector("#search_input").value
                  )
                );

                for (let i = 0; i <= 999; i++) {
                  if (length >= 1) {
                    searchResultHighLight = searchResultHighLight.replace(
                      String(searchResultArr[index]),
                      `<p class="highlight">${searchResultArr[index]}</p>`
                    );
                    index++;
                    length--;
                  } else {
                    break;
                  }
                }
              } else {
                searchResultHighLight = item.name;
              }
              let HTMLString = `<li class="new_search_result_item new_search_result_item_1" idx="${
                item.id
              }"> <p class="new_profile profile"></p> <div class="textArea"> <h1>${searchResultHighLight}</h1> <span>${
                item.message ? item.message : ""
              }</span> </div> </li>`;
              appendTargetElement.insertAdjacentHTML("beforeend", HTMLString);
              item.profile_url &&
                (document.querySelectorAll(
                  ".new_profile"
                )[0].style.background = `url(${item.profile_url}) no-repeat`);
              document
                .querySelectorAll(".new_profile")[0]
                .classList.remove("new_profile");
              document
                .querySelectorAll(".new_search_result_item")[0]
                .addEventListener("click", function () {
                  document
                    .querySelectorAll(
                      "div.member_list div.user_profile_wrapper div.profile_row"
                    )
                    .forEach((item2, idx2) => {
                      if (item.id == item2.getAttribute("idx")) {
                        item2.click();
                      }
                    });
                });
              document
                .querySelectorAll(".new_search_result_item")[0]
                .classList.remove("new_search_result_item");
            });
          } else {
            noData(true);
          }
          if (next) {
            searchInner(next);
          }
        }
      });
  }

  document
    .querySelectorAll("div.search_result_wrapper ul:nth-of-type(1) li")
    .forEach((item, idx) => {
      item.addEventListener("click", function () {
        if (idx == 0) {
          if (!document.querySelectorAll(".new_search_result_item_1")[0]) {
            noData(true);
          } else {
            noData(false);
          }
          document
            .querySelectorAll(".new_search_result_item_1")
            .forEach((item, idx) => {
              item.style.display = "flex";
            });
          document
            .querySelectorAll(".new_search_result_item_2")
            .forEach((item, idx) => {
              item.style.display = "none";
            });
        } else {
          if (!document.querySelectorAll(".new_search_result_item_2")[0]) {
            noData(true);
          } else {
            noData(false);
          }
          document
            .querySelectorAll(".new_search_result_item_1")
            .forEach((item, idx) => {
              item.style.display = "none";
            });
          document
            .querySelectorAll(".new_search_result_item_2")
            .forEach((item, idx) => {
              item.style.display = "flex";
            });
        }
      });
    });

  function searchInner2(url) {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        next = data.data.datas.next_page_url;
        if (data.success == true) {
          const appendTargetElement = document.querySelectorAll(
            ".search_result_list"
          )[0];
          document.querySelectorAll(".recent")[0].style.display = "none";
          document.querySelectorAll(".search_result_wrapper")[0].style.display =
            "block";
          if (data.data.datas.data.length !== 0) {
            noData(false);
            data.data.datas.data.forEach((item) => {
              let searchResultHighLight = item.title,
                searchResultArr = [],
                length = Number(
                  String(document.querySelector("#search_input").value).length
                );
              if (
                getSameString(
                  item.title,
                  document.querySelector("#search_input").value
                ) !== -1
              ) {
                item.title.split("").forEach((item) => {
                  searchResultArr.push(item);
                });

                let index = Number(
                  getSameString(
                    item.title,
                    document.querySelector("#search_input").value
                  )
                );

                var find = String(
                  document.querySelector("#search_input").value
                );
                var hlChk = new RegExp(find, "g");
                searchResultHighLight = searchResultHighLight.replace(
                  hlChk,
                  '<p class="highlight">' + find + "</p>"
                );

                // for (let i = 0; i <= 999; i++) {
                //     if (length >= 1) {
                //         searchResultHighLight = searchResultHighLight.replace(String(searchResultArr[index]), `<p class="highlight">${searchResultArr[index]}</p>`);
                //         index++;
                //         length--;
                //     } else {
                //         break;
                //     }
                // }
              } else {
                searchResultHighLight = item.title;
              }
              let liTapSet =
                window.location.pathname !== "/main"
                  ? document
                      .querySelectorAll(".search_result_wrapper ul")[0]
                      .querySelectorAll("li.active")[0].textContent == "일정"
                    ? "display:none"
                    : ""
                  : "";
              let HTMLString = `<li style="${liTapSet}" class="new_search_result_item new_search_result_item_2" idx="${
                item.id
              }"> <p class="new_profile profile_2"></p> <div class="textArea"> <h1>${searchResultHighLight}</h1> <span>${
                item.message ? item.message : ""
              }</span> </div> </li>`;
              appendTargetElement.insertAdjacentHTML("beforeend", HTMLString);
              item.image_url &&
                (document.querySelectorAll(
                  ".new_profile"
                )[0].style.background = `url(${item.image_url}) no-repeat`);
              document
                .querySelectorAll(".new_profile")[0]
                .classList.remove("new_profile");
              document
                .querySelectorAll(".new_search_result_item")[0]
                .addEventListener("click", function () {
                  window.location.href = `/chat?idx=${item.id}`;
                });
              document
                .querySelectorAll(".new_search_result_item")[0]
                .classList.remove("new_search_result_item");
            });
          } else {
            if (
              window.location.pathname !== "/main" &&
              document
                .querySelectorAll(".search_result_wrapper ul")[0]
                .querySelectorAll("li.active")[0].textContent !== "일정"
            ) {
              noData(true);
            }
          }
          if (next) {
            searchInner2(next);
          }
        }
      });
  }
};

function getSameString(targetString, keyword) {
  if (targetString.indexOf(keyword) !== -1) {
    return targetString.indexOf(keyword);
  }
}

// 5. 최근검색어 개별삭제
function removeRecentEach(targetWrapper, id) {
  fetch(`https://api.olapse.com/api/search/delete?id[]=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        targetWrapper.remove();
      }
    });
}
//6. 최근검색어 전체삭제
function removeRecentAll() {
  fetch(`https://api.olapse.com/api/search/delete?id[]=all`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        document
          .querySelectorAll(".recent_search_list li")
          .forEach((item, idx) => {
            item.remove();
          });
      }
    });
}

//시작
const initSearchModule = () => {
  const closeTriggerElement = document.querySelectorAll(
      "div.module_wrapper.search_module div.top span"
    )[0],
    openTriggerElement = document.querySelectorAll(
      "div.chat_header ul > li"
    )[0],
    targetModuleElement = document.querySelectorAll(".search_module")[0],
    searchTriggerElement = document.querySelectorAll(
      "div.module_wrapper.search_module div.top div.left .search_input_wrapper img"
    )[0],
    searchInputElement = document.querySelector("#search_input");
  openModule(openTriggerElement, targetModuleElement);
  closeModule(closeTriggerElement, targetModuleElement);
  removeTextContents(
    document.querySelectorAll(".del_text")[0],
    searchInputElement
  );
  if (searchTriggerElement) {
    searchTriggerElement.addEventListener("click", function () {
      String(searchInputElement.value).length > 0 &&
        search(searchInputElement.value);
    });
    searchInputElement.addEventListener("keydown", function (e) {
      if (e.keyCode == 13) {
        String(searchInputElement.value).length > 0 &&
          search(searchInputElement.value);
      }
    });
    document
      .querySelectorAll(".del_all")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".recent_search_list li")[0] &&
          removeRecentAll();
      });
  }
};

export default initSearchModule;

// 2022-02-24 인수인계
// 기능 : 아이디찾기 스탭2 페이지입니다.

import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {getParameterByName, inputChanger} from "../shared/script.js";

const FindIdSecond = () => {
    const navigate = useNavigate();

    useEffect(() => {
        inputChanger();
        const idEl = document.querySelectorAll(".found_id")[0];
        if (getParameterByName("id")) {
            idEl.textContent = getParameterByName("id");
        } else {
            idEl.textContent = "해당 정보로 가입된 아이디가 없습니다.";
        }
    }, []);

    return (
        <>
            <div id="wrap">
                <UserHeader />
                <div className="user_wrapper">
                    <div className="page_desc">
                        <h2>아이디 찾기</h2>
                        <span>입력하신 정보로 조회된 아이디입니다.</span>
                    </div>
                    <div className="found_id"></div>
                    <div className="button_type_2">
                        <button>
                            <a onClick={() => navigate("/find_pass_1")}>비밀번호 찾기</a>
                        </button>
                        <button>
                            <a onClick={() => navigate("/login")}>로그인</a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FindIdSecond;

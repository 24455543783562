// 2022-02-24 인수인계
// 기능 : 고객센터(qna) 리스트입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData } from "../shared/script";
const CustomerCenter = () => {
  const navigate = useNavigate();

  useEffect(() => {
    //문의내역 가져오기
    function getFaqList() {
      const appendTarget = document.querySelectorAll(".faq_list")[0];
      inner(`https://api.olapse.com/api/qna?page=1`);
      function inner(url) {
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.qnas.data.length !== 0) {
              noData(false);
              data.data.qnas.data.forEach((item, idx) => {
                const htmlString = `<li class="new_faq_item" idx="${
                  item.id
                }"> <div class="left_text_list"> <h1>${item.type}</h1> <span>${
                  item.created_at
                }</span> </div> <p class="${
                  item.status == 0 ? "faq_type_1" : "faq_type_2"
                }">${item.status == 0 ? "답변대기" : "답변완료"}</p> </li>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                document
                  .querySelectorAll(".new_faq_item")[0]
                  .addEventListener("click", function () {
                    navigate(`/more/faq_detail?idx=${item.id}`);
                  });
                document
                  .querySelectorAll(".new_faq_item")[0]
                  .classList.remove("new_faq_item");
              });

              if (data.data.qnas.next_page_url) {
                inner(data.data.qnas.next_page_url);
              }
            } else {
              noData(true);
            }
          });
      }
    }
    getFaqList();
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="고객센터" />
        <ul className="button_type_sq_select">
          <li>
            <a onClick={() => navigate("/more/customer_center/1")}>1:1문의</a>
          </li>
          <li className="active">
            <a onClick={() => navigate("/more/customer_center/0")}>문의내역</a>
          </li>
        </ul>
        <div className="setting_gray_wrapper">
          <ul className="faq_list" />
          <div className="no_data">
            <span>문의내역이 없습니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerCenter;

// 2022-02-24 인수인계
// 기능 : 팀워크 생성페이지입니다.

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import "../assets/css/cal.css";
import {
  getParameterByName,
  toggleClass,
  IsLogin,
  noData,
  getToday,
  numberWithCommas,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import TeamworkList from "../components/teamworkList";
import anime from "animejs";
import Basic from "../components/basic";
import ChatInner from "../components/chat_inner";
import ChatHeader from "../components/chat_header";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import AddMember from "../components/addMember";
const Create = () => {
  const navigate = useNavigate();

  useEffect(() => {
    inputChanger();
    let selectedMemberIdArr = [];

    fetch("https://api.olapse.com/api/member", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_friends.length !== 0) {
          noData(false);
          const appendTarget = document.querySelectorAll(".member_list")[0],
            selectedAppendTarget = document.querySelectorAll(
              ".selected_member_list"
            )[0];
          document.querySelectorAll(
            "div.chat_wrapper.white_m_wrapper div.all_memeber_list h1 span"
          )[0].textContent = data.data.user_friends.length + "명";
          data.data.user_friends.forEach((item, idx) => {
            let msgString;
            item.message
              ? item.message.length > 18
                ? (msgString = item.message.substr(0, 18) + "…")
                : (msgString = item.message)
              : (msgString = "");
            let htmlString = `<li idx="${item.id}" class="new_member_item"> <p class="new_profile_row"></p> <div> <h1>${item.name}</h1> <span>${msgString}</span> </div> </li>`;
            appendTarget.insertAdjacentHTML("beforeend", htmlString),
              item.profile_url &&
                (document.querySelectorAll(
                  ".new_profile_row"
                )[0].style.background = `url(${item.profile_url}) no-repeat`);
            //추가하기
            document
              .querySelectorAll(".new_member_item")[0]
              .addEventListener("click", function () {
                if (this.classList.contains("active")) {
                  this.classList.remove("active");
                  document
                    .querySelectorAll(".selected_member_list li")
                    .forEach((item, idx) => {
                      if (
                        this.getAttribute("idx") == item.getAttribute("idx")
                      ) {
                        item.remove();
                      }
                    });
                } else {
                  this.classList.add("active");
                  let htmlString2 = `<li idx="${item.id}"> <div class="new_selected_profile_img selected_profile_img"> <p class="del_selected_memeber"> <span class="new_remove_sel_button remove_sel_button"></span> </p> <span>${item.name}</span> </div> </li>`;
                  selectedAppendTarget.insertAdjacentHTML(
                    "beforeend",
                    htmlString2
                  );
                  item.profile_url &&
                    (document.querySelectorAll(
                      ".new_selected_profile_img .del_selected_memeber"
                    )[0].style.background = `url(${item.profile_url}) no-repeat`);
                  document.querySelectorAll(
                    ".selected_member_count"
                  )[0].innerText =
                    document.querySelectorAll(".selected_member_list li")
                      .length + "명";
                  //지우기
                  document
                    .querySelectorAll(".new_remove_sel_button")[0]
                    .addEventListener("click", function () {
                      this.parentNode.parentNode.parentNode.remove();
                      document
                        .querySelectorAll(".all_memeber_list .member_list li")
                        .forEach((item, idx) => {
                          if (
                            this.parentNode.parentNode.parentNode.getAttribute(
                              "idx"
                            ) == item.getAttribute("idx")
                          ) {
                            item.classList.remove("active");
                          }
                        });
                      document.querySelectorAll(
                        ".selected_member_count"
                      )[0].innerText =
                        document.querySelectorAll(".selected_member_list li")
                          .length + "명";
                    });

                  document
                    .querySelectorAll(".new_remove_sel_button")[0]
                    .classList.remove("new_remove_sel_button"),
                    document
                      .querySelectorAll(".new_selected_profile_img")[0]
                      .classList.remove("new_selected_profile_img");
                }
                document.querySelectorAll(
                  ".selected_member_count"
                )[0].innerText =
                  document.querySelectorAll(".selected_member_list li").length +
                  "명";
              });

            document
              .querySelectorAll(".new_profile_row")[0]
              .classList.remove("new_profile_row"),
              document
                .querySelectorAll(".new_member_item")[0]
                .classList.remove("new_member_item");
          });

          if (getParameterByName("selected_id")) {
            document
              .querySelectorAll(
                "div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list li"
              )
              .forEach((item, idx) => {
                item.getAttribute("idx") == getParameterByName("selected_id") &&
                  item.click();
              });
          }
        } else {
          noData(true);
        }
      });

    document
      .querySelectorAll(".create_next_button")[0]
      .addEventListener("click", function () {
        if (document.querySelectorAll(".selected_member_list li")[0]) {
          document
            .querySelectorAll(".selected_member_list li")
            .forEach((item, idx) => {
              selectedMemberIdArr.push(item.getAttribute("idx"));
            });
          navigate(`/create2?idArr=${selectedMemberIdArr}`);
        } else {
          createPopup({
            type: "reTry",
            title: "선택된 멤버 없음",
            content: "팀워크를 생성할 멤버를<br />1명 이상 선택해주세요.",
          });
        }
      });
  }, []);
  return (
    <>
      <div className="wrap">
        <MainMenu />
        <AddMember />
        <div className="chat_wrapper white_m_wrapper">
          <div className="white_m_header">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
            <span>팀워크 개설</span>
            <button className="create_next_button">다음</button>
          </div>
          <div className="selected_member_wrapper">
            <h1>
              선택
              <span className="selected_member_count blue">0명</span>
            </h1>
            <ul className="selected_member_list" />
          </div>
          <div className="all_memeber_list">
            <h1>
              멤버
              <span />
            </h1>
            <ul className="member_list" />
            <div className="no_data">
              <span>추가한 멤버가 없습니다.</span>
            </div>
          </div>
        </div>
        <Basic />
      </div>
    </>
  );
};

export default Create;

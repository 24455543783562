// 2022-02-24 인수인계
// 기능 : 휴대폰인증 스탭2입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const Phone2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inputChanger();
    let timeout, step1, savedPhoneNumber, countInterval;

    const phoneTrigger = document.querySelectorAll(".submit_number")[0];
    phoneTrigger.addEventListener("click", function () {
      savedPhoneNumber = document.querySelector("#phone_number").value;
      step1 = false;
      if (passPhone(document.querySelector("#phone_number").value)) {
        getNumber(savedPhoneNumber);
      } else {
        createPopup({
          type: "reTry",
          title: "인증요청 실패",
          content: "휴대폰 번호를 다시 확인해주세요.",
        });
      }
    });

    document
      .querySelectorAll(".next_button")[0]
      .addEventListener("click", function () {
        if (step1 == true) {
          confirmNumber(
            savedPhoneNumber,
            document.querySelector("#code").value
          );
        } else {
          createPopup({
            type: "confirm",
            title: "인증 실패",
            content: "휴대폰 번호로 인증코드를 발송해주세요.",
          });
        }
      });

    //인증번호 확인
    function confirmNumber(phone, code) {
      if (timeout == true) {
        createPopup({
          type: "confirm",
          title: "인증 시간 초과",
          content:
            "입력 가능 시간을 초과하였습니다. <br /> 재발송 후 다시 인증을 진행해주세요.",
        });
      } else {
        let formdata = new FormData();
        formdata.append("phone", phone);
        formdata.append("certification_code", code);
        fetch("https://api.olapse.com/api/sms/certification/check", {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            locale: "ko",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "인증성공",
                content: "휴대폰 번호 인증에 성공하였습니다.",
                custom: function () {
                  localStorage.setItem("code", code);
                  localStorage.setItem("phone", savedPhoneNumber);
                  navigate("/account_1");
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "인증실패",
                content: "인증번호가 일치하지 않습니다.",
              });
            }
          });
      }
    }

    //휴대폰 번호 유효성
    function passPhone(phoneNumber) {
      var patternPhone =
        /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
      if (!patternPhone.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }
    }
    //휴대폰 인증 요청
    function getNumber(phoneNumber) {
      let formdata = new FormData();
      formdata.append("phone", phoneNumber);
      fetch("https://api.olapse.com/api/sms/certification", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
          locale: "ko",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "confirm",
              title: "인증요청 성공",
              content: "입력하신 번호로 인증번호를 발송하였습니다.",
            });
            timeout = false;
            initTimer();
            step1 = true;
            phoneTrigger.textContent = "재발송";
          } else {
            if (
              data.alert ==
              "1일 최대 인증 횟수를 초과했습니다.\n내일 다시 시도해주세요."
            ) {
              createPopup({
                type: "custom",
                title: "조회불가",
                content: data.alert,
                custom: () => {
                  navigate("/login");
                },
              });
            } else {
              createPopup({
                type: "custom_select",
                title: "등록된 계정",
                content:
                  "입력한 휴대폰 번호로<br />이미 등록된 계정이 존재합니다.<br><br> <span style='display:inline' class='blue'>로그인 화면</span>으로 이동하시겠습니까?",
                custom: () => {
                  navigate("/login");
                },
              });
            }
          }
        });
    }

    //타이머
    function paddedFormat(num) {
      return num < 10 ? "0" + num : num;
    }

    function startCountDown(duration, element) {
      let secondsRemaining = duration;
      let min = 0;
      let sec = 0;

      countInterval = setInterval(function () {
        min = parseInt(secondsRemaining / 60);
        sec = parseInt(secondsRemaining % 60);

        element.textContent = `${paddedFormat(min)}:${paddedFormat(sec)}`;

        secondsRemaining = secondsRemaining - 1;
        if (secondsRemaining < 0) {
          clearInterval(countInterval);
          timeout = true;
        }
      }, 1000);
    }

    function initTimer() {
      if (countInterval) {
        clearInterval(countInterval);
      }
      let time_minutes = 3;
      let time_seconds = 0;

      let duration = time_minutes * 60 + time_seconds;

      let element = document.querySelector("#count-down-timer");
      element.style.display = "block";
      element.textContent = `${paddedFormat(time_minutes)}:${paddedFormat(
        time_seconds
      )}`;

      startCountDown(--duration, element);
    }
  }, []);

  function next() {
    if (String(document.querySelector("#name").value).length > 1) {
      localStorage.setItem("name", document.querySelector("#name").value);
      navigate("phone_2");
    } else {
      createPopup({
        type: "reTry",
        title: "이름확인",
        content: "이름을 두 글자 이상 입력해주세요.",
      });
    }
  }

  return (
    <>
      <div id="wrap">
        <UserHeader isCenter="true" />
        <div className="user_wrapper">
          <h1 className="page_title">휴대폰 인증</h1>
          <div className="input_wrapper">
            <div className="input_row">
              <h1>휴대폰 인증</h1>
              <div className="input_outter">
                <input
                  id="phone_number"
                  placeholder="휴대폰 번호"
                  type="number"
                />
                <p className="submit_number">전송</p>
              </div>
              <div className="input_outter">
                <input id="code" placeholder="인증 번호" type="number" />
                <p id="count-down-timer" className="red">
                  03:00
                </p>
              </div>
            </div>
          </div>
          <button className="button_type_1 next_button">다음</button>
        </div>
      </div>
    </>
  );
};

export default Phone2;

// 2022-02-24 인수인계
// 기능 : 정산하기 페이지입니다.

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import "../assets/css/cal.css";
import {
  getParameterByName,
  toggleClass,
  IsLogin,
  noData,
  getToday,
  numberWithCommas,
  filteringBtns,
  dateForm,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import TeamworkList from "../components/teamworkList";
import anime from "animejs";
import Basic from "../components/basic";
import ChatInner from "../components/chat_inner";
import ChatHeader from "../components/chat_header";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import AddMember from "../components/addMember";
import TeamworkModule from "../components/teamworkModules";

const Confirm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getParameterByName("idx")) {
      document.querySelectorAll(".right_area_title")[0].textContent = `${
        getParameterByName("name") + " 정산확인"
      }`;
    }

    fetch(
      `https://api.olapse.com/api/teamwork/member/calculate?id=${
        getParameterByName("idx") ? getParameterByName("idx") : "my"
      }&calculate_status=0`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.schedules.length !== 0) {
          noData(false);
          data.data.schedules.forEach((item, idx) => {
            let className, title;

            if (item.status == 0) {
              (className = "type_1"), (title = "요청");
            } else if (item.status == 1) {
              (className = "type_2"),
                (title = `수락 ${
                  item.name !== null
                    ? "(" + item.title + " / " + item.name + ")"
                    : ""
                }`);
            } else if (item.status == 2) {
              (className = "type_3"),
                (title = `완료 ${
                  item.name !== null
                    ? "(" + item.title + " / " + item.name + ")"
                    : ""
                }`);
            } else if (item.status == 5) {
              (className = "type_4"),
                (title = `보류 ${
                  item.name !== null
                    ? "(" + item.title + " / " + item.name + ")"
                    : ""
                }`);
            } else if (item.status == 10) {
              (className = "type_5"),
                (title = `취소 ${
                  item.name !== null
                    ? "(" + item.title + " / " + item.name + ")"
                    : ""
                }`);
            }
            const htmlString = `<li class="new_cal_item" idx="${
              item.id
            }" status="${
              item.status
            }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
              item.contents && item.contents
            }</span> <h1> <span class="date_created_at">${dateForm(
              item.completed_at
            )}</span> <span class="data_price_detail">${numberWithCommas(
              item.price
            )}원</span> </h1> </div> </li>`;
            // const htmlString = `<li idx="${item.id}"> <div class="left"> <h1>${numberWithCommas(item.price)}원 (${String(item.payment_type)})</h1> <span>일정완료일 : ${item.completed_at.split(" ")[0]}</span> </div> </li>`;

            document
              .querySelectorAll(".view_price_wrapper ul")[0]
              .insertAdjacentHTML("beforeend", htmlString);
          });
          document
            .querySelectorAll(".data_wrapper_checkable ul li")
            .forEach((item, idx) => {
              item.addEventListener("click", function () {
                if (this.classList.contains("on")) {
                  this.classList.remove("on");
                } else {
                  this.classList.add("on");
                }
              });
            });
        } else {
          noData(true);
        }
      });
  }, []);
  return (
    <>
      <div className="pc_right_area confirm_wrapper">
        <div className="pc_right_area_top">
          <div className="left">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <h1 className="right_area_title">정산확인</h1>
          <div className="right">
            <button
              onClick={() => {
                if (
                  document.querySelectorAll(
                    ".data_wrapper_checkable ul li.on"
                  )[0]
                ) {
                  let formdata = new FormData();
                  let scheduleIdArr = [];
                  document
                    .querySelectorAll(".data_wrapper_checkable ul li.on")
                    .forEach((item, idx) => {
                      scheduleIdArr.push(item.getAttribute("idx"));
                      formdata.append("schedule_id[]", scheduleIdArr);
                    });

                  fetch(
                    `https://api.olapse.com/api/teamwork/schedule/calculate/complete`,
                    {
                      method: "POST",
                      body: formdata,
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    }
                  )
                    .then(function (response) {
                      return response.json();
                    })
                    .then(function (data) {
                      if (data.success == true) {
                        createPopup({
                          type: "custom",
                          title: "정산 완료",
                          content: "정산이 완료되었습니다.",
                          custom: () => {
                            window.location.reload(true);
                          },
                        });
                      } else {
                        createPopup({
                          type: "reTry",
                          title: "정산 실패",
                          content: data.alert,
                          custom: () => {
                            window.location.reload(true);
                          },
                        });
                      }
                    });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "정산 실패",
                    content: "정산할 일정을 선택해주세요",
                  });
                }
              }}
              className="confirm_button"
            >
              완료
            </button>
          </div>
        </div>
        <div className="view_price_wrapper data_wrapper data_wrapper_checkable">
          {/* 일정 리스트 */}
          <ul className="data_list"></ul>
          <div className="no_data">
            <span>정산 가능한 일정이 없습니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirm;

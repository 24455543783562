// 2022-02-24 인수인계
// 기능 : 공지사항 리스트 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
const NoticeList = () => {
  const navigate = useNavigate();
  useEffect(() => {
    inner("https://api.olapse.com/api/notice?page=1");
    function inner(url) {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.data.notices.data.length !== 0) {
            noData(false);
            data.data.notices.data.forEach((item, idx) => {
              const appendTarget =
                  document.querySelectorAll("ul.notice_list")[0],
                dom = `<li class="new_notice_item" idx="${item.id}"><h1>${
                  item.title
                }</h1><span>${item.created_at.split(" ")[0]}</span></li>`;
              appendTarget.insertAdjacentHTML("beforeend", dom);
              document
                .querySelectorAll(".new_notice_item")[0]
                .addEventListener("click", function () {
                  navigate(`/more/notice_detail?idx=${item.id}`);
                });
              document
                .querySelectorAll(".new_notice_item")[0]
                .classList.remove("new_notice_item");
            });
          } else {
            noData(true);
          }

          if (data.data.notices.next_page_url) {
            inner(data.data.notices.next_page_url);
          }
        });
    }
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="공지사항" />
        <div className="setting_gray_wrapper">
          <ul className="notice_list" />
          <div className="no_data">
            <span>공지사항이 없습니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default NoticeList;

// 2022-02-24 인수인계
// 기능 : 정보수정 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import createPopup from "./createPopup";

const EditInfo = () => {
  const navigate = useNavigate();
  useEffect(() => {
    //기저장한 내 정보 불러옴
    fetch("https://api.olapse.com/api/mypage", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        data.data.profile_url
          ? (document.querySelectorAll(
              "div.edit_wrapper div.profile_img_input_wrapper label"
            )[0].style.background = `url(${data.data.profile_url}) no-repeat`)
          : (document.querySelectorAll(
              "div.edit_wrapper div.profile_img_input_wrapper label"
            )[0].style.background =
              "url(/assets/images/icon_profile_basic.svg) no-repeat");
        document.querySelectorAll(
          "div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper input"
        )[0].value = data.data.name;
        document.querySelectorAll(
          "div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper textarea"
        )[0].value = data.data.message ? data.data.message : "";
      });
    //수정 실행
    const editProfile = () => {
      if (
        String(document.querySelector("#user_name").value).length !== 0 &&
        String(document.querySelector("#user_msg").value).length !== 0
      ) {
        let formdata = new FormData();
        formdata.append("name", document.querySelector("#user_name").value);
        formdata.append("message", document.querySelector("#user_msg").value);
        if (
          document.querySelectorAll(
            "div.edit_wrapper div.profile_img_input_wrapper label input"
          )[0].files[0]
        ) {
          formdata.append(
            "profile",
            document.querySelectorAll(
              "div.edit_wrapper div.profile_img_input_wrapper label input"
            )[0].files[0],
            document.querySelectorAll(
              "div.edit_wrapper div.profile_img_input_wrapper label input"
            )[0].files[0].name
          );
        }
        fetch(`https://api.olapse.com/api/mypage/modify`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "수정완료",
                content: "회원정보가 수정되었습니다.",
                custom: () => {
                  window.location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "수정실패",
                content: data.alert,
              });
            }
          });
      } else {
        createPopup({
          type: "reTry",
          title: "수정실패",
          content: "모든 정보를 입력해주세요.",
        });
      }
    };
    document
      .querySelectorAll(".profile_edit_complete_button")[0]
      .addEventListener("click", function () {
        editProfile();
      });

    //파일 업로드 기능(이미지 전용)
    function imgUpload(trigger, target) {
      trigger.addEventListener("input", function (e) {
        let fr = new FileReader();
        fr.readAsDataURL(this.files[0]);
        fr.onload = function () {
          target.style.background = `url(${this.result}) no-repeat`;
        };
      });
    }
    imgUpload(
      document.querySelectorAll(
        "div.edit_wrapper div.profile_img_input_wrapper label input"
      )[0],
      document.querySelectorAll(
        "div.edit_wrapper div.profile_img_input_wrapper label"
      )[0]
    );
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="회원정보수정" />
        <div className="edit_wrapper">
          <div className="profile_img_input_wrapper">
            <label>
              <input type="file" accept="image/*" />
              <p className="icon_photo" />
            </label>
            <div className="edit_info_wrapper">
              <div className="row">
                <h1>이름</h1>
                <input
                  id="user_name"
                  onKeyUp={(e) => {
                    if (e.target.value.length > 20) {
                      e.target.value = e.target.value.substr(0, 20);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.target.value.length > 20) {
                      e.target.value = e.target.value.substr(0, 20);
                    }
                  }}
                  placeholder="이름을 입력하세요."
                  type="text"
                  maxLength="20"
                />
              </div>
              <div className="row">
                <h1>상태메세지</h1>
                <textarea
                  id="user_msg"
                  onKeyUp={(e) => {
                    if (e.target.value.length > 60) {
                      e.target.value = e.target.value.substr(0, 60);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.target.value.length > 60) {
                      e.target.value = e.target.value.substr(0, 60);
                    }
                  }}
                  placeholder="상태 메세지를 입력하세요."
                  maxLength="60"
                />
              </div>
              <button className="profile_edit_complete_button">
                수정 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditInfo;

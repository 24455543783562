// 2022-02-24 인수인계
// 기능 : 회원가입 페이지입니다.

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import UserHeader from "../components/userHeader";
import {
  getParameterByName,
  toggleClass,
  inputChanger,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

const Account1 = () => {
  const navigate = useNavigate();
  let idPass = /^[a-z0-9_-]{4,12}$/,
    isChecked,
    loginId;
  useEffect(() => {
    document.querySelector("#id").addEventListener("input", function () {
      isChecked = false;
    });
    inputChanger();
  }, []);

  const checkId = () => {
    document.querySelectorAll(".sub_alert")[0].style.display = "none";
    if (idPass.test(document.querySelector("#id").value)) {
      let formdata = new FormData();
      formdata.append("login_id", document.querySelector("#id").value);
      fetch("https://api.olapse.com/api/id/check", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "confirm",
              title: "아이디 확인",
              content: `${
                document.querySelector("#id").value
              } 는 이용 가능한 아이디입니다.`,
            });
            isChecked = true;
            loginId = document.querySelector("#id").value;
          } else {
            document.querySelectorAll(".sub_alert")[0].style.display = "block";
            isChecked = false;
          }
        });
    } else {
      createPopup({
        type: "reTry",
        title: "아이디 재확인",
        content:
          "영문 소문자,숫자,언더바,하이픈만 이용 가능하며, <br /> 4~12자리로 입력해주세요.",
      });
      isChecked = false;
    }
  };

  function next() {
    if (isChecked == true) {
      localStorage.setItem("login_id", loginId);
      navigate("/account_2");
    } else {
      createPopup({
        type: "reTry",
        title: "중복확인 미진행",
        content: "아이디 중복 확인을 진행해주세요.",
      });
    }
  }
  return (
    <>
      <div id="wrap">
        <UserHeader isCenter="true" />
        <div className="user_wrapper">
          <h1 className="page_title">계정 설정</h1>
          <div className="input_wrapper">
            <div className="input_row">
              <h1>아이디</h1>
              <div className="input_outter">
                <input id="id" placeholder="아이디" type="text" />
                <p onClick={() => checkId()} className="blue">
                  중복확인
                </p>
              </div>
              <span className="red sub_alert">중복된 아이디입니다.</span>
            </div>
          </div>
          <button className="button_type_1" onClick={() => next()}>
            다음
          </button>
        </div>
      </div>
    </>
  );
};

export default Account1;

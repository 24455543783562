// 2022-02-24 인수인계
// 기능 : 안드로이드/IOS 앱과 통신을 위한 브릿지 함수 모음

const bridge = () => {
    try {
        const varUA = navigator.userAgent;
        if (varUA.match("olapse/Android") != null) {
            const token_ = localStorage.getItem("token");
            window.olapse.postMessage(JSON.stringify({type: "autoLogin", token: token_}));
        } else if (varUA.match("olapse/iOS") != null) {
            const token_ = localStorage.getItem("token");
            window.webkit.messageHandlers.autoLoginHandler.postMessage(token_);
        }
    } catch {
        console.log("PC");
    }
};

const colorBridgeGray = () => {
    try {
        const varUA = navigator.userAgent;
        if (varUA.match("olapse/Android") != null) {
            window.olapse.postMessage(JSON.stringify({color: "gray"}));
        } else if (varUA.match("olapse/iOS") != null) {
            window.webkit.messageHandlers.colorHandler.postMessage("gray");
        }
    } catch {
        console.log("PC");
    }
};

const webOpenBridge = (browserFunction) => {
    try {
        const varUA = navigator.userAgent;
        if (varUA.match("olapse/Android") != null) {
            const element = document.createElement("a");
            element.setAttribute("href", `https://app.olapse.com/more/data_save_2?token=${localStorage.getItem("token")}`);
            element.setAttribute("target", "_blank");
            element.click();
        } else if (varUA.match("olapse/iOS") != null) {
            window.webkit.messageHandlers.nativeBrowser.postMessage(
                JSON.stringify({
                    type: "nativeBrowserOpen",
                    url: `https://app.olapse.com/more/data_save_2?token=${localStorage.getItem("token")}`,
                })
            );
        }
    } catch {
        browserFunction();
    }
};

export {bridge, colorBridgeGray, webOpenBridge};

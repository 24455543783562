// 2022-02-24 인수인계
// 기능 : 팝업 생성 함수입니다.
// 함수의 옵션은 오브젝트이며
// type : confirm/reTry/custom 이 있습니다. 각각 확인, 다시시도, 버튼클릭시 특정한 이벤트 실행 기능을 담당합니다.
// title : String 팝업 타이틀입니다.
// content : String 팝업 내용입니다.
// custom : function type이 custom일경우 팝업 확인 클릭시 작동할 함수입니다.
// 예외로 custom_select가 있는데, 해당 타입은 거의 사용하지 않습니다. 버튼명을 변경하거나 여러개의 기능을 넣는 타입이며 사용법은 코드에 위 옵션과 동일하게 작성되어있습니다.

import react from "react";
import Anime, {anime} from "react-anime";

const createPopup = (option) => {
    if (!document.querySelectorAll(".popup_wrapper")[0]) {
        //등장 애니메이션(top down)
        function _animationAppearTopDown(targetElement) {
            targetElement.style.display = "flex";
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [0, 1],
                translateY: ["-15px", 0],
                duration: 500,
            });
        }

        //등장 애니메이션(left)
        function _animationAppearLeft(targetElement) {
            targetElement.style.display = "flex";
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [0, 1],
                translateX: ["-5%", 0],
                duration: 400,
            });
        }
        //퇴장 애니메이션(right)
        function _animationDisappearRight(targetElement) {
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateX: [0, "-5%"],
                duration: 300,
                complete: function (i) {
                    document.querySelectorAll(".popup_wrapper")[0].remove();
                },
            });
        }

        //등장 애니메이션(right)
        function _animationAppearRight(targetElement) {
            anime.remove(targetElement);
            targetElement.style.display = "flex";
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [0, 1],
                translateX: ["5%", 0],
                duration: 400,
            });
        }
        //퇴장 애니메이션(left)
        function _animationDisappearLeft(targetElement) {
            anime.remove(targetElement);
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateX: [0, "5%"],
                duration: 300,
                complete: function (i) {
                    targetElement.style.display = "none";
                },
            });
        }

        //퇴장 애니메이션(bottom up)
        function _animationDisappearBottomUp(targetElement) {
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateY: [0, "-15px"],
                duration: 300,
                complete: function (i) {
                    if (document.querySelectorAll(".popup_wrapper")[0]) {
                        document.querySelectorAll(".popup_wrapper")[0].remove();
                    }
                },
            });
        }

        //퇴장 애니메이션V2(bottom up)
        function _animationDisappearBottomUpV2(targetElement, wrapperElement) {
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateY: [0, "-15px"],
                duration: 300,
                complete: function (i) {
                    wrapperElement.style.display = "none";
                    targetElement.style.display = "none";
                },
            });
        }

        //퇴장 애니메이션V3(bottom up)
        function _animationDisappearBottomUpV3(targetElement) {
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateY: [0, "-15px"],
                duration: 300,
                complete: function (i) {
                    targetElement.style.display = "none";
                },
            });
        }

        //팝업 등장 애니메이션(top down)
        function _popupAnimationAppearTopDown(wrapper, targetElement) {
            document.body.style.overflow = "hidden";
            document.querySelectorAll(".background_black")[0].style.display = "block";
            wrapper.style.display = "flex";

            if (window.matchMedia("(min-width: 768px)").matches) {
                anime({
                    targets: targetElement,
                    easing: "easeOutExpo",
                    opacity: [0, 1],
                    translateY: ["-15px", 0],
                    duration: 500,
                });
            } else {
                anime({
                    targets: targetElement,
                    easing: "easeOutExpo",
                    opacity: [0, 1],
                    translateY: ["100%", 0],
                    duration: 500,
                });
            }
        }

        //팝업 퇴장 애니메이션(bottom up)
        function _popupAnimationDisappearBottomUp(wrapper, targetElement, isRemove) {
            document.body.style.overflow = "visible";
            document.querySelectorAll(".background_black")[0].style.display = "none";
            if (window.matchMedia("(min-width: 768px)").matches) {
                anime({
                    targets: targetElement,
                    easing: "easeOutExpo",
                    opacity: [1, 0],
                    translateY: [0, "-15px"],
                    duration: 300,
                    complete: function (i) {
                        if (isRemove == true) {
                            wrapper.remove();
                        } else {
                            wrapper.style.display = "none";
                        }
                    },
                });
            } else {
                anime({
                    targets: targetElement,
                    easing: "easeOutExpo",
                    opacity: [1, 0],
                    translateY: [0, "30%"],
                    duration: 300,
                    complete: function (i) {
                        if (isRemove == true) {
                            wrapper.remove();
                        } else {
                            wrapper.style.display = "none";
                        }
                    },
                });
            }
        }
        //bottom up 100% 등장 애니메이션
        function _agreePopupActive(wrapper, targetElement) {
            wrapper.style.display = "flex";
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [0, 1],
                translateY: ["100%", 0],
                duration: 500,
            });
        }
        //bottom up 100% 퇴장 애니메이션
        function _agreePopupOut(wrapper, targetElement) {
            anime({
                targets: targetElement,
                easing: "easeOutExpo",
                opacity: [1, 0],
                translateY: [0, "30%"],
                duration: 300,
                complete: function () {
                    wrapper.style.display = "none";
                },
            });
        }

        // 컨트롤러 등장 애니메이션
        function _controllerActive(controllerElement, trigger) {
            if (trigger) {
                trigger.style.pointerEvents = "none";
                trigger.parentNode.style.pointerEvents = "none";
            }

            if (controllerElement.getAttribute("floated") !== "true") {
                anime({
                    targets: controllerElement,
                    easing: "easeOutExpo",
                    opacity: [0, 1],
                    translateY: ["15px", "0"],
                    duration: 300,
                    complete: function (i) {
                        controllerElement.setAttribute("floated", true);
                        if (trigger) {
                            trigger.style.pointerEvents = "auto";
                            trigger.parentNode.style.pointerEvents = "auto";
                        }
                    },
                });
            }
        }

        // 컨트롤러 퇴장 애니메이션
        function _controllerDisappear(controllerElement, trigger) {
            if (trigger) {
                trigger.style.pointerEvents = "none";
                trigger.parentNode.style.pointerEvents = "none";
            }
            if (controllerElement.getAttribute("floated") !== "false") {
                anime({
                    targets: controllerElement,
                    easing: "easeOutExpo",
                    opacity: [1, 0],
                    translateY: ["0", "15px"],
                    duration: 300,
                    complete: function (i) {
                        controllerElement.setAttribute("floated", false);
                        if (trigger) {
                            trigger.style.pointerEvents = "auto";
                            trigger.parentNode.style.pointerEvents = "auto";
                        }
                    },
                });
            }
        }

        function _animateRightModule(bool, controllerElement) {
            if (bool == true) {
                //열기
                controllerElement.style.display = "block";
                anime({
                    targets: controllerElement,
                    easing: "easeOutExpo",
                    translateX: ["100%", "0"],
                    duration: 300,
                });
            } else {
                //닫기
                anime({
                    targets: controllerElement,
                    easing: "easeOutExpo",
                    translateX: ["0", "100%"],
                    duration: 300,
                    complete: function (i) {
                        controllerElement.style.display = "none";
                    },
                });
            }
        }

        const contentWrapper = document.querySelector("#wrap") ? document.querySelector("#wrap") : document.querySelectorAll(".wrap")[0];
        if (option.type == "reTry") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_right">다시시도</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
            });
        } else if (option.type == "confirm") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_right">확인</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
                option.custom && option.custom();
            });
        } else if (option.type == "custom") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_right">확인</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                option.custom();
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
            });
        } else if (option.type == "customT2") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_right">${option.button_text_1}</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                option.custom();
                // if(window.location.pathname == "/join_team.html"){
                //     window.close();
                // }
            });
        } else if (option.type == "custom_select") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_left">취소</button><button class="button_right">확인</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
                option.custom();
            });
            document.querySelectorAll(".button_left")[0].addEventListener("click", function () {
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
            });
        } else if (option.type == "custom_select_2") {
            contentWrapper.insertAdjacentHTML("beforeend", `<div class="popup_wrapper"> <div class="popup_box"> </div> <div class="background_black"></div> </div>`);
            const appendHTMLString = `<div class="popup_text_wrapper"><h1>${option.title}</h1><span>${option.content}</span></div><button class="button_left">${option.button_text_1}</button><button class="button_right">${option.button_text_2}</button>`;
            document.querySelectorAll(".popup_box")[0].insertAdjacentHTML("beforeend", appendHTMLString);
            _animationAppearTopDown(document.querySelectorAll(".popup_box")[0]);
            document.querySelectorAll(".button_right")[0].addEventListener("click", function () {
                option.firstButtonClickFunction();
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
            });
            document.querySelectorAll(".button_left")[0].addEventListener("click", function () {
                option.secondButtonClickFunction();
                _animationDisappearBottomUp(document.querySelectorAll(".popup_box")[0]);
            });
        }
        return false;
    }
};

export default createPopup;

// 2022-02-24 인수인계
// 기능 : 모아보기 페이지의 상단 팀워크 리스트입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { getParameterByName, filteringBtns } from "../shared/script";
import Anime, { anime } from "react-anime";
const TeamworkList = (props) => {
  let useCard;
  if (window.location.pathname == "/more/card") {
    useCard = 1;
  } else {
    useCard = 0;
  }

  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://api.olapse.com/api/data/store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_data_store == null) {
          document.querySelectorAll(".subs_img")[0] &&
            (document.querySelectorAll(".subs_img")[0].style.display = "block");
          if (window.location.pathname !== "/more/card") {
            document.querySelectorAll(".open_search_module")[0] &&
              (document.querySelectorAll(
                ".open_search_module"
              )[0].style.display = "none");
            document.querySelectorAll(".teamword_list")[0] &&
              (document.querySelectorAll(".teamword_list")[0].style.display =
                "none");
          }
        } else {
          document.querySelectorAll(".subs_img")[0] &&
            (document.querySelectorAll(".subs_img")[0].style.display = "none");
        }
      });
    const appendTarget = document.querySelectorAll("div.teamword_list ul")[0];
    inner(`https://api.olapse.com/api/teamwork?use_card=${useCard}`);

    function inner(url) {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          data.data.teamworks.forEach((item, idx) => {
            const htmlString = `<li class="new_team_work_list_item" idx="${
              item.id
            }"> <p style="background:url('${
              item.image_url
                ? item.image_url
                : "/assets/images/icon_profile_basic.svg"
            }')"></p> <span>${
              item.title == "나와의 채팅" ? "개인 일정" : item.title
            }</span> </li>`;

            appendTarget.insertAdjacentHTML("beforeend", htmlString);
            appendTarget.style.display = "flex";
            anime({
              targets: document.querySelectorAll(".new_team_work_list_item")[0],
              easing: "easeOutExpo",
              opacity: [0, 0.5],
              duration: 2000,
            });
            document
              .querySelectorAll(".new_team_work_list_item")[0]
              .addEventListener("click", function () {
                props.clickEv(this);
              });
            document
              .querySelectorAll(".new_team_work_list_item")[0]
              .classList.remove("new_team_work_list_item");
          });
          filteringBtns("div.teamword_list ul li", "active");
          if (
            getParameterByName("idx") &&
            window.location.pathname !== "date"
          ) {
            document
              .querySelectorAll("div.teamword_list ul li")
              .forEach((item, idx) => {
                if (getParameterByName("idx") == item.getAttribute("idx")) {
                  item.click();
                }
              });
          }
        });
    }
    document
      .querySelectorAll(".teamword_list .all")[0]
      .addEventListener("click", function () {
        props.allEv();
      });
  }, []);

  return (
    <>
      <div className="teamword_list">
        <ul>
          <li className="all active">
            <p>ALL</p>
            <span>전체</span>
          </li>
        </ul>
      </div>

      {window.location.pathname !== "/more/card" && (
        <img
          className="subs_img"
          style={{
            width: "100%",
            display: "none",
          }}
          onClick={() => navigate("/more/data_save_2")}
          src="/assets/images/banner_img.svg"
        />
      )}
    </>
  );
};
export default TeamworkList;
